import { AppBar, Box, Button, CircularProgress, createTheme, Divider, IconButton, Menu, MenuItem, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useRecoilState } from "recoil";
import { isEditingFunctionAtom, cancelEditingFunctionAtom, updatingFunctionAtom, doSubmitFunctionAtom, selectedFunctionDescriptorAtom, selectedFunctionAtom, reloadFunctionAtom } from "../../recoil/atom/function_atom";
import CancelEditingDialog from "./cancel_editing_dialog";
import { doCustomizeProgramForStudentAtom, editingProgramAtom, isCustomizingProgramForStudentAtom, selectedCustomizeStudentAtom, selectedProgramAtom } from "../../recoil/atom/program_library_atom";
import { printObject } from "../../utils/print_object";
import { jsonCopyObject } from "../../utils/json_copy";
import { sideMenuWidth } from "../sidemenu/side_menu";

const theme = createTheme({
    palette: {
        primary: {
            main: '#268DA5',
            dark: '#0c6472',
        }
    },
});

function ProgramLibraryProgramSubheader(props) {
    const [title, setTitle] = useState(props.title)
    const [editMode, setEditMode] = useState('canEdit')
    const [selectedProgram, setSelectedProgram] = useRecoilState(selectedProgramAtom)
    const [editingProgram, setEditingProgram] = useRecoilState(editingProgramAtom)
    const [isEditingFunction, setIsEditingFunction] = useRecoilState(isEditingFunctionAtom)
    const [cancelEditingState, setCancelEditing] = useRecoilState(cancelEditingFunctionAtom)
    const [updatingFunction, setUpdatingFunction] = useRecoilState(updatingFunctionAtom)
    const [isCustomizingForStudent, setIsCustomizingForStudent] = useRecoilState(isCustomizingProgramForStudentAtom)
    const [doCustomizeProgramForStudent, setDoCustomizeForStudent] = useRecoilState(doCustomizeProgramForStudentAtom)
    const [selectedCustomizeStudent, setSelectedCustomizeStudent] = useRecoilState(selectedCustomizeStudentAtom)
    const [showingCancelDialog, setShowingCancelDialog] = useState(false)
    const [doSubmitFunction, setDoSubmitFunction] = useRecoilState(doSubmitFunctionAtom)

    useEffect(() => {
        setTitle(selectedProgram?.name)
    }, [selectedProgram])

    useEffect(() => {
        if (!selectedProgram) {
            setEditingProgram(null)
        } else {
            if (editMode == 'canEdit') {
                setEditingProgram(jsonCopyObject(selectedProgram))
            }
        }
    }, [editMode, selectedProgram])

    function onCustomizeClicked() {
        setIsCustomizingForStudent(true)
    }

    function cancelEditing() {
        setShowingCancelDialog(true)
    }

    function cancelDialogClose(value) {
        if (value) {
            setIsEditingFunction(false)
            setCancelEditing(true)
            setIsCustomizingForStudent(false)
            setEditMode('canEdit')
        }
        setShowingCancelDialog(false)
    }

    function onClickEdit() {
        setEditMode('editing')
        setIsEditingFunction(true)
    }

    function onBack() {
        setSelectedProgram(null)
        setIsCustomizingForStudent(false)
    }

    function renderActionButtons() {
        if (updatingFunction) {
            return (
                <CircularProgress sx={{ mr: '32px' }} />
            )
        } else if (isCustomizingForStudent) {
            return (
                <>
                    <Button sx={{ mt: '12px', mb: '12px', mr: '16px' }} color='error' variant="contained" onClick={() => cancelEditing()}>Cancel</Button>
                    <Button sx={{ mt: '12px', mb: '12px', mr: '24px' }} disabled={!selectedCustomizeStudent} variant="contained" onClick={() => setDoCustomizeForStudent(true)}>Submit</Button>
                </>
            )
        } else if (editMode == 'editing') {
            return (
                <>
                    <Button sx={{ mt: '12px', mb: '12px', mr: '16px' }} color='error' variant="contained" onClick={() => cancelEditing()}>Cancel</Button>
                    <Button sx={{ mt: '12px', mb: '12px', mr: '24px' }} variant="contained" onClick={() => setDoSubmitFunction(true)}>Submit</Button>
                </>
            )
        } else if (editMode == 'canEdit') {
            return (
                <Button sx={{ mt: '12px', mb: '12px', mr: '16px' }} variant="contained" onClick={() => onClickEdit()}>Edit</Button>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                zIndex: 1,
                left: `${sideMenuWidth}px`,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1,
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>


            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '400px' }}>
                {!updatingFunction &&
                    <>
                    { editMode == 'canEdit' && !isCustomizingForStudent &&
                    <IconButton onClick={() => onBack()}>
                        <ArrowBackIcon sx={{ ml: 1, mr: 2, color: '#3c7ebf' }} />
                    </IconButton>
                    }
                    {!isCustomizingForStudent && editMode == 'canEdit' &&
                        <Button sx={{ mr: 2 }}
                            variant="contained"
                            onClick={() => onCustomizeClicked()}
                            key="customizeButton">Customize for student</Button>
                    }
                </>
                }
            </Box>

            <Typography flexGrow={1} sx={{ position: 'relative', ml: '16px', fontWeight: 'medium', textAlign: 'center', color: '#3c7ebf' }} variant="h5">{title}</Typography>

            <Box sx={{ marginRight: '8px', width: '400px', display: 'flex', alignItems: 'center', justifyContent: 'end' }} >

                {
                    renderActionButtons()
                }

            </Box>
            <CancelEditingDialog open={showingCancelDialog} onCompleted={(value) => cancelDialogClose(value)} />

        </Box>
    );
};

export default ProgramLibraryProgramSubheader;