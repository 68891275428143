import React, { useEffect, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, InputLabel, Typography } from "@mui/material";
import { Label } from "@mui/icons-material";
import { fetchGroups } from '../reports/reports.api'
import MenuBookIcon from '@mui/icons-material/MenuBook';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropzoneArea } from "material-ui-dropzone";
import { app } from '../../components/firebase/firebase';
import 'firebase/storage';
import { generateRandomKey } from "../../utils/random_key";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { jsonCopyObject } from "../../utils/json_copy";
import { importStudentInstances, parseUploadedPrograms } from "../../api/api_import_data";
import { TreeItem, TreeView } from "@mui/lab";
import { fetchEducationTemplateDescriptors } from "../../api/program_library";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { showError } from "../../components/toasts";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRecoilState } from "recoil";
import { importedTemplateProgramsState } from "../../recoil/atom/import-state";
import { useNavigate } from 'react-router-dom';
import LoadingInformationDialog from "../reports/create-report-alert";
import { sideMenuWidth } from "../../components/sidemenu/side_menu";

const storage = getStorage(app)
const spacingBetween = 4

const style = {

    fileContainer: {
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '8px',
        p: 1,
        mt: 1,
        flexDirection: 'row',
        alignItems: 'center',
        width: "420px",
        userSelect: 'none'
    },
    file: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fileText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};


export default function ImportInstances(props) {
    // state
    const [selectedFiles, setSelectedFiles] = useState({})
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [selectedStudent, setSelectedStudent] = useState(null)
    
    // file upload
    const [dropzoneIndex, setDropzoneIndex] = useState(0)

    // tree
    const [isLoadingRootFolders, setLoadingRootFolders] = useState(true)
    const [allFolders, setAllFolders] = useState({})
    const [fetchingFolders, setFetchingFolders] = useState({})
    const [folders, setFolders] = useState(null)
    const [rootGroups, setRootGroups] = useState(null)
    const [treeUpdated, setTreeUpdated] = useState(1)

    const [groupTree, setGroupTree] = useState(null)
    const [allGroups, setAllGroups] = useState({})
    const [allStudents, setAllStudents] = useState({})
    const [isDone, setIsDone] = useState(false)
    const [importedTemplates, setImportedTemplates] = useRecoilState(importedTemplateProgramsState)
    const [isUploading, setIsUploading] = useState(false)
    
    const navigate = useNavigate();

    const loadingDialogModel = [
        { text: 'Compressing Modules', duration: 2.0 },
        { text: 'Uploading Files', duration: 6.0 },
        { text: 'Parsing Programs', duration: 4.5 },
        { text: 'Validating Data', duration: 3.0 },
        { text: 'Generating Report', duration: 3000 }
    ]

    useEffect(() => {
        fetchSubfolders(null)
        setImportedTemplates(null)

        fetchGroups(null).then((result) => {
            setRootGroups(result.data)
        })

        setIsDone(false)
    }, []);

    useEffect(() => {
        if(isDone) {
            window.setTimeout(() => {
                navigate('/dashboard/import_completed', { replace: true })
            }, 850);
        }
    }, [isDone])

    useEffect(() => {
        addToAllGroups(rootGroups)
        setGroupTree(rootGroups)

        setTreeUpdated(treeUpdated + 1)
    }, [rootGroups])

    function renderTree(isRoot, parent) {
        if (!groupTree) {
            return <CircularProgress />
        } else if (isRoot) {
            return groupTree.map((sub) => {
                return renderTree(false, sub)
            })
        } else if (!parent) {
            return <CircularProgress />
        }

        if (parent.type == 'student') {
            return <FormGroup id={parent.id} >
                <FormControlLabel sx={{ ml: 4 }} control={<Checkbox disabled={isUploading} checked={selectedStudent?.id ==  parent.id} onChange={(event) => toggleStudent(parent.id, event.target.checked)} />} label={parent.title} />
            </FormGroup>
        } else {
            return <TreeItem nodeId={parent.id} disabled={isUploading}
                label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography >{parent.title}</Typography>
                </Box>
                }>

                {
                    parent?.subgroups && parent?.subgroups.map((sub) => {
                        return renderTree(false, sub)
                    })
                }
                {
                    !parent.isEmpty && parent?.subgroups == null && <CircularProgress size={32} />
                }
                {
                    parent.isEmpty && <TreeItem nodeId={`empty_${parent.id}`} label='/'></TreeItem>
                }
            </TreeItem>
        }
    }

    function addToAllGroups(arr) {
        let all = allGroups ?? {}
        let alls = allStudents ?? {}
        for (let key in arr) {
            let item = arr[key]
            if (item.type == 'group') {
                all[item.id] = item
            } else if (item.type == 'student') {
                alls[item.id] = item
            }

            let subgroups = item.subgroups ?? []
            subgroups.forEach((subg) => {
                if (subg.type == 'group') {
                    all[subg.id] = subg
                } else if (subg.type == 'student') {
                    alls[subg.id] = subg
                }
            })
        }

        setAllGroups(all)
        setAllStudents(alls)
    }

    function findGroup(id, tree) {
        return allGroups[id]
    }

    function toggleStudent(id, checked) {
        if(checked) { 
            setSelectedStudent(allStudents[id])
        } else {
            setSelectedStudent(null)
        }
    }

    function onDrop(files) {
        if (!files || files.length == 0) {
            return
        }
        let added = {}

        for (let i = 0; i < files.length; i++) {
            let file = { name: files[i].name, path: files[i].path, type: files[i].type, file: files[i] }
            added[file.name] = file
        }

        setSelectedFiles((prev) => ({
            ...prev,
            ...added
        }));
        console.log('on drop')
        setDropzoneIndex(dropzoneIndex + 1)
    }

    function onUpload() {
        if (Object.keys(selectedFiles).length == 0) {
            showError('Please select at least one file.')
            return
        }

        if (selectedStudent?.studentID == null || selectedStudent?.groupID == null) {
            showError('Please select the student.')
            return
        }

        setIsUploading(true)
        setTreeUpdated(treeUpdated + 1)

        let urls = []
        let count = Object.keys(selectedFiles).length
        for (let i = 0; i < count; i++) {
            let key = Object.keys(selectedFiles)[i]
            let file = selectedFiles[key]

            const fileName = file.name;
            const fileType = file.type;

            console.log("uploading file " + fileName)

            const fileRef = ref(storage, `/import_programs/${generateRandomKey(20)}/${fileName}`);
            console.log("attaching file " + file.file)
            const uploadTask = uploadBytesResumable(fileRef, file.file)

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    // setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        if (addURL(url, urls, count)) {
                            importStudentInstances({ files: urls, studentID: selectedStudent?.studentID, groupID: selectedStudent?.groupID }).then((resp) => {
                                console.log('Data received ' + JSON.stringify(resp.data, null, 4))
                                setImportedTemplates(resp.data)
                                setIsDone(true)
                            }).finally(() => {
                                setIsUploading(false)
                                setTreeUpdated(treeUpdated + 1)
                            })
                        }
                    })
                }
            );
        }
    }

    function addURL(url, arr, count) {
        arr.push(url)
        return arr.length == count
    }

    function removeFile(name) {
        let current = selectedFiles
        delete current[name]
        setSelectedFiles((prev) => ({ ...current }))
    }

    function fetchSubfolders(parentID) {
        if (parentID) {
            if (fetchingFolders[parentID]) {
                return
            }
            fetchingFolders[parentID] = true
        }

        fetchEducationTemplateDescriptors({ parentID: parentID }).then((result) => {

            let jsonString = JSON.stringify(result.data, null, 4);
            let validLibraries = []

            console.log('Fetched ' + jsonString)

            const libraries = result.data
            for (let idx in libraries) {
                const entry = libraries[idx]
                if (!entry) {
                    continue
                }

                let library = entry.value
                if (!entry.value) {
                    continue
                } else if (!library) {
                    continue
                }

                if (library && library.name) {
                    if (library.itemType === 'folder') {
                        library.key = entry.key
                        library.id = entry.key
                        validLibraries.push(library)

                        allFolders[library.id] = library
                    }
                }
            }

            let sorted = validLibraries.sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 })
            console.log('sorted count ' + sorted.length)

            sorted.forEach((folder) => {
                folder.parentID = parentID
            })

            let parentFolder = null

            if (parentID) {
                parentFolder = allFolders[parentID]
                if (parentFolder) {
                    parentFolder.subfolders = sorted

                    let rootFolderID = parentFolder.rootFolderID ?? parentFolder.id
                    sorted.forEach((folder) => {
                        folder.rootFolderID = rootFolderID
                    })
                }
            }

            if (!parentID) {
                setFolders(sorted)
            }

            setLoadingRootFolders(false)
            setTreeUpdated(treeUpdated + 1)
        }).catch().finally(() => {
            delete fetchingFolders[parentID]
        })
    }

    function fetchSubgroups(parentID) {
        fetchGroups(parentID).then((result) => {
            addToAllGroups(result.data)

            let tree = groupTree
            let item = findGroup(parentID, tree)

            if (item) {
                item.subgroups = result.data
                if (result.data.length == 0) {
                    item.isEmpty = true
                }
            }

            setTreeUpdated(treeUpdated + 1)
            setGroupTree(tree)
        })
    }

    return <Box>
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: `${sideMenuWidth}px`,
                zIndex: 1,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1
            }}>
            <IconButton component={Link} to="/dashboard/import">
                <ArrowBackIcon sx={{ ml: 2, mr: 2, color: '#3c7ebf' }} />
            </IconButton>
            <Box flexGrow={1} sx={{ marginRight: '32px', display: 'flex', alignItems: 'center', justifyContent: 'end' }} >
                { isUploading ? <CircularProgress /> : <Button sx={{ mt: '12px', mb: '12px' }} variant="contained" onClick={() => onUpload() }>Upload</Button> }
            </Box>
        </Box>
        <Box sx={{ ml: '16px', mr: '16px', mt: '84px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

        <Typography sx={{ mb: 1 }} component="h3" variant="body" align="center" >Select the student you wish to import your programs for</Typography>
            <TreeView
                disabled={isUploading}
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={props.expandedGroupIDs}
                sx={{ height: 400, flexGrow: 1, width: '550px', overflowY: 'auto' }}
                onNodeToggle={(event, nodeIDs) => {
  
                    for (let key in nodeIDs) {
                        let id = nodeIDs[key]
                        let group = allGroups[id]
                        if (group && !group.subgroups) {
                            fetchSubgroups(group.id)
                        }
                    }
                }}>
                {
                    treeUpdated && allGroups && renderTree(true, null)
                }
            </TreeView>

            <Typography component="h3" sx={{mt: 2 }} variant="body" align="center" >Select program files you wish to import(.doc, .docx, .txt)</Typography>

            <Box sx={{ width: '100%', mt: 1 }}>
                <DropzoneArea
                    dropzoneProps={ { disabled: isUploading} }
                    disabled={isUploading}
                    key={`dropzone_${dropzoneIndex}`}
                    showPreviews={false}
                    fullWidth={false}
                    showPreviewsInDropzone={false}
                    filesLimit={3000}
                    maxFileSize={15000000}
                    onChange={(files) => onDrop(files)}
                    acceptedFiles={['.txt', '.docx']}
                    // acceptedFiles={['.doc', '.txt', '.docx']}
                    dropzoneText={"Drag and drop your files here or click to browse"} />
            </Box>
            {Object.keys(selectedFiles).length > 0 &&
                <Typography sx={{ mt: 1 }} component="h4" variant="body">{Object.keys(selectedFiles).length} files selected</Typography>
            }
            <Box sx={{ mb: 4 }}>
            {Object.keys(selectedFiles).map((file, index) => {
                return <Box sx={{ ...style.fileContainer }} >
                    <Box sx={{ ...style.file }} >
                        <UploadFileIcon sx={{ mr: 1, width: '30px', height: '30px' }} color='primary' />
                        <Box sx={{ ...style.fileText }}>
                            <Typography style={{ wordWrap: "break-word" }} component="div" variant="body">{file}</Typography>
                        </Box>
                        <IconButton sx={{ mr: 0, ml: 'auto' }} aria-label="delete" onClick={(e) => removeFile(file)}>
                            { !isUploading ? <DeleteIcon /> : <></> }
                        </IconButton>
                    </Box>

                </Box>
            })
            }
            </Box>

        </Box>

     
    <LoadingInformationDialog open={isUploading || isDone} show={isUploading || isDone} completed={isDone} items={loadingDialogModel} title='Importing...' />
    
    </Box>
}