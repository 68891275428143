import './App.css';
// import { useForm, Resolver } from 'react-hook-form';
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CreateSignatureAccount from './pages/register/create-signature-account';
import { ToastContainer } from 'react-toastify';
import CreateAccount from './pages/register/create-account';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#268da5',
      contrastText: '#fff'
    },
    success: {
      main: '#4BB543'
    },
    info: {
      main: '#0288d1'
    }
  }
});

export default function App() {
  return (
    <div className="app" style={{ height: '100vh', width: '100%' }}>
      <ThemeProvider theme={theme}>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RecoilRoot>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/create-signature-account" element={<CreateSignatureAccount />} />
              {/* <Route path="/register" element={<CreateAccount />} /> */}
              <Route path="/dashboard/*" element={<Dashboard />} />
            </Routes>
          </BrowserRouter>
        </RecoilRoot>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

