import { TreeItem, TreeView } from "@mui/lab";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fetchUserAdminGroups } from "../../api/administration-api";
import { printObject } from "../../utils/print_object";

export default function MyGroupsTree(props) {
    const [rootGroups, setRootGroups] = useState(props.rootGroups)
    const [isSubmitting, setSubmitting] = useState(false)
    const [selectedGroupKey, setSelectedGroupKey] = useState(null)
    const [selectedGroupName, setSelectedGroupName] = useState(null)

    const [treeUpdated, setTreeUpdated] = useState(1)

    const [groupTree, setGroupTree] = useState(null)
    const [allGroups, setAllGroups] = useState({})
    const [allStudents, setAllStudents] = useState({})

    useEffect(() => {
        console.log('props.rootGroups changed')
        setRootGroups(props.rootGroups)
        console.log('update')
        setRootGroups(props.rootGroups)
        let all = {}
        addRootGroupsToAll(props.rootGroups, all)
        setAllGroups(all)
        setGroupTree(props.rootGroups)

        setTreeUpdated(treeUpdated + 1)
        
    }, [props.rootGroups])

    useEffect(() => {
        props.onSelectGroupKey?.(selectedGroupKey)
    }, [selectedGroupKey])

    useEffect(() => {
        props.onSelectGroupName?.(selectedGroupName)
    }, [selectedGroupName])

    function renderTree(isRoot, parent) {
        if (!groupTree) {
            console.log('no group tree')
            return <CircularProgress />
        } else if (isRoot) {
            return groupTree.map((sub) => {
                return renderTree(false, sub)
            })
        } else if (!parent) {
            console.log('no parent')
            return <CircularProgress />
        }

        return <TreeItem nodeId={parent.id}
            disabled={isSubmitting}
            collapseIcon={parent.isEmpty ? <></> : <ExpandMoreIcon />}
            expandIcon={parent.isEmpty ? <></> : <ChevronRightIcon />}
            label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={event => event.stopPropagation()}>
                <Typography >{parent.title}</Typography>
            </Box>
            }>
            {
                parent?.subgroups && parent?.subgroups.map((sub) => {
                    return renderTree(false, sub)
                })
            }
            {
                !parent.isEmpty && parent?.subgroups == null && <CircularProgress size={32} />
            }
        </TreeItem>

    }

    function addRootGroupsToAll(arr, all) {
        for(let key in arr) {
            let item = arr[key]
            all[item.id] = item
            if(item.subgroups) {
                addRootGroupsToAll(item.subgroups, all)
            }
        }
    }

    function addToAllGroups(arr) {
        let all = allGroups ?? {}
        let alls = allStudents ?? {}
        for (let key in arr) {
            let item = arr[key]
            if (item.type == 'group') {
                all[item.id] = item
            } else if (item.type == 'student') {
                alls[item.id] = item
            }

            let subgroups = item.subgroups ?? []
            subgroups.forEach((subg) => {
                if (subg.type == 'group') {
                    all[subg.id] = subg
                } else if (subg.type == 'student') {
                    alls[subg.id] = subg
                }
            })
        }
        printObject('All groups')
        setAllGroups(all)
        setAllStudents(alls)
    }

    function findGroup(id, tree) {
        return allGroups[id]
    }

    function fetchSubgroups(parentID) {
        fetchUserAdminGroups(parentID).then((result) => {
            addToAllGroups(result.data)

            let tree = groupTree
            let item = findGroup(parentID, tree)

            if (item) {
                item.subgroups = result.data
                if (result.data.length == 0) {
                    item.isEmpty = true
                }
            }

            setTreeUpdated(treeUpdated + 1)
            setGroupTree(tree)
        })
    }

    return <Box>
            { treeUpdated && <TreeView
                disabled={isSubmitting}
                aria-label="my groups navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ height: '700px', flexGrow: 1, width: '700px', overflowY: 'auto' }}
                onNodeFocus={(event, id) => {
                    setSelectedGroupKey(id)
                    setSelectedGroupName(allGroups[id].title)
                }}

                onNodeToggle={(event, nodeIDs) => {
                    for (let key in nodeIDs) {
                        let id = nodeIDs[key]
                        let group = allGroups[id]
                        if (group && !group.subgroups) {
                            fetchSubgroups(group.id)
                        }
                    }
                }}
            >
                {
                    treeUpdated && allGroups && renderTree(true, null)
                }
            </TreeView> }
        </Box>
}
