import StandaloneProgram from "./standalone_program";
import { Typography, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchUserInGroup } from "../../api/user";
import { getCurrentUser } from "../../components/firebase/firebase";
import { fetchFunctionData } from "../../api/functions";
import { printObject } from "../../utils/print_object";
import { useRecoilState } from "recoil";
import { cancelEditingFunctionAtom, reloadedFunctionDataAtom, reloadFunctionAtom, selectedFunctionAtom } from "../../recoil/atom/function_atom";
import { showError } from "../../components/toasts";

export default function FunctionContainer(props) {

    const [userProfile, setUserProfile] = useState(null);
    const [functionData, setFunctionData] = useState(props.item)
    const [cancelEditing, setCancelEditing] = useRecoilState(cancelEditingFunctionAtom)
    const [reloadedFunctionData, setReloadedFunctionData] = useRecoilState(reloadedFunctionDataAtom)
    const [selectedFunction, setSelectedFunction] = useRecoilState(selectedFunctionAtom)
    const [reloadFunction, setReloadFunction] = useRecoilState(reloadFunctionAtom)
    
    useEffect(() => {
        fetchUserProfile()
    }, [props.groupID]);

    useEffect(() => {
        if(reloadFunction) {
            doReloadFunctionData()
        }
    }, [reloadFunction]);

    useEffect(() => {
        if(props.groupID && props.studentID && props.functionID) {
            setSelectedFunction({ 
                groupID: props.groupID,
                studentID: props.studentID,
                functionID: props.functionID
            })
        }
    }, [props.groupID, props.studentID, props.functionID])

    useEffect(() => {
        if(cancelEditing) {
            doReloadFunctionData()
        }
        setCancelEditing(false)
    }, [cancelEditing]);

    function fetchUserProfile() {
        if (props.groupID) {
            fetchUserInGroup({ userID: getCurrentUser().uid, groupID: props.groupID }).then((response) => {
                setUserProfile(response.data)
            })
        }
    }

    function doReloadFunctionData() {
        let groupID = props.groupID
        let studentID = props.studentID
        let functionID = props.functionID
        if(groupID && studentID && functionID) {
            fetchFunctionData({groupID: groupID, studentID: studentID, functionID: functionID})
                .then((resp) => {
                    let data = resp.data
                    setFunctionData(data)
                    setReloadedFunctionData(data)
                }).catch((err) => {
                    showError(err)
                }).finally(() => {
                    setReloadFunction(false)
                })
        }
    }

    function renderFunction() {
        if(!functionData) {
            return <></>
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        }}>
            
            <StandaloneProgram 
            {...props}
            functionData={functionData}
            
            reloadFunctionData={doReloadFunctionData}
            userProfile={userProfile} />
        </Box>
    }

    return (
        <div>
            {!userProfile &&
                <div className="centerHorizontally fullWidth" style={{ marginBottom: '16px' }}>
                    <CircularProgress className="spinner" />
                </div>
            }
            {
                renderFunction()
            }
        </div>
    );
}