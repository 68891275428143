import { Box, createStyles, Icon, ListItem, List, ListItemButton, ListItemIcon, ListItemText, withStyles, Divider } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { styled } from '@mui/material/styles';
import { MenuBook } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { userProfileState } from "../../recoil/atom/user-state";
import { useRecoilState } from "recoil";
import { UserTypeEnum } from "../../api/user";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

export const sideMenuWidth = 244

const StyledList = styled(List)({
    // selected and (selected + hover) states
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: '#d8d8d8',
        '&, & .MuiListItemIcon-root': {
            color: 'black',
        },
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
        backgroundColor: '#f0f0f0',
        '&, & .MuiListItemIcon-root': {
            color: 'black',
        },
    },
    '.MuiListItemIcon-root': {
        minWidth: '36px'
      }
});

interface MenuItem {
    id: string
    title: string
    path: string
}

export default function SideMenu() {
    const [items, setItems] = useState<Array<MenuItem>|null>(null);
    const [userType, setUserType] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [currentUserProfileState, setCurrentUserProfileState] = useRecoilState<any>(userProfileState)
    let regularUserItems: Array<MenuItem> = [
        { id: 'home', title: "Home", path: "/dashboard/home" },
        { id: 'my_groups', title: "My Groups", path: "/dashboard/my_groups" },
        { id: 'program_library', title: "Program Library", path: "/dashboard/program_library" },
        { id: 'reporting', title: "Reporting", path: "/dashboard/reports" },
        { id: 'import_data', title: "Import Data", path: "/dashboard/import" },
        { id: 'organization', title: "Organization", path: "/dashboard/organization" }
    ]

    let signatureUserItems: Array<MenuItem> = [
        { id: 'home', title: "Home", path: "/dashboard/home" },
        { id: 'signatures', title: "Signatures", path: "/dashboard/signatures" }
    ]

    // useEffect(() => {
    //     setItems(regularUserItems)
    // }, [])

    useEffect(() => {
        if(!currentUserProfileState) {
            console.log('null')
            setItems(null)
        } else {
            let userType = currentUserProfileState.type
            setUserType(userType)
            
            if(UserTypeEnum.isSignatureType(userType)) {
                setItems(signatureUserItems)
            } else {
                setItems(regularUserItems)
            }
 
        }
    }, [currentUserProfileState])


    function renderIcon(index: number) {
        if(userType == null) {
            return
        }

        var icon = (<HomeIcon />)
        if(UserTypeEnum.isSignatureType(userType)) {
            switch (index) {
                case 0:
                    icon = (<HomeIcon />)
                    break
                case 1:
                    icon = (<AssignmentIcon />)
                    break
            }
        } else { 
            switch (index) {
                case 0:
                    icon = (<HomeIcon />)
                    break
                case 1:
                    icon = (<GroupsIcon />)
                    break
                case 2:
                    icon = (<MenuBookIcon />)
                    break
                case 3:
                    icon = (<AssessmentIcon />)
                    break
                case 4:
                    icon = (<ArrowDownwardIcon />)
                    break
                case 5:
                    icon = (<CorporateFareIcon />)
                    break
            }
        }
        
        
        return icon
    }

    const currentPath = useLocation().pathname

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
    };

    function isSelected(index: number) {
        if(!items) {
            return false
        }
        
        let url = window.location.href
        return url.includes(items[index].path) 
    }

    useEffect(() => {
        if(!items) {
            return
        }

        setSelectedIndex(items.findIndex((value) => { return value.path == currentPath }))
    }, [selectedIndex])

    return (<>
        <Box
            sx={{
                position: 'fixed',
                top: '55px',
                width: `${sideMenuWidth}px`, 
                height: '100%',
                bgcolor: '#f8f8f8',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'stretch'
            }}
        >
            <StyledList sx={{ width: '100%' }} >
                {items && items.map((value, index) => {

                    return (
                        <ListItem component={Link} to={value.path} key={value.path} sx={{ width: '100%', color: 'black', borderRadius: '8px', margin: '0px', pb: '4px', pt: 0 }}>
                            <ListItemButton
                                sx={{ width: '100%', borderRadius: '8px', margin: '0px', paddingTop: '2px', paddingBottom: '2px' }}
                                selected={isSelected(index)}
                                onClick={(e) => {
                                    return handleListItemClick(index);
                                }} >
                                <ListItemIcon >
                                    {renderIcon(index)}
                                </ListItemIcon  >
                                <ListItemText primary={value.title} />
                            </ListItemButton>
                        </ListItem>
                    );
                })
                }
            </StyledList >
            <Divider orientation="vertical" flexItem />
        </Box >
    </>
    );
}