import React, { useEffect, useState } from 'react';
import data from '../model.json';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { borders } from '@mui/system';
import '../assets/css/edit_program.css'
import '../assets/css/student_list.css'
import { fetchStudents } from '../api/students'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';

interface GroupDescriptor {
    key: string
    name: string
    parentID?: string
    rootID?: string
}

interface UserInGroup {
    key: string
    firstname: string
    lastname?: string | null
    email: string
    photoURL?: string | null
    role: number
    groups: Array<GroupDescriptor>
}

export interface UserInGroupParameter {
    userID: string
    groupID: string
}

interface StudentListProps {
    onStudentSelected?: (name: string, student: UserInGroupParameter) => void
}

export default function StudentList({ onStudentSelected }: StudentListProps) {
    const [loading] = useState(false)
    const [students, setStudents] = useState<Array<UserInGroup> | null>(null)
    const [selectedStudent, setSelectedStudent] = useState<string | null>(null)
    const [groupSelection, setGroupSelection] = useState<{ [key: string]: GroupDescriptor }>({})

    useEffect(() => {
        fetchStudents().then((result) => {
            const usersData = result.data as { [key: string]: UserInGroup }

            if (usersData) {
                var defaultGroups: { [key: string]: GroupDescriptor } = {}
                var students: Array<UserInGroup> = []
                for (let key in usersData) {
                    let student = usersData[key]
                    console.log("fetched " + key + " ; role: " + student?.role + " ; name " + student?.firstname)
                    if(student?.role == 7) { // 7 is a student role
                        console.log("student")
                        if (student.groups.length > 0 && student.groups[0]) {
                            console.log("default group")
                            defaultGroups[key] = student.groups[0]
                        }
                        students.push(student)
                    }
                }

                setGroupSelection(defaultGroups)
                setStudents(students)
            }
        });
    }, [loading]);

    function studentFullName(user: UserInGroup): string {
        if (user.lastname) {
            return user.firstname + " " + user.lastname
        } else {
            return user.firstname
        }
    }

    function onGroupChange(selectedGroupKey: string, studentKey: string) {
        if(students) {
            const student = studentForKey(studentKey)
            if(student) {
                for(let key in student.groups) {
                    let group = student.groups[key]
                    if(group.key == selectedGroupKey){
                        let groups = groupSelection
                        groups[studentKey] = group
                        setGroupSelection({
                            ...groupSelection,
                            studentKey: group
                        })
                        return;
                    }
                }
            }
        }
    }

    function studentForKey(studentKey: string): UserInGroup|null {
        if (students) {
            for(let key in students) {
                const student = students[key]
                if(student.key == studentKey) {
                    return student
                }
            }
        }        
        return null
    }

    function onStudentCheckboxSelected(student: UserInGroup) {
        setSelectedStudent(student.key)
        const user: UserInGroupParameter = {
            groupID: groupSelection[student.key].key,
            userID: student.key
        } 
        
        onStudentSelected?.(studentFullName(student), user)
    }
    return (
        <div className='rootContainer'>
            <Typography component="h1" variant="h5">
                Choose a Student
            </Typography>
            {!students && <CircularProgress className="spinner" />}

            {students &&

                <div className='studentsContainer'>

                    <List className='studentsList'>
                        {
                            students && students.map((student, index) => {
                                return <ListItem key={student.key} >
                                    <Checkbox 
                                        checked={student.key == selectedStudent} 
                                        onClick={() => onStudentCheckboxSelected(student) } />
                                    <ListItemText>{studentFullName(student)}</ListItemText>
                                    <Select value={groupSelection[student.key].key}
                                            onChange={(event) => onGroupChange(event.target.value, student.key)}
                                            key={student.key+"_combobox"}>
                                        {
                                            student.groups.map((group) => {
                                                return <MenuItem value={group.key} key={"item" + group.key}>
                                                    {group.name}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                </ListItem>
                            })
                        }
                    </List>
                </div >
            }
        </div >
    );
}

