import { Dialog, Modal } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 2,
};

export default function LoadingInformationDialog(props) {

    const [currentIndex, setCurrentIndex] = useState(null)
    const [completed, setCompleted] = useState(false)
    const [items, setItems] = useState(props.items)
    const [title, setTitle] = useState(props.title)
    const timer = React.useRef()

    const defaultTitle = "Creating Report..."
    const defaultItems = [
        { text: 'Collecting Data', duration: 3 },
        { text: 'Preparing Programs', duration: 3 },
        { text: 'Generating Report', duration: 3 },
        { text: 'Uploading Files', duration: 3000 }
    ]

    useEffect(() => {
        setCurrentIndex(0)
        setCompleted(false)
    }, [])

    useEffect(() => {
        if(props.completed) {
            setCompleted(props.completed)
        }
        
    }, [props.completed])

    useEffect(() => {
        if(!props.show) {
            setCurrentIndex(0)
            if(timer.current) {
                clearTimeout(timer.current)
            }
            setCompleted(false)
            
            return
        }

        let model = items ?? defaultItems
        if(!currentIndex && currentIndex != 0) { return }
        const item = model[currentIndex]
        let delay = item.duration * 1000

        timer.current = window.setTimeout(() => {
            setCurrentIndex(currentIndex + 1)
        }, delay);
    }, [currentIndex, props.show])

    return <Modal open={props.open}>
        <Box sx={{ ...style }}>
            <div sx={{ width: '100%', mb: '16px' }}>
            <Typography id="modal-modal-title"  component="h2" variant="h6" align="center">
                { title ?? defaultTitle }
            </Typography>
            </div>
            
            { (items ?? defaultItems).map((item, idx) => {
                return <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 1, mb: 1 }}>
                    <Typography id="modal-modal-description" sx={{ mr: 2 }} variant='body'>
                        {item.text}
                    </Typography>
                    { (idx == currentIndex && !completed) ? <CircularProgress size='32px' sx={{ ml: '8px', width: '44px', height: '44px'}} /> : <CheckCircleOutlineIcon fontSize='large' sx={{ ml: '8px', color: 'green', opacity: (idx < currentIndex || completed) ? 1 : 0.1  }} /> }
                </Box>
                })
            }
    </Box>
    </Modal >
}