import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography } from '@mui/material';

interface AddSignaturePlusProps {
    onClick: () => void
}

export default function AddSignaturePlus({ onClick }: AddSignaturePlusProps) {
    return (
        <Box onClick={() => onClick()} 
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer'
                    }}>   
            <AddCircleOutlineIcon sx={{ marginRight: '4px' }}/>Add Signature
        </Box>
    );
}