import { AppBar, Box, Button, CircularProgress, createTheme, Divider, IconButton, Menu, MenuItem, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { borderBottom } from "@mui/system";
import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from "react";
import Image from 'material-ui-image'
import { ArrowBack, Label } from "@mui/icons-material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useRecoilState } from "recoil";
import { isEditingFunctionAtom, cancelEditingFunctionAtom, updatingFunctionAtom, doSubmitFunctionAtom, selectedFunctionDescriptorAtom, selectedFunctionAtom, reloadFunctionAtom } from "../../recoil/atom/function_atom";
import CancelEditingDialog from "./cancel_editing_dialog";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SimpleDialog from "../dialogs/simple_dialog";
import { printObject } from "../../utils/print_object";
import { activateProgram, copyToHomeProgramming } from "../../api/functions/api_standalone_program";
import { showError, showLoading, showSuccess, updateToastError, updateToastSuccess } from "../toasts";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddHomeIcon from '@mui/icons-material/AddHome';
import InputDialog from "../dialogs/input_dialog";
import { generateRandomKey } from "../../utils/random_key";
import { sideMenuWidth } from "../sidemenu/side_menu";
import DeleteDialog from "../dialogs/delete-dialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { archiveFunction } from "../../api/functions";
const theme = createTheme({
    palette: {
        primary: {
            main: '#268DA5',
            dark: '#0c6472',
        }
    },
});


function FunctionSubheader(props) {
    const [title, setTitle] = useState(props.title)
    const [titleColor, setTitleColor] = useState("#48a5d3")
    const [studentName, setStudentName] = useState(props.studentName)
    const [isEditingFunction, setIsEditingFunction] = useRecoilState(isEditingFunctionAtom)
    const [studentPhotoURL, setStudentPhotoURL] = useState(props.studentPhotoURL)
    const [cancelEditingState, setCancelEditing] = useRecoilState(cancelEditingFunctionAtom)
    const [updatingFunction, setUpdatingFunction] = useRecoilState(updatingFunctionAtom)
    const [showingCancelDialog, setShowingCancelDialog] = useState(false)
    const [showingSwitchToHomeProgramDialog, setShowingSwitchToHomeProgramDialog] = useState(false)
    const [showingActivateProgramDialog, setShowingActivateProgramDialog] = useState(false)
    const [showingArchiveProgramDialog, setShowingArchiveProgramDialog] = useState(false)
    const [isArchiving, setArchiving] = useState(false)
    const [doSubmitFunction, setDoSubmitFunction] = useRecoilState(doSubmitFunctionAtom)
    const [moreAnchor, setMoreAnchor] = useState(null)
    const [moreOptions, setMoreOptions] = useState([])
    const [showMoreButton, setShowMoreButton] = useState(false)
    const [functionState, setFunctionState] = useRecoilState(selectedFunctionDescriptorAtom)
    const [functionTitle, setFunctionTitle] = useState("")

    const [selectedFunction, setSelectedFunction] = useRecoilState(selectedFunctionAtom)
    const [reloadFunction, setReloadFunction] = useRecoilState(reloadFunctionAtom)
    const [homeProgrammingToastID, setHomeProgrammingToastID] = useState("homeProgrammingToastID")
    useEffect(() => {
        setTitle(props.title)
        if (props.titleColor) {
            setTitleColor(props.titleColor)
        }

    }, [props.title, props.titleColor])

    useEffect(() => {
        if (!functionState) {
            setMoreOptions([])
            setFunctionTitle("")
            return;
        }

        let moreOptions = []
        setFunctionTitle(functionState?.function?.title ?? "")

        if (functionState.canActivateProgram === true) {
            moreOptions.push({ id: 'activate', title: 'Activate Program', onClick: onActivateProgram })
        }

        if (functionState.canMoveToHomeProgramming === true) {
            moreOptions.push({ id: 'copy_to_home', title: 'Copy to Home Programming', onClick: onCopyToHomeProgramming })
        }

        if(functionState.canArchiveProgram === true) {
            moreOptions.push({ id: 'archive_program', title: 'Archive', onClick: onArchiveProgram, color: 'red' })
        }

        setMoreOptions(moreOptions)

        let shouldShowMoreOptionsButton = false
        for (let key in moreOptions) {
            if (moreOptions[key]) {
                shouldShowMoreOptionsButton = true
                break
            }
        }
        setShowMoreButton(shouldShowMoreOptionsButton)

    }, [functionState])

    function iconFor(id) {
        if (id) {
            switch (id) {
                case "activate":
                    return <RestartAltIcon color="primary" />
                case "copy_to_home":
                    return <AddHomeIcon color="primary" />
                case "archive_program":
                    return <DeleteForeverIcon color="error" />
            }
        }

        return <></>;
    }


    function cancelEditing() {
        setShowingCancelDialog(true)
    }

    function handleCloseMore() {
        setMoreAnchor(null)
    }

    function onActivateProgram() {
        setMoreAnchor(null)
        setShowingActivateProgramDialog(true)
    }

    function onArchiveProgram() {
        setMoreAnchor(null)
        setShowingArchiveProgramDialog(true)
    }

    function onCopyToHomeProgramming() {
        setMoreAnchor(null)
        setShowingSwitchToHomeProgramDialog(true)
    }

    function onCopyToHomeClicked(value) {
        setShowingSwitchToHomeProgramDialog(false)

        let groupID = selectedFunction.groupID
        let studentID = selectedFunction.studentID
        let functionID = selectedFunction.functionID

        if (value) {
            if (groupID && studentID && functionID) {
                
                setHomeProgrammingToastID(showLoading("Copying program...", "homeProgrammingToastID"))
                copyToHomeProgramming({
                    groupID: groupID,
                    studentID: studentID,
                    functionID: functionID,
                    newTitle: value
                }).then((response) => {
                    if (response.data.error) {
                        updateToastError(response.data.error, homeProgrammingToastID)
                        setHomeProgrammingToastID("homeProgrammingToastID")
                    } else {
                        updateToastSuccess("Program successfully copied.", homeProgrammingToastID)
                        setHomeProgrammingToastID("homeProgrammingToastID")
                    }
                }).catch((err) => {
                    updateToastError(err, homeProgrammingToastID)
                    setHomeProgrammingToastID("homeProgrammingToastID")
                })
            } else {
                let missing = {
                    groupID: groupID ?? "null",
                    studentID: studentID ?? "null",
                    functionID: functionID ?? "null",
                }
                showError("Missing data " + missing, homeProgrammingToastID)
                setHomeProgrammingToastID("homeProgrammingToastID")
            }

        }
    }

    async function onArchiveCompleted(shouldArchive) {

        if(!shouldArchive) {
            setShowingArchiveProgramDialog(false)
            return
        } 

        let groupID = selectedFunction.groupID
        let studentID = selectedFunction.studentID
        let functionID = selectedFunction.functionID

        if (groupID && studentID && functionID) {
            setArchiving(true)

            let result = await archiveFunction({ groupID, studentID, functionID})

            setArchiving(false)
            if(result.data.error) {
                showError(result.data.error)
            } else {
                setShowingArchiveProgramDialog(false)
                showSuccess(result.data.message ?? 'Program archived')
                props.onBack?.()
            }
        }
    }

    function onActivateButtonClicked(value) {
        setShowingActivateProgramDialog(false)
        if (!value) {
            return
        }

        let groupID = selectedFunction.groupID
        let studentID = selectedFunction.studentID
        let functionID = selectedFunction.functionID

        if (groupID && studentID && functionID) {
            setUpdatingFunction(true)
            activateProgram({
                groupID: groupID,
                studentID: studentID,
                functionID: functionID
            }).then((response) => {
                if (response.data?.error) {
                    showError(response.data.error)
                } else {
                    showSuccess('Program Activated.')
                    setReloadFunction(true)
                }
                setUpdatingFunction(false)

            }).catch((err) => {
                showError(err)
                setUpdatingFunction(false)
            })
        }
    }

    function cancelDialogClose(value) {
        if (value) {
            setIsEditingFunction(false)
            setCancelEditing(true)
        }
        setShowingCancelDialog(false)
    }

    function onMore(event) {
        setMoreAnchor(event.currentTarget);
    }

    return (
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: `${sideMenuWidth}px`,
                zIndex: 1,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1,
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>

            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '200px' }}>
                <IconButton onClick={() => props.onBack?.()}>
                    <ArrowBackIcon sx={{ ml: 1, mr: 2, color: '#3c7ebf' }} />
                </IconButton>
                <Image
                    // src={studentPhotoURL} 
                    loading={
                        <AccountCircle sx={{ color: 'white' }} />
                    } />
                <Typography sx={{ ml: '16px' }} variant="body">{studentName}</Typography>

            </Box>
            <Typography flexGrow={1} sx={{ position: 'relative', ml: '16px', fontWeight: 'medium', textAlign: 'center', color: '#48a5d3' }} variant="h5">{title}</Typography>

            <Box sx={{ marginRight: '8px', width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'end' }} >

                {updatingFunction &&
                    <CircularProgress />
                }

                {!updatingFunction && props.editMode == 'canEdit' &&
                    <>
                        <Button sx={{ mt: '12px', mb: '12px', mr: '16px' }} variant="contained" onClick={() => setIsEditingFunction(true)}>Edit</Button>
                        {
                            showMoreButton && <IconButton onClick={onMore}>
                                <MoreHorizIcon />
                            </IconButton>
                        }
                    </>
                }

                {!updatingFunction && props.editMode == 'editing' &&
                    <>
                        <Button sx={{ mt: '12px', mb: '12px', mr: '16px' }} color='error' variant="contained" onClick={() => cancelEditing()}>Cancel</Button>
                        <Button sx={{ mt: '12px', mb: '12px', mr: '24px' }} variant="contained" onClick={() => setDoSubmitFunction(true)}>Submit</Button>
                    </>
                }

                <Menu
                    id="menu-appbar"
                    anchorEl={moreAnchor}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={moreAnchor !== null}
                    onClose={handleCloseMore}>
                    {
                        moreOptions.map((option, idx) => {
                            return <MenuItem onClick={option.onClick} value={option.id}>
                                {idx != 0 && <Divider  />}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {iconFor(option.id)}
                                    <span style={{ marginLeft: 6, color: option.color ?? null}}>{option.title}</span>
                                </div>
                            </MenuItem>
                        })
                    }
                </Menu>
            </Box>
            <CancelEditingDialog open={showingCancelDialog} onCompleted={(value) => cancelDialogClose(value)} />

            <SimpleDialog open={showingActivateProgramDialog} yesTitle="Activate" noTitle="Cancel" onCompleted={onActivateButtonClicked} title="Please Confirm" text="Are you sure you want to activate this program?" />
            <DeleteDialog isLoading={isArchiving} onCompleted={onArchiveCompleted} deleteTerm='archive' open={showingArchiveProgramDialog} yesTitle="Archive" noTitle="Cancel" />

            {functionTitle &&
                <InputDialog inputLabel="Home Program Title"
                    key={"input_dialog_home_programming" + functionTitle}
                    inputText={"Home - " + functionTitle}
                    open={showingSwitchToHomeProgramDialog}
                    yesTitle="Yes, I'm sure"
                    noTitle="Cancel"
                    onCompleted={(newTitle) => onCopyToHomeClicked(newTitle)}
                    title="Copy to Home Programming"
                    lines={[
                        "This will make a new copy of the program and any associated functions, without any of the existing data, in the student's main folder under the subheader 'Home Programming'.",
                        "",
                        "Are you sure you want to make a Home Program copy of this program?"
                    ]}
                />
            }

        </Box>
    );
};

export default FunctionSubheader;