export const defaultWrittenComponentOptions = [
    {
        "tf_keys": ['program_type'],
        "ff_keys": ['programType'],
        "key": "program_type",
        "title": "Program Type"
    },
    {
        "tf_keys": ['response_definition'],
        "ff_keys": ['behaviorSkillDefinition'],
        "key": "operational_defininition_of_behavior_skill",
        "title": "Operational Defininition of Behavior/Skill"
    },
    {
        "tf_keys": ['measurement_procedure'],
        "ff_keys": ['measurementInfo/measurementType'],
        "key": "measurement_type",
        "title": "Measurement Type"
    },
    {
        "tf_keys": [],
        "ff_keys": ['measurementInfo/measurementType'],
        "key": "measurement_notes",
        "title": "Measurement Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "success_mastery_criterion",
        "title": "Success/Mastery Criterion"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "next_step",
        "title": "Next Step"
    },
    {
        "tf_keys": ['interobserver_agreement'],
        "ff_keys": [],
        "key": "ioa",
        "title": "IOA"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "teaching_materials",
        "title": "Teaching Materials"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "other_additional_materials",
        "title": "Other/Additional Materials"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "curriculum",
        "title": "Curriculum"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "pretest",
        "title": "Pretest"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "teaching_intervention_instructions",
        "title": "Teaching/Intervention Instructions"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "response_prompts",
        "title": "Response Prompts"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "response_prompt_notes",
        "title": "Response Prompt Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "stimulus_prompts",
        "title": "Stimulus Prompts"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "stimulus_prompt_notes",
        "title": "Stimulus Prompt Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "error_correction",
        "title": "Error Correction"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "reinforcement_procedure",
        "title": "Reinforcement Procedure"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "reinforcement_schedule",
        "title": "Reinforcement Schedule"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "reinforcement_notes",
        "title": "Reinforcement Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "generalization_schedule",
        "title": "Generalization Schedule"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "generalization_programmed_by",
        "title": "Generalization Programmed By"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "generalization_other",
        "title": "Generalization Other "
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "assessment_method",
        "title": "Generalization Assessment Method"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "generalization_notes",
        "title": "Generalization Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_schedule",
        "title": "Maintenance Schedule"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "teaching_reintroduced_if",
        "title": "Teaching Reintroduced If"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_measurement_type",
        "title": "Maintenance Measurement Type"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_measurement_notes",
        "title": "Maintenance Measurement Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_success_mastery_criterion",
        "title": "Maintenance Success/Mastery Criterion"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_ioa",
        "title": "Maintenance IOA"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_strategy_and_intervention",
        "title": "Maintenance Strategy and Intervention"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_materials",
        "title": "Maintenance Materials"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_other_additional_materials",
        "title": "Maintenance Other/Additional Materials"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_teaching_intervention_instructions",
        "title": "Maintenance Teaching/Intervention Instructions"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_response_prompts",
        "title": "Maintenance Response Prompts"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_response_prompt_notes",
        "title": "Maintenance Response Prompt Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_stimulus_prompts",
        "title": "Maintenance Stimulus Prompts"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_stimulus_prompt_notes",
        "title": "Maintenance Stimulus Prompt Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_error_correction",
        "title": "Maintenance Error Correction"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_reinforcement_procedure",
        "title": "Maintenance Reinforcement Procedure"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_reinforcement_schedule",
        "title": "Maintenance Reinforcement Schedule"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_reinforcement_notes",
        "title": "Maintenance Reinforcement Notes"
    },
    {
        "tf_keys": [],
        "ff_keys": [],
        "key": "maintenance_notes",
        "title": "Maintenance Notes"
    }
]