import { Label } from "@mui/icons-material";
import { TextField, Typography, CircularProgress } from "@mui/material";
import { render } from "@testing-library/react";
import SignatureCanvas from 'react-signature-canvas';
import "../../assets/css/signature_canvas.css"
import { getCurrentUser, getStorageURL } from "../firebase/firebase";
import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import { GroupRole } from "../../model/api-model";
import { signSignature, uploadSignatureToStorage } from "../../api/functions/api_standalone_program";
import { ToastContainer, toast } from 'react-toastify';
import AgreeSignatureDialog from "./agree_signature_dialog";
import dateFormat from "dateformat";
import { showError, showSuccess } from "../toasts";
import { useRecoilState } from "recoil";
import { userProfileState } from "../../recoil/atom/user-state";
import { UserTypeEnum } from "../../api/user";
import { generateRandomKey } from "../../utils/random_key";
import { jsonCopyObject } from "../../utils/json_copy";

export default function Signature(props) {
    const [userProfile, setUserProfile] = useRecoilState(userProfileState)
    const [displayName, setDisplayName] = useState("")
    const [displayEmail, setDisplayEmail] = useState("")
    const [canvas, setCanvas] = useState(null)
    const [signedDate, setSignedDate] = useState(null)
    const [signatureTitle, setSignatureTitle] = useState("Sign here")
    const [alreadySigned, setAlreadySigned] = useState(false)
    const [canSign, setCanSign] = useState(false)
    const [signing, setSigning] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [emailRequirement, setEmailRequirement] = useState(null)
    const [signatureImage, setSignatureImage] = useState(null)
    const [model, setModel] = useState(null)
    const [data, setData] = useState(props.model)
    const [digitalSignatureAgreed, setDigitalSignatureAgreed] = useState(false)
    const [showingDigitalSignatureDialog, setShowingDigitalSignatureDialog] = useState(false)
    const [updateRequest, setUpdateRequest] = useState(null)

    useEffect(() => {
        updateCanvasState()
    }, [alreadySigned, canSign, signing, canvas, submitting, props.editMode])

    useEffect(() => {
        reloadWithModel(props.model)
    }, [props.model, userProfile])

    useEffect(() => {
        canvasStateDidChange()
    }, [props.signAttempts])

    useEffect(() => {
        if (props.isSigning !== null && props.isSigning !== undefined) {
            setSigning(props.isSigning)

            if (!props.isSigning) {
                clearCanvas()
            }
        }
    }, [props.isSigning])

    function canvasStateDidChange() {
        let isEmpty = canvas?.isEmpty()
        if (isEmpty === null || isEmpty === undefined) {
            isEmpty = true
        }
        props.onCanvasStateChanged?.(canvas, !isEmpty)
    }

    function reloadWithModel(newModel) {
        
        if (!userProfile) {
            setUpdateRequest(null)
            return;
        }
        let model = jsonCopyObject(newModel)
        setModel(model)
        

        let alreadySigned = model.signature != null

        if (model.signature?.timestamp) {
            let dateString = dateFormat(new Date(model.signature.timestamp), "mm/dd/yyyy, hh:mm:ss")
            setSignedDate(dateString)
        }

        setAlreadySigned(alreadySigned)


        // signature either doesn't require email address or it should match the current user's email
        let signatureHasEmailRequirement = model.email !== null && model.email !== undefined
        let emailsMatch = model.email == userProfile.email

        if (model.email && model.email != userProfile.email && !alreadySigned) {
            setEmailRequirement("This signature is assigned to user " + model.email + ".")
        } else {
            setEmailRequirement(null)
        }

        if (model?.signature?.user?.signatureURL) {
            getStorageURL(model.signature.user.signatureURL).then((url) => {
                setSignatureImage(url)
            })
        }

        let type = userProfile.type ?? -1
        let isSignatureAccount = UserTypeEnum.isSignatureType(type)
        let role = userProfile.role ?? -1
        let hasAppropriateGroupRole = role == GroupRole.Principal || role == GroupRole.AdminTeacher || role == GroupRole.Teacher

        let canSign = false 
        
        if(!alreadySigned) {
            if(signatureHasEmailRequirement) {
                canSign = emailsMatch
            } else {
                // no email requirement, check role and account type
                canSign = (UserTypeEnum.supporter == type) && hasAppropriateGroupRole
            }
        }

        setCanSign(canSign)
        updateCanvasState()

        // for(let i = 0; i<20; i++) {
        //     window.setTimeout(() => {
                setUpdateRequest(generateRandomKey(8))
        //     }, i*250);
        // }
    }

    function updateCanvasState() {
        if (!userProfile || props.editMode) {
            canvas?.off()
            clearData()
        } else if (alreadySigned) {
            canvas?.off()
            setSignatureTitle("Signature")
            let fullname = model.signature?.user?.fullname ?? "fullname"
            let email = model.signature?.user?.email ?? "email"
            setDisplayName(fullname)
            setDisplayEmail(email)
        } else if (canSign && signing) {
            canvas?.on()
            setSignatureTitle("Sign Here")
            let fullname = (userProfile.name ?? "") + " " + (userProfile.lastName ?? "")
            setDisplayName(fullname)
            setDisplayEmail(userProfile.email ?? "")
        } else {
            clearData()
        }

        if (submitting) {
            canvas?.off()
        }
    }

    function clearCanvas() {
        canvas?.clear()
        canvasStateDidChange()
    }

    function clearData() {
        canvas?.off()
        setSignatureTitle("Signature")
        setDisplayName("")
        setDisplayEmail("")
    }

    function onClearSignature() {
        clearCanvas()
    }

    function cancelSigning() {
        setSigning(false)
        clearCanvas()
    }

    function digitalSignatureAgreedValueDidChange(value) {
        setDigitalSignatureAgreed(value)
        setShowingDigitalSignatureDialog(false)
        if (value) {
            doSign()
        }
    }

    function agreeToDigitalSignatureAndSign() {
        if (!digitalSignatureAgreed) {
            setShowingDigitalSignatureDialog(true)
        } else {
            doSign()
        }
    }

    async function doSign() {
        let groupID = props.groupID
        let functionID = props.item?.key ?? props.functionID
        let signatureID = model.key
        let studentID = props.studentID

        if (!groupID || !functionID || !signatureID || !studentID) {
            // show error
            return
        }

        setSubmitting(true)

        // upload signature
        if (canvas.isEmpty()) {
            showError("Canvas is empty")
            setSubmitting(false)
            return;
        }

        let base64 = canvas.toDataURL()

        let uploadResult = await uploadSignatureToStorage(base64, getCurrentUser().uid)

        let signatureURL = uploadResult?.metadata?.fullPath

        if (!signatureURL) {
            showError("Failed to upload signature.")
            setSubmitting(false)
            return;
        }

        let response = await signSignature({
            groupID: groupID,
            studentID: studentID,
            functionID: functionID,
            signatureID: signatureID,
            signatureURL: signatureURL
        })

        if (response.data?.error) {
            showError(response.data.error)
        } else {
            setSigning(false)
            showSuccess("Signature signed successfully")

            let signature = response.data?.signature
            if (signature) {
                let jsonString = JSON.stringify(signature, null, 4);
                // console.log("signature model is " + jsonString)
                signature.key = model.key
                reloadWithModel(signature)
                props.onSignatureSigned?.(signatureID, signature)
            }
        }

        setSubmitting(false)
    }

    function renderSignatureButtons() {
        if (props.editMode) {
            return <span></span>
        } else if (submitting) {
            return <div className="centerHorizontally fullWidth" style={{ marginBottom: '16px' }}>
                <CircularProgress className="spinner" />
            </div>
        } else if (signing) {
            return <div className="fullWidth">
                <Button sx={{ float: 'right', margin: '16px' }} variant="outlined" startIcon={<ClearIcon />} color='error' onClick={() => onClearSignature()}>
                    Clear Signature Canvas
                </Button>
                <Box sx={{
                    marginTop: '32px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    {(props.showSignButton ?? true) &&
                        <Button sx={{ margin: '16px' }} variant="outlined" startIcon={<CreateIcon />} color='success' onClick={() => agreeToDigitalSignatureAndSign()}>
                            Sign
                        </Button>
                    }
                    {(props.showCancelButton ?? true) && <Button sx={{ margin: '16px' }} variant="outlined" startIcon={<CancelIcon />} color='error' onClick={() => cancelSigning()}>
                        Cancel Signing
                    </Button>
                    }
                </Box>
            </div >
        } else if (canSign && (props.showSignSignatureButton ?? true)) {
            return <Button sx={{ marginLeft: '16px', marginTop: '8px', marginBottom: '16px' }} variant="outlined" startIcon={<CreateIcon />} color='primary' onClick={() => setSigning(true)}>
                Sign this signature
            </Button>
        } else {
            return <span></span>
        }
    }

    return <Box>
            { updateRequest && <Box
                sx={{
                    marginTop: "16px",
                    marginRight: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                }} className="round-border">
                <Box
                    m={2}
                    sx={{
                        margin: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                    <Typography sx={{ ml: '8px'}}variant="h5" align="left">
                        {`${model.value}${model.annual ? ' (Annual)' : ''}`}
                    </Typography>

                    <Typography variant="h5" align="right" sx={{ marginLeft: 'auto', marginRight: '24px' }} color={alreadySigned ? 'primary' : 'error'}>
                        {
                            alreadySigned ? "Signed" : "Not signed"
                        }
                    </Typography>
                </Box>

                {
                    <Box
                        m={2}
                        sx={{
                            marginTop: '32px',
                            marginRight: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%'
                        }}>
                        {emailRequirement &&
                            <Typography component="h1" variant="body1" color="warning" sx={{ marginTop: '16px' }}>
                                {emailRequirement}
                            </Typography>
                        }

                        <TextField
                            className='text-field'
                            disabled
                            label="Name"
                            value={displayName}
                            key={"textfield_name"} >
                            {displayName}
                        </TextField>

                        <TextField sx={{ marginTop: "8px" }}
                            className='text-field'
                            disabled
                            label="Email"
                            value={displayEmail}
                            key={"textfield_email"}>
                            {displayEmail}
                        </TextField>


                        { (alreadySigned || canSign) &&
                            <Typography component="h1" variant="body1" sx={{ marginTop: '16px', mr: '24px' }}>
                                I have reviewed this written individualized program and the intervention procedures have been fully explained to me.  I have been given an explanation of the potential benefits of this program, as well as an explanation of possible risks or discomforts.  I give informed consent for the implementation of this program in the manner described above, understanding that I may withdraw consent at any time, and that if consent is withdrawn, the program will be immediately discontinued.
                            </Typography>
                        }

                        <Typography component="h1" variant="body1" sx={{ marginTop: '16px' }}>
                            {signatureTitle}
                        </Typography>
                        <div style={{ width: '100%' }}>
                            {signatureImage &&
                                <img src={signatureImage} className="img-signature" />
                            }
                            {
                                !signatureImage &&
                                <SignatureCanvas penColor='black'
                                    canvasProps={{ className: "signature-canvas" }} ref={(ref) => { setCanvas(ref) }} />
                            }
                        </div>

                        {signedDate &&
                            <div>
                                <Typography component="h1" variant="body1" sx={{ color: 'red' }}>
                                    Signed {signedDate}
                                </Typography>
                                <br />
                            </div>
                        }

                        
                    </Box>
                }
                {renderSignatureButtons()}
            </Box>
            }

            <AgreeSignatureDialog open={showingDigitalSignatureDialog} onCompleted={(value) => digitalSignatureAgreedValueDidChange(value)} />
        </Box>
}

// signature states:
// 1. Signed by user - copy data inline so we don't have to pull the user
// 2. Waiting for a signature - and CANNOT be signed by current user
// 3. Waiting for a signature - and CAN be signed by the current user
// 
// signature: {
//     "signed": true,
//     "timestamp": timestamp,
//     "user": {
//         "id": "userid-12345",
//         "fullname": "John Doe",
//         "email": "abc@def.gh",
//         "signatureURL": "img.jpg"
//     }
// }
// Jane C - F9unQgOSklQjkbrRwox52JSPFHF2