import { httpsCallable } from "firebase/functions";
import { UploadResult } from "firebase/storage";
import { functions, uploadBase64Image } from "../../components/firebase/firebase";
import { SimpleResponse } from '../../model/api-model';
import { generateRandomKey } from "../../utils/random_key";

export const signSignature = httpsCallable(functions, 'standalone_program-signSignature');
export const signAndApplyDiscontinuationRationale = httpsCallable(functions, 'standalone_program-signAndApplyDRAT');
export const activateProgram = httpsCallable(functions, 'standalone_program-activateProgram');
export const copyToHomeProgramming = httpsCallable(functions, 'standalone_program-copyToHomeProgramming');

export const uploadSignatureToStorage = (base64: string, userID: string): Promise<UploadResult> => { 
    let name = generateRandomKey(20)
    let path = `signatures/${userID}/${name}.png`
    
    return uploadBase64Image(base64, path)
}