import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function AgreeSignatureDialog(props) {
    const { onCompleted } = props;

    return (
        <Dialog open={props.open} onClose={() => onCompleted(false)} >
            <DialogTitle>
                Digital Signature Agreement
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContentText sx={{ ml: 3, mr: 3, mb: 1}}>
                By clicking the "Agree" button below, I agree to the electronic signatures in this document. I understand that I may disagree to electronic signature in which case I must sign a paper copy of this form. If I disagree to electronic signatures, I've clicked the "Disagree" button below.
            </DialogContentText>
            <DialogActions>
                <Button id="button_agree" onClick={() => onCompleted(true)}>Agree</Button>
                <Button id="button_disagree" sx={{ color: 'red'}} onClick={() => onCompleted(false)}>Disagree</Button>
            </DialogActions>
        </Dialog>
    );
}