import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { ProgramEntryFieldType } from '../program_library';
import '../../assets/css/edit_program.css';
import Signature from '../../components/signature/signature';
import { jsonCopyObject } from "../../utils/json_copy";
import SignatureField from '../program/signature_field';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@mui/material';
import AddSignaturePlus from '../program/add_signature_plus';
import DiscontinuationRationale from '../program/discontinuation_rationale';
import { generateRandomKey } from '../../utils/random_key';
import { updateStandaloneProgram } from '../../api/functions';
import { showError, showSuccess } from '../../components/toasts';
import { printObject } from '../../utils/print_object';
import AddProgramFieldDialog from '../program/add_program_field';
import AddProgramFieldPlus from '../program/add_program_field_plus';
import { useRecoilState } from 'recoil';
import { selectedFunctionDescriptorAtom, doSubmitFunctionAtom, updatingFunctionAtom, isEditingFunctionAtom } from '../../recoil/atom/function_atom';
import { StandaloneProgramState } from '../../model/api-model';

export default function StandaloneProgram(props) {
    const [entries, setEntries] = useState([])
    const [isHomeProgramming, setHomeProgramming] = useState(false)
    const [signatures, setSignatures] = useState([])
    const [showingAddFieldDialog, setShowingAddFieldDialog] = useState(null)
    const [isEditing] = useRecoilState(isEditingFunctionAtom)
    // const [discontinuationItems, setDiscontinuationItems] = useState([])
    const [title, setTitle] = useState("")
    const [editSignatures, setEditSignatures] = useState([])
    const [updateRequest, setUpdateRequest] = useState(null)
    const [loading, setLoading] = useState(false)
    const [updatingFunction, setUpdatingFunction] = useRecoilState(updatingFunctionAtom)
    const [doSubmitFunction, setDoSubmitFunction] = useRecoilState(doSubmitFunctionAtom)
    const [functionState, setFunctionState] = useRecoilState(selectedFunctionDescriptorAtom)
 
    useEffect(() => {
        console.log('reloaded function data')
        resetState()

        let canActivateProgram = false
        
        let programState = props.functionData?.program?.programState

        let program = props.functionData?.program

        if (programState) {
            canActivateProgram = (programState != StandaloneProgramState.Active)
        }

        console.log('can arhvie ' + props.canArchiveProgram)

        let canMoveToHomeProgramming = program && (program?.category !== 'home')
        setFunctionState({
            function: props.functionData,
            canActivateProgram: canActivateProgram,
            canArchiveProgram: (props.canArchiveProgram === true),
            canMoveToHomeProgramming: canMoveToHomeProgramming
        })
    }, [props.functionData, props.canArchiveProgram])

    useEffect(() => {
        console.log('is editing')
    }, [isEditing])

    useEffect(() => {
        resetState()
    }, [functionState])

    useEffect(() => {
        setUpdatingFunction(loading)
    }, [loading])

    useEffect(() => {
        if (doSubmitFunction) {
            onSubmitChanges()
            setDoSubmitFunction(false)
        }
    }, [doSubmitFunction])

    function handleChange(key, value) {
        if (!isEditing) {
            return;
        }
        let allEntries = jsonCopyObject(entries)
        for (let idx in allEntries) {
            let entry = allEntries[idx]
            if (entry[0] == key) {
                entry[1].value = value
                allEntries[idx][1].value = value
                setEntries(allEntries)
                return
            }
        }
    }

    function resetState() {
        let functionData = jsonCopyObject(props.functionData ?? {})
        printObject(functionData, 'fd')
        let program = functionData.program
        if(!program) {
            console.log('no program')
            return
        }
        let signatures = functionData.program.signatures ?? {}
        let sortedItems = Object.entries(functionData.program.textItems).sort((a, b) => a[1].index < b[1].index ? -1 : 1)
        let isHomeProgramming = functionData.program.category === 'home'
        let signaturesArray = []

        for (let key in signatures) {
            let signature = signatures[key]
            signature.key = key
            signaturesArray.push(signature)
        }

        let dict = functionData.program.discontinuationItems ?? {}
        for (let key in dict) {
            let item = dict[key]
            if (item) {
                item.key = key
                signaturesArray.push(item)
            }
        }

        let sortedSignatures = signaturesArray.sort((a, b) => {
            if(a.fieldType === 'drat' && b.fieldType != 'drat') {
                return -1
            } else if(a.fieldType != 'drat' && b.fieldType === 'drat') {
                return 1
            } else {
                return a.index < b.index ? -1 : 1
            }
        })

        printObject(sortedSignatures, 'signatures object')

        setEntries(jsonCopyObject(sortedItems))
        setSignatures(jsonCopyObject(sortedSignatures))
        setTitle(functionData.program.title)
        setHomeProgramming(isHomeProgramming)

        updateEditSignatures(sortedSignatures)
        setUpdateRequest(generateRandomKey(8))
    }

    function updateEditSignatures(sortedSignatures) {
        let editSignatures = []
        for (let key in sortedSignatures) {
            let signature = sortedSignatures[key]
            let signatureObject = signature.signature
            // edit signatures contains only signatures that are not signed (that don't have a signature object)
            // we also show drats in that list but they're not editable
            if (signature.fieldType !== 'signature' || (signatureObject === null || signatureObject === undefined)) {
                editSignatures.push(signature)
            }
        }

        setEditSignatures(jsonCopyObject(editSignatures))
    }

    function onSignatureEdit(title, type, email, annual, key) {
        let signatures = jsonCopyObject(editSignatures)
        for (let idx in signatures) {
            let signature = signatures[idx]
            if (signature.key == key) {
                signature.value = title
                signature.isParentSignature = null
                signature.annual = annual
                signature.type = type
                signature.email = email
                setEditSignatures(signatures)
                return;
            }
        }
    }

    function onAddItem(type) {
        if (type === "signature") {
            onAddSignature()
        } else if (type === "drat") {
            onAddDiscontinuationRationale()
        } else if (type === "annual-signatures") {
            onAddAnnualSignatures()
        }

        setShowingAddFieldDialog(false)
    }

    function onAddAnnualSignatures() {
        let signatures = jsonCopyObject(editSignatures)
        addSignature(signatures, "Parental Consent", true, true)
        addSignature(signatures, "Evaluator", false, true)
        addSignature(signatures, "Human Rights Committee", false, true)
        addSignature(signatures, "External Evaluator", false, true)
        addSignature(signatures, "Behavior Management Committee", false, true)

        setEditSignatures(signatures)
        showSuccess("Annual signatures added")
    }

    function onAddSignature() {
        let signatures = jsonCopyObject(editSignatures)
        addSignature(signatures, "", false)
        setEditSignatures(signatures)
        showSuccess("Signature added")
    }

    function addSignature(signatures, name, isParentSignature, annual) {
        let signature = {
            fieldType: ProgramEntryFieldType.Signature,
            index: signatures.length,
            label: "Signature",
            key: generateRandomKey(20),
            value: name,
            annual: annual ?? false,
            isParentSignature: isParentSignature
        }

        signatures.push(signature)
    }

    function addDRATItem(items, text, fieldType) {
        let key = generateRandomKey(20)
        items[key] = {
            key: key,
            index: Object.keys(items).length,
            fieldType: fieldType,
            text: text
        }
        return items
    }

    function onAddDiscontinuationRationale() {
        let dratItems = jsonCopyObject(editSignatures) ?? []
        let newItems = {}

        newItems = addDRATItem(newItems, "The student met the goals of this program, and this program will be replaced with", "short")
        newItems = addDRATItem(newItems, "Behavior maintained at an appropriate level.")
        newItems = addDRATItem(newItems, "Pretest or baseline data indicate that the student does not need this program.")
        newItems = addDRATItem(newItems, "Program did not achieve the desired outcome and will be replaced with", "short")
        newItems = addDRATItem(newItems, "Program is temporarily discontinued until", "short")
        newItems = addDRATItem(newItems, "Other (describe)", "multiline")

        let index = dratItems.length
        let dratTemplate = {
            fieldType: 'drat',
            index: index,
            key: generateRandomKey(20),
            signature: {
                fieldType: "signature",
                index: 0,
                label: "Signature",
                value: "Signature"
            },
            items: newItems
        }

        printObject(dratItems, 'pre add')

        dratItems.push(dratTemplate)
        printObject(dratItems, 'post add')
        setEditSignatures(dratItems)
        // setSignatures(dratItems)

        showSuccess("Discontinuation Rationale added")
    }

    function onRemoveSignature(key) {

        let signatures = []
        for (let idx in editSignatures) {
            let signature = editSignatures[idx]
            if (signature.key !== key) {
                signatures.push(signature)
            }
        }

        setEditSignatures(signatures)
    }

    function onRemoveDRATClicked(dratKey) {
        if (!editSignatures) {
            return
        }

        let dratItems = jsonCopyObject(editSignatures)

        dratItems = dratItems.filter((value) => {
            return value.key !== dratKey
        })
        setEditSignatures(dratItems)
    }

    function onSignatureSigned(signatureKey, signature) {
        let updatedSignatures = signatures

        for(let i in updatedSignatures) {
            if(updatedSignatures[i].key == signatureKey) {
                updatedSignatures[i] = signature
                break
            }
        }

        setSignatures(updatedSignatures)

        updateEditSignatures(updatedSignatures)
    }

    function onSubmitChanges() {
        // printObject(entries, "entries")
        printObject(editSignatures, "editSignatures")
        printObject(signatures, "signatures")

        // validate title
        let title = null
        for (let idx in entries) {
            let entry = entries[idx]
            let key = entry[0]
            if (key === "program_name" || key === "program_full_title") {
                title = entry[1].value.trim()
                if (title && title.length < 3) {
                    showError("Program name must be at least 3 characters long.")
                    return;
                }
            }
        }

        if (!title) {
            showError("Missing title for the program.")
            return;
        }

        let signaturesToUpdate = {}
        let discontinuationItemsToUpdate = {}
        for (let key in editSignatures) {
            let editSignature = editSignatures[key]

            if (editSignature.fieldType === 'signature') {
                signaturesToUpdate[editSignature.key] = editSignature
            } else {
                // drat
                discontinuationItemsToUpdate[editSignature.key] = editSignature
            }

        }

        let entriesToUpdate = {}
        for (let idx in entries) {
            let entry = entries[idx]
            entriesToUpdate[entry[0]] = entry[1]
        }

        let category = isHomeProgramming ? 'home' : ''

        let program = {
            title: title,
            signatures: signaturesToUpdate,
            category: category,
            textItems: entriesToUpdate,
            discontinuationItems: discontinuationItemsToUpdate
        }

        setLoading(true)
        updateStandaloneProgram({
            program: program,
            groupID: props.groupID,
            studentID: props.studentID,
            functionID: props.functionID
        }).catch((err) => {
            showError(err.message)
        }).then((resp) => {
            if (resp.data.error) {
                showError(resp.data.error)
            } else {
                showSuccess(resp.message ?? "Success.")
                props.reloadFunctionData?.()
                props.onFinishedEditing?.()
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
            { updateRequest && <form className='form'>

                {/* {
                    <FormGroup key='home_group'>
                        <FormControlLabel key='home_group_label' control={
                            <Checkbox key='home_group_checkbox'
                                checked={isHomeProgramming} />
                            }
                            label="Toggle on if this is Home Programming"
                            onChange={ (event) => {
                                if(props.editMode && !loading) {
                                    setHomeProgramming(!isHomeProgramming)
                                }
                            }
                            }
                            />
                    </FormGroup>
                } */}

                {
                    entries && entries.map((field) => {
                        return (
                            <div className="form-item" key={field[0] + "_form"}>
                                {
                                    field[1].fieldType == ProgramEntryFieldType.TextField &&
                                    <TextField margin="normal"
                                        required
                                        readOnly={!isEditing || loading}
                                        onChange={(event) => handleChange(field[0], event.target.value)}
                                        fullWidth
                                        name={field[1].label}
                                        key={field[0]}
                                        label={field[1].label} variant="standard"
                                        value={field[1].value}
                                        className='form-textfield' />
                                }

                                {field[1].fieldType == ProgramEntryFieldType.Multiline &&
                                    // TODO: Add placeholder
                                    <TextField className="form-textarea"
                                        key={field[0]}
                                        readOnly={!isEditing || loading}
                                        onChange={(event) => handleChange(field[0], event.target.value)}
                                        fullWidth id="outlined-basic"
                                        rows={12}
                                        multiline={true}
                                        label={field[1].label}
                                        value={field[1].value}
                                        variant="filled" />
                                }

                            </div>
                        );
                    })
                }
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        width: '100%'
                    }}>
                    {
                        !isEditing && signatures &&
                        <>
                            <Typography component="h1" variant="h4" sx={{ mt: 5 }}>
                                Signatures
                            </Typography>

                            { signatures.map((item, index) => {
                                if (item.fieldType === 'signature') {
                                    return <Box key={`signature_${item.key}`} >
                                        <Signature groupID={props.functionData.groupKey} studentID={props.functionData.studentID} functionID={props.functionID} model={item} onSignatureSigned={onSignatureSigned} {...props} />
                                    </Box>
                                } else {
                                    return <Box key={`signature_${item.key}`}>
                                        <DiscontinuationRationale {...props} id={`drat_item_${item.key}`} key={`drat_item_${item.key}`} updateRequest={updateRequest} reloadFunctionData={props.reloadFunctionData} dratModel={item} userProfile={props.userProfile} onRemoveClicked={(dratKey) => { onRemoveDRATClicked(dratKey) }}></DiscontinuationRationale>
                                    </Box>
                                }
                            })
                            }
                        </>
                    }
                    {
                        isEditing && editSignatures && editSignatures.length > 0 &&
                        <>
                            <Typography component="h1" variant="h4" sx={{ mt: 5 }}>
                                Signatures
                            </Typography>
                            {editSignatures.map((field, index) => {
                                if (field.fieldType === 'signature') {
                                    return <Box key={`edit_signature_${field.key}`}>
                                        <SignatureField id={field.key}
                                            title={field.value ?? ""}
                                            editMode={false}
                                            annual={field.annual}
                                            type={field.type} 
                                            email={field.email} 
                                            onStateChanged={(title, type, email, annual) => onSignatureEdit(title, type, email, annual, field.key)}
                                            onRemoveClicked={() => onRemoveSignature(field.key)} />
                                    </Box>
                                } else {
                                    return <Box key={`edit_signature_drat_${field.key}`}>
                                        <DiscontinuationRationale {...props} id={`drat_item_${field.key}`} key={`drat_item_${field.key}`} updateRequest={updateRequest} reloadFunctionData={props.reloadFunctionData} dratModel={field} userProfile={props.userProfile} onRemoveClicked={(dratKey) => { onRemoveDRATClicked(dratKey) }}></DiscontinuationRationale>
                                    </Box>
                                }

                            })
                            }
                        </>
                    }
                </Box>

                {props.editMode && !loading &&
                    <AddProgramFieldPlus onClick={() => setShowingAddFieldDialog(true)} />
                }

            </form> }

            {showingAddFieldDialog && <AddProgramFieldDialog open={showingAddFieldDialog} key="add_item_dialog" onSelect={(type) => onAddItem(type)} />}

        </Box>
    )
}