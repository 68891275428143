import * as React from 'react';
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from "react-router-dom";
import MaterialLink from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { signIn, auth } from '../components/firebase/firebase';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Login } from '@mui/icons-material';
import { FirebaseError } from 'firebase/app';
import { FirebaseErrors } from '../components/firebase/firebase_errors';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <MaterialLink color="inherit" href="https://behaviorflow.org/">
        BehaviorFlow
      </MaterialLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [errorString, setErrorString] = useState("")
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // show loading screen
      return;
    }
    if (user) navigate("/dashboard/home");
  }, [user, loading]);


  async function login(email: string, password: string) {
    try {
      await signIn(email, password)
      setErrorString("")
    } catch(err) {
      let error: FirebaseError|undefined = err as FirebaseError
      if(error) {
        let errorString = FirebaseErrors[error.code]
        console.log("-  " + error.code + " ; message " + errorString)
        if(!errorString) {
          errorString = "An error occurred. Please try again."
        }
        setErrorString(errorString)
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {!loading && !user &&
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#268DA5' }}>
              <img src={process.env.REACT_APP_PUBLIC_URL + (process.env.REACT_APP_IS_STAGING ? '/logo256_staging.png' : '/logo256.png')} height="32px" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
      
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => login(email, password)}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>

              <Typography variant="body2" color="error" align="center">{errorString}</Typography>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}

            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      }
    </ThemeProvider>
  );
}
