import { httpsCallable } from "firebase/functions";
import { functions } from "../../components/firebase/firebase"
import { fetchGroupDescriptors } from "../../api/my_groups";
import { printObject } from "../../utils/print_object";
import dayjs from "dayjs";
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export const createReport = function (data) {
    return httpsCallable(functions, 'report-create_report')(data)
}

export const saveReportSettings = function (data) {
    return httpsCallable(functions, 'report-save_report')(data)
}

export const fetchAllReports = function () {
    return fetchReports().then((result) => {
        let recent = []
        let saved = []
        if(result?.data) {
            recent = result.data.recent ?? {}

            let sortedRecent = Object.values(recent).sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
            sortedRecent.forEach((r, idx) => {
                r.title = `Report ${idx + 1}`
                
                let date = dayjs(r.timestamp)
                r.timeString = `${dayjs(date).format('MM/DD/YYYY')} - ${date.fromNow()}`
            })
            recent = sortedRecent

            saved = result.data.saved ?? {}
            for(let key in saved) {
                saved[key].id = key
                saved[key].title = saved[key].reportName
                saved[key].lastUsedAt = saved[key].lastUsedAt ?? 0
            }
    
            saved = Object.values(saved).sort((a, b) => {
                return a.lastUsedAt < b.lastUsedAt ? 1 : -1
            })
    
            saved.forEach((rep) => {
                console.log(`${rep.title} ${rep.lastUsedAt}`)
            })
    
        }
        
        return {
            saved: saved,
            recent: recent
        }
    })
}

export const fetchReports = function () {
    return httpsCallable(functions, 'report-fetch_reports')()
}

export function fetchStudentFunctions(students) {
    let obj = {
        data: [
            {
                studentID: 's_1',
                groupID: 'sub_3',
                displayName: 'Student 1',
                functions: [
                    {
                        id: "f_1",
                        title: "Program 1",
                        type: 12
                    },
                    {
                        id: "f_2",
                        title: "Program 2",
                        type: 12
                    },
                    {
                        id: "f_3",
                        title: "Program 3",
                        type: 12
                    },
                    {
                        id: "f_4",
                        title: "Program 4",
                        type: 12
                    }
                ]
            },
            {
                studentID: 's_2',
                groupID: 'sub_3',
                displayName: 'Student 2',
                functions: [
                    {
                        id: "f_1",
                        title: "Program X",
                        type: 12
                    },
                    {
                        id: "f_2",
                        title: "Program Y",
                        type: 12
                    }
                ]
            },
        ]
    }

    return Promise.resolve(obj)
}

export function fetchGroups(parent) {
    return fetchGroupDescriptors({ parentGroupID: parent }).then((response) => {
        return parseTreeGroups(response.data, parent)
    });
}

export function fetchExpandGroups(groupIDs) {
    let promises = []
    groupIDs.forEach((id) => {
        let promise = fetchGroupDescriptors({ parentGroupID: id}).then((response) => {
            let parseResult = parseTreeGroups(response.data, id)
            return { groups: parseResult.data ?? [], parent: id }
        })
        promises.push(promise)
    })

    return Promise.all(promises)
}

export function mergeRootAndExpandedGroups(expandedGroups, rootGroups) {
    for(let idx in expandedGroups) {
        let expanded = expandedGroups[idx]
        let groups = expanded.groups
        let parentID = expanded.parent
        let parentGroup = findGroupWithID(rootGroups, parentID)
        
        if(!parentGroup) {
            for(let idx in expandedGroups) {
                let expanded = expandedGroups[idx]
                let groups = expanded.groups
                parentGroup = findGroupWithID(groups, parentID)
                if(parentGroup) {
                    break
                }
            }
        }

        if(parentGroup) {
            let subgroups = groups.map((gr) => gr.title)
            if(!parentGroup.subgroups) {
                parentGroup.subgroups = groups
            }
            
        } else {
            console.log('parent group with id ' + parentID + 'not found')
        }
    }
}

function findGroupWithID(groups, id) {
    if(!groups) {
        return null
    }

    for(let idx in groups) {
        let group = groups[idx]
        if(group.id == id) {
            return group
        } else {
            let subgroup = findGroupWithID(group.subgroups, id)
            if(subgroup) {
                return subgroup
            }
        }
    }

    return null
}

export function parseTreeGroups(groups, parentID) {
    let groupsArr = []

    for (let key in groups) {
        let group = groups[key]
        if(group.status && group.status != 1) {
            continue
        }

        let groupDict = {
            id: key,
            type: 'group',
            title: group.name,
            parentID: parentID
        }

        let students = undefined
        if (group.users) {
            for (let userKey in group.users) {
                let user = group.users[userKey]
                if (user.role == 7) {
                    let dict = {
                        id: `${userKey}~${key}`,
                        studentID: userKey,
                        title: user.name + " " + (user.lastName ?? ""),
                        type: 'student',
                        groupID: key
                    }
                    if(!students) {
                        students = []
                    }
                    students.push(dict)
                }
            }
        }
        if(students){
            groupDict.subgroups = students.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1)
        }
        
        groupsArr.push(groupDict)
    }
    return {
        data: groupsArr
    }
}

export function fetchStudentPrograms(studentsArray, groupID) {
    let items = {
        "s_1": {
            data: [
                {
                    id: "program_1",
                    title: "Program 1 student 1"
                },
                {
                    id: "program_2",
                    title: "Program 2 student 1"
                }
            ]
        },
        "s_2": {
            data: [
                {
                    id: "program_3",
                    title: "Program 1 student 2"
                },
                {
                    id: "program_4",
                    title: "Program 2 student 2"
                },
                {
                    id: "program_5",
                    title: "Program 3 student 2"
                }
            ]
        }
    }

    let data = {}
    studentsArray.forEach((id) => {
        data[id] = items[id]
    })

    return Promise.resolve(data)
}