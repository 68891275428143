import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function SimpleDialog(props) {
    const { onCompleted } = props;

    return (
        <Dialog open={props.open} onClose={() => onCompleted(false)} >
            <DialogTitle>
                { props.title ?? "" }
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
            { props.text ?? "" }
            </DialogContentText>
            </DialogContent>            
            <DialogActions sx={{mt: 0}}>
                <Button id="button_disagree" onClick={() => onCompleted(false)}>{props.noTitle ?? "No"}</Button>
                <Button id="button_agree" onClick={() => onCompleted(true)}>{props.yesTitle ?? "Yes"}</Button>                
            </DialogActions>
        </Dialog>
    );
}