import React, { useEffect, useState } from 'react';
import data from '../../model.json';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../assets/css/edit_program.css'
import { ProgramEntryFieldType, ProgramTextEntry, TextualProgram } from '../program_library';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import AddSignaturePlus from './add_signature_plus';
import { generateRandomKey } from '../../utils/random_key';
import SignatureField from './signature_field';
import { showError } from '../../components/toasts';

interface AddProgramProps {
    onAddProgram: (program: TextualProgram) => void
    submitting: boolean
}

export default function AddProgramForm({ onAddProgram, submitting }: AddProgramProps) {

    const [items, setItems] = useState<{ [key: string]: ProgramTextEntry } | null>(null)
    const [signatures, setSignatures] = useState<{ [key: string]: ProgramTextEntry }>({})

    useEffect(() => {
        if (!items) {
            let allItems: { [key: string]: ProgramTextEntry } = {}
            let index = 0
            for (let idx in data) {
                let dataItem = data[idx]
                let key = keyFromTitle(dataItem.Title)
                let type = dataItem.Type.toLowerCase()
                let dataType: ProgramEntryFieldType
                if (type == "single") {
                    dataType = ProgramEntryFieldType.TextField
                } else if (type == "multi") {
                    dataType = ProgramEntryFieldType.Multiline
                } else {
                    continue;
                }
                let item: ProgramTextEntry = { fieldType: dataType, index: index, label: dataItem.Title, value: "" }
                allItems[key] = item
                index += 1
            }
            setItems(allItems)
        }

    }, [items])

    function keyFromTitle(text: string): string {
        return text.toLowerCase().trim().replaceAll(' ', '_')
    }

    function onSubmitClick() {
        if (!items) {
            return
        }

        let nameField = items["program_name"]

        if (!nameField) {
            return;
        }

        let name = nameField.value.trim()

        if (name.length < 3) {
            showError("Program name must be at least 3 characters long.")
            return
        }

        onAddProgram({ textItems: items, title: name, signatures: signatures, discontinuationItems: [] })
    }

    function onAddSignature() {
        let allSignatures = structuredClone(signatures)
        
        if (!allSignatures) {
            return;
        }

        const randomKey = generateRandomKey(20)
        const index = Object.keys(allSignatures).length

        let signature: ProgramTextEntry = {
            fieldType: ProgramEntryFieldType.Signature,
            index: index,
            label: "Signature",
            value: "",
            type: 'regular'
        }

        allSignatures[randomKey] = signature
        setSignatures(allSignatures)
    }

    function onSignatureValueChange(key: string, text: string, type: string, email: string, annual: boolean) {
        let allSignatures = signatures
        let signature = allSignatures?.[key]
        if(signature) {
            signature.value = text
            signature.type = type
            signature.email = email
            signature.annual = annual
            setSignatures(allSignatures)
        }
    }

    function removeSignature(key: string) {
        let allSignatures = structuredClone(signatures)
        if(allSignatures[key]) {
            delete allSignatures[key]
            setSignatures(allSignatures)
        }
    }

    function update(key: string, value: string) {
        let editedItems = items
        if (editedItems) {
            let item: ProgramTextEntry = structuredClone(editedItems[key])
            item.value = value
            setItems({
                ...items,
                [key]: item
            })
        }
    }

    function signatureType(sig: any) {
        if(sig.isParentSignature) {
            return 'parent'
        } else if(sig.type) {
            return sig.type
        }

        return 'regular'
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
                Create Program
            </Typography>

            <form className='form'>
                {items &&
                    Object.entries(items).map((field, index) => {
                        return (
                            <div className="form-item" key={field[1].label}>
                                {
                                    field[1].fieldType == ProgramEntryFieldType.TextField &&
                                    <TextField margin="normal"
                                        required
                                        fullWidth
                                        id={field[0]}
                                        name={field[0]}
                                        key={field[0]}
                                        value={field[1].value}
                                        onChange={(event) => update(field[0], event.target.value)}
                                        label={field[1].label} variant="standard"
                                        className='form-textfield' />
                                }

                                {field[1].fieldType == ProgramEntryFieldType.Multiline &&
                                    <TextField
                                        className="form-textarea"
                                        onChange={(event) => update(field[0], event.target.value)}
                                        value={field[1].value} key={field[0]}
                                        fullWidth
                                        id="outlined-basic"
                                        rows={7}
                                        multiline
                                        label={field[1].label}
                                        variant="filled" />
                                }
                            </div>
                        );
                    })
                }

                {signatures &&
                    Object.entries(signatures).map((field, index) => {
                        return (
                            <div className="form-item" key={"signature_container_" + field[0]}>
                                {field[1].fieldType == ProgramEntryFieldType.Signature &&
                                    <SignatureField
                                        id={field[0]}
                                        key={field[0]}
                                        title={field[1].value}
                                        editMode={true}
                                        type={signatureType(field[1])}

                                        onRemoveClicked={() => removeSignature(field[0])}
                                        onStateChanged={(text, isChecked, email, annual) => onSignatureValueChange(field[0], text, isChecked, email, annual)} />
                                }
                            </div>
                        );
                    })
                }

                {signatures && !submitting &&
                    <AddSignaturePlus key="add_signature_plus" onClick={() => onAddSignature()} />
                }

            </form>
            {items && !submitting &&
                <Button className="mdl-button mdl-js-button mdl-button--fab" variant="contained" onClick={() => onSubmitClick()} >Add Program</Button>
            }

            {submitting &&
                <CircularProgress className="spinner" />
            }

            <br />
        </Box>

    );
}

