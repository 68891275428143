import React, { useEffect, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, IconButton, InputLabel, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Label } from "@mui/icons-material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropzoneArea } from "material-ui-dropzone";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { app } from '../../components/firebase/firebase';
import { useNavigate } from 'react-router-dom';
import 'firebase/storage';
import { generateRandomKey } from "../../utils/random_key";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { jsonCopyObject } from "../../utils/json_copy";
import { parseUploadedPrograms, updateUploadedPrograms, updateUploadedStudentInstances } from "../../api/api_import_data";
import { TreeItem, TreeView } from "@mui/lab";
import { fetchEducationTemplateDescriptors } from "../../api/program_library";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { showError, showSuccess } from "../../components/toasts";
import { Link } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import { EditProgramForm } from "../program/edit_program";
import { EditProgramMode } from "../program/edit_program";
import { editingProgramAtom, isCustomizingProgramForStudentAtom } from '../../recoil/atom/program_library_atom';
import { isEditingFunctionAtom } from '../../recoil/atom/function_atom';
import { useRecoilState } from "recoil";
import { importedTemplateProgramsState } from "../../recoil/atom/import-state";
import { printObject } from "../../utils/print_object";
import { sideMenuWidth } from "../../components/sidemenu/side_menu";

export default function ImportSuccess(props) {
    // tree
    const [isSubmitting, setSubmitting] = useState(false)

    const [selectedProgramError, setSelectedProgramError] = useState(null)
    const [isCustomizingProgram, setCustomizingProgram] = useRecoilState(isCustomizingProgramForStudentAtom)
    const [selectedProgram, setSelectedProgram] = useRecoilState(editingProgramAtom)
    const [_, setIsEditingProgram] = useRecoilState(isEditingFunctionAtom)

    const [programs, setPrograms] = useRecoilState(importedTemplateProgramsState)
    // const [programs, setPrograms] = useState(mock2)
    const [localProgramsState, setLocalProgramsState] = useState(null)
    const [selectedProgramIndex, setSelectedProgramIndex] = useState(null)

    const navigate = useNavigate();

    const handleListItemClick = (event, index) => {
        setIsEditingProgram(true)
        setCustomizingProgram(false)
        selectIndex(index)
    };

    useEffect(() => {
        setSelectedProgram(null)
        setCustomizingProgram(false)
        setIsEditingProgram(true)
    }, [])

    useEffect(() => {
        if (programs && !localProgramsState) {
            setLocalProgramsState(jsonCopyObject(programs))
        }
    }, [programs])

    useEffect(() => {
        if ((!selectedProgramIndex && selectedProgramIndex != 0) && localProgramsState) {
            selectIndex(0)
        }

        if(localProgramsState) {
            printObject(localProgramsState, "Local state")
        }
    }, [localProgramsState])

    function onProgramChange(program) {
        if (!localProgramsState || (!selectedProgramIndex && selectedProgramIndex != 0)) {
            return
        }
        let state = jsonCopyObject(localProgramsState)
        state.results[selectedProgramIndex].changed = true
        state.results[selectedProgramIndex].value.program = jsonCopyObject(program)
        setLocalProgramsState(state)
    }

    function selectIndex(index) {
        setSelectedProgramIndex(index)
        if (programs.results?.[index].error) {
            setSelectedProgram(null)
            setSelectedProgramError(programs.results[index].error)
        } else {
            setSelectedProgram(localProgramsState.results[index]?.value)
            setSelectedProgramError(null)
        }
    }

    function onDone() {
        let folderID = localProgramsState?.folderID
        let rootFolderID = localProgramsState?.rootFolderID

        let groupID = localProgramsState?.groupID
        let studentID = localProgramsState?.studentID

        let state = localProgramsState.results ?? []
        let changedState = state.filter((item) => item.changed)

        if (changedState == 0) {
            showCompletedAndNavigateAway()
            return  
        } 

        if(folderID && rootFolderID) {
            // update library
            setSubmitting(true)
            updateUploadedPrograms({ programs: changedState, folderID, rootFolderID }).then((res) => {
                handleResponse(res.data)
            })
        } else if(groupID && studentID) {
            // update instances
            setSubmitting(true)
            updateUploadedStudentInstances({ programs: changedState, groupID, studentID }).then((res) => {
                handleResponse(res.data)
            })
        } else {
            showError("Error updating data. Please try again later.")
            return
        }
    }

    function handleResponse(res) {
        setSubmitting(false)
        if(res.error) {
            showError(res.error)
        } else if(res.results) {
            for(let index in res.results) {
                let result = res.results[index]
                
                if(result.error && localProgramsState.results[index]) {
                    showError(`Error updating ${localProgramsState.results[index].title}:\n${result.error}`)
                    return
                }
            }

            showCompletedAndNavigateAway()
        } else {
            showCompletedAndNavigateAway()
        }
    }

    function showCompletedAndNavigateAway() {
        showSuccess('Importing completed')
        navigate('/dashboard/import')
    }

    return <Box>
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: `${sideMenuWidth}px`,
                zIndex: 1,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1
            }}>
            <Box flexGrow={1} sx={{ marginRight: '32px', display: 'flex', alignItems: 'center', justifyContent: 'end' }} >
                {isSubmitting ? <CircularProgress /> : <Button sx={{ mt: '12px', mb: '12px' }} variant="contained" onClick={() => onDone()}>Done</Button>}
            </Box>
        </Box>
        <Box sx={{ height: 'calc(100vh - 110px)', position: 'fixed', top: '55px', left: `${sideMenuWidth}px` }}>
            <List sx={{ width: '300px', mt: '55px', height: '100%', overflow: 'auto' }}>
                {
                    programs?.results?.map((program, index) => {
                        return <ListItemButton
                            key={program.key}
                            selected={selectedProgramIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}>
                            <ListItemText sx={{ display: 'inline' }} primary={program.title} />
                            {program.error ? (<ListItemIcon sx={{ ml: 1 }}> <ErrorIcon color="error" /> </ListItemIcon>) : <></>}
                        </ListItemButton>
                    })
                }
            </List>
        </Box>

        <Box sx={{ ml: '316px', mr: '16px', mt: '84px', height: '100%' }}>
            {selectedProgram && <EditProgramForm onChange={onProgramChange} isEmbedded={true} mode={EditProgramMode.Edit} loading={false} item={selectedProgram} />}
            {selectedProgramError && <Typography sx={{ width: '100%' }} component="h4" variant="body" align="center" >An error occurred while trying to parse the selected program: {selectedProgramError}</Typography>}
        </Box>
    </Box>
}