import * as React from 'react';
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function InputDialog(props) {
    const { onCompleted, inputText } = props;
    const [text, setText] = useState("");

    useEffect(() => {
        setText(inputText)
    }, [])

    function onClickCompleted() {
        onCompleted(text)
    }

    return (
        <Dialog open={props.open} onClose={() => onCompleted(false)} >
            <DialogTitle>
                {props.title ?? ""}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.lines && props.lines.map((line, index) => {
                        return <>
                        { index > 0 && <br />}
                        {line}
                        </>
                    })}
                    {props.text}
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="input"
                    label={props.inputLabel}
                    type={props.inputType ?? ""}
                    onChange={(e) => { setText(e.target.value) } }
                    value={text}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions sx={{ mt: 0 }}>
                <Button id="button_disagree" onClick={() => onCompleted(null)}>{props.noTitle ?? "No"}</Button>
                <Button id="button_agree" onClick={() => onClickCompleted()}>{props.yesTitle ?? "Yes"}</Button>
            </DialogActions>
        </Dialog>
    );
}