import * as React from 'react';
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from "react-router-dom";
import MaterialLink from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { auth, logout, signIn } from '../../components/firebase/firebase';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Login } from '@mui/icons-material';
import { FirebaseError } from 'firebase/app';
import { FirebaseErrors } from '../../components/firebase/firebase_errors';
import { getEmailToken, registerSignatureAccount } from './create-signature-account.api';
import { showError, showSuccess } from '../../components/toasts';
import { printObject } from '../../utils/print_object';
import { CircularProgress } from '@mui/material';

const theme = createTheme();

export default function CreateSignatureAccount() {
    const [token, setToken] = useState(null);
    const [accountCreated, setAccountCreated] = useState(false);
    const [email, setEmail] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errorString, setErrorString] = useState("")
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [isSubmitting, setSubmitting] = useState(false)
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            // show loading screen
            return;
        }
        if (user && accountCreated)  {
            showSuccess("Account created")
            navigate("/dashboard/home");
        }
    }, [user, loading]);
    
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token")
        console.log('token ' + token)
        if(!token) {
            // missing token, redirect
            navigate('/')
        } else {
            setToken(token)
            getEmailToken(token).then((res) => {
                if(res.error) {
                    showError(res.error)
                    navigate('/')
                } else if(res.email) {
                    setEmail(res.email)
                } else {
                    showError('Invalid invitation token. Please contact support for further assistance.')
                    navigate('/')
                }
            })
        }
    }, []);

    async function register() {
        try {
            setErrorString("")
            if(validateForm()) {
                setSubmitting(true)
                let result = await registerSignatureAccount(email, firstName.trim(), lastName.trim(), password, token)
                if(result.data.error) {
                    setErrorString(result.data.error)
                    setSubmitting(false)
                } else {
                    await logout()
                    setAccountCreated(true)
                    await signIn(email, password)
                    setSubmitting(false)
                }
            }
            
        } catch (err) {
            setSubmitting(false)
            if (err) {
                let errorString = FirebaseErrors[err.code]
                if (!errorString) {
                    errorString = "An error occurred. Please try again."
                }
                setErrorString(errorString)
            }
        }
    }

    function validateForm() {
        let fname = firstName.trim()
        let hasErrors = false
        if(fname.length < 2) {
            setFirstNameError("First Name must be at least 2 characters long")
            hasErrors = true
        } else {
            setFirstNameError(null)
        }

        let lname = lastName.trim()

        if(lname.length < 1) {
            setLastNameError("Last Name must be at least 1 character long")
            hasErrors = true
        } else {
            setLastNameError(null)
        }

        if(password.length < 6) {
            setPasswordError("Password must be at least 6 characters long")
            hasErrors = true
        } else if(password != confirmPassword) {
            setPasswordError("Passwords must match")
            hasErrors = true
        } else {
            setPasswordError(null)
        }
        
        return !hasErrors
    }

    return (
        <ThemeProvider theme={theme}>
            { email &&
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#268DA5' }}>
                            <img src={process.env.REACT_APP_PUBLIC_URL + (process.env.REACT_APP_IS_STAGING ? '/logo256_staging.png' : '/logo256.png')} height="32px" />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Create Signature Account
                        </Typography>
                        <Box sx={{ mt: 1 }}>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                value={email}
                                label="Email Address"
                                name="email"
                                disabled={true}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    error={ firstNameError }
                                    helperText={ firstNameError }
                                    id="first-name"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="last-name"
                                    error={lastNameError}
                                    helperText={ lastNameError }
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Box>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                error={passwordError}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                error={passwordError}
                                helperText={passwordError}
                                name="confirm-password"
                                label="Confirm Password"
                                type="password"
                                id="confirm-password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="current-password"
                            />

                            { !isSubmitting ? <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={() => register()}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Create Account
                            </Button> : <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress />
                            </Box>
                            }
                            <Typography variant="body2" color="error" align="center">{errorString}</Typography>
                        </Box>
                    </Box>
                    {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                </Container>
            }
        </ThemeProvider>
    );
}
