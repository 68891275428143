import React, { useEffect, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Card, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { Label } from "@mui/icons-material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropzoneArea } from "material-ui-dropzone";
import { app } from '../../components/firebase/firebase';
import 'firebase/storage';
import { generateRandomKey } from "../../utils/random_key";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { jsonCopyObject } from "../../utils/json_copy";
import { importStudentInstances, parseUploadedPrograms } from "../../api/api_import_data";
import { TreeItem, TreeView } from "@mui/lab";
import { fetchEducationTemplateDescriptors } from "../../api/program_library";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { showError, showSuccess } from "../../components/toasts";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRecoilState } from "recoil";
import { importedTemplateProgramsState } from "../../recoil/atom/import-state";
import { useNavigate } from 'react-router-dom';
import { sideMenuWidth } from "../../components/sidemenu/side_menu";
import { GroupRoleEnum, addUserToGroup, checkIfUserExists, copyStudentWithoutProgramming, createProgramLibraryRootFolder, fetchUserAdminGroup, fetchUserAdminGroups, fetchUserAdminRootGroups, generateGroupPathValues, getUserAdminRootGroups, moveStudent, removeUserFromGroup } from "../../api/administration-api";
import { DataGrid } from "@mui/x-data-grid";
import { darken, lighten, styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import SimpleDialog from "../dialogs/simple_dialog";
import CopyWithoutProgrammingDialog from "./copy-without-programming-dialog";
import LoadingInformationDialog from "../../pages/reports/create-report-alert";
import MoveStudentDialog from "./move-student-dialog";
import { UserTypeEnum } from "../../api/user";

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Filled': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--PartiallyFilled': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Rejected': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));


export default function AdministrationHome(props) {
  // state
  const [selectedGroupKey, setSelectedGroupKey] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)

  // delete student
  const [deleteStudentName, setDeleteStudentName] = useState(null)
  const [isDeletingStudent, setDeletingStudent] = useState(false)

  // add user
  const [addUserEmail, setAddUserEmail] = useState(null)
  const [addUserRole, setAddUserRole] = useState(null)
  const [addUserEmailValid, setAddUserEmailValid] = useState(false)
  const [isAddingUser, setIsAddingUser] = useState(false)
  const [isShowingAddUserConfirmationDialog, setShowingAddUserConfirmationDialog] = useState(false)
  const [addUserDialogTitle, setAddUserDialogTitle] = useState('')
  const [addUserDialogMessage, setAddUserDialogMessage] = useState('')

  // move 
  const [showMoveStudentDialog, setShowMoveStudentDialog] = useState(false) // tree dialog
  const [showMoveStudentConfirmationDialog, setShowMoveStudentConfirmationDialog] = useState(false) // yes/no dialog
  const [isMovingStudent, setMovingStudent] = useState(false)  // action in progress
  const [isMovingStudentDone, setMovingStudentDone] = useState(false) // if done, the loading dialog shows all checkmarks
  const [moveUserTerm, setMoveUserTerm] = useState(null)

  // copy without programming
  const [showCopyWithoutProgrammingDialog, setShowCopyWithoutProgrammingDialog] = useState(false)
  const [isCopyingWithoutProgramming, setCopyingWithoutProgramming] = useState(false)
  const [isCopyingDone, setCopyingDone] = useState(false)

  // Data grid
  const [dataGridRows, setDataGridRows] = useState([])

  // tree
  const [rootGroups, setRootGroups] = useState(null)

  const [treeUpdated, setTreeUpdated] = useState(1)
  const [moreAnchor, setMoreAnchor] = useState(null)

  const [groupTree, setGroupTree] = useState(null)
  const [allGroups, setAllGroups] = useState({})
  const [allStudents, setAllStudents] = useState({})
  const [isUploading, setIsUploading] = useState(false)

  const copyWithoutProgrammingDialogModel = [
    { text: 'Checking permissions', duration: 2.0 },
    { text: 'Preparing groups', duration: 2.0 },
    { text: 'Copying student', duration: 3000 }
  ]

  const moveStudentDialogModel = [
    { text: 'Checking permissions', duration: 2.5 },
    { text: 'Preparing groups', duration: 1.0 },
    { text: 'Copying student information', duration: 3.5 },
    { text: 'Copying programs and data', duration: 3000 }
  ]

  const addUserRoleModel = GroupRoleEnum.all().reduce((res, next) => {
    let text = GroupRoleEnum.titleFor(next)
    res[text] = { text: GroupRoleEnum.titleFor(next), code: next }
    return res
  }, {})

  let columns = [
    {
      field: 'fullname',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'role',
      headerName: 'Role',
      valueGetter: (params) => params.row.roleTitle,
      flex: 1
    },
    {
      headerName: 'Actions',
      headerAlign: 'right',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.id === selectedStudent?.id && isDeletingStudent) {
          return <CircularProgress />
        }

        let selectedGroup = (allGroups ?? {})[selectedGroupKey]

        const onDelete = (e) => {
          e.stopPropagation();
          // showSuccess('row ' + params.id + ' in group ' + selectedGroupKey)
          (selectedGroup?.students ?? []).forEach((student) => {
            if (student.id === params.id) {
              setSelectedStudent(student)
              setDeleteStudentName(student.fullname)
            }
          })

        };

        const onMore = (e) => {
          e.stopPropagation();
          try {
            setMoreAnchor(e.target)
          } catch (err) { }

          (selectedGroup?.students ?? []).forEach((student) => {
            if (student.id === params.id) {
              setSelectedStudent(student)
              setMoveUserTerm(student.role === 7 ? 'student' : 'user')
            }
          })

        };

        const isInvitationCell = (selectedGroup?.invitations ?? []).filter((inv) => inv.id === params.id).length > 0
        if (isInvitationCell) {
          return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mr: 1 }}>
            <Typography variant='body1'>Invitation sent</Typography>
          </Box>
        } else {
          return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mr: 1 }}>
            <IconButton onClick={onDelete}> <RemoveCircleIcon sx={{ color: 'error.main' }} /> </IconButton>
            <Divider flexItem orientation="vertical" sx={{ ml: 2, mr: 1 }} />
            <IconButton onClick={(e) => onMore(e)}> <MoreHorizIcon /> </IconButton>
          </Box>
        }
      }
    },
  ];

  useEffect(() => {
    fetchUserAdminRootGroups().then((result) => {
      setRootGroups(result.data)
    })
  }, []);

  useEffect(() => {
    addToAllGroups(rootGroups)
    setGroupTree(rootGroups)

    setTreeUpdated(treeUpdated + 1)
  }, [rootGroups])

  useEffect(() => {
    updateDataGridRows()
  }, [selectedGroupKey])

  function updateDataGridRows() {
    let selectedGroup = (allGroups ?? {})[selectedGroupKey]
    let students = selectedGroup?.students ?? []
    let invitations = (selectedGroup?.invitations ?? []).map((invitation) => {
      return {
        id: invitation.id ?? generateRandomKey(),
        fullname: invitation.email,
        role: invitation.role,
        roleTitle: GroupRoleEnum.titleFor(invitation.role),
        type: 'invitation'
      }
    })

    let combined = invitations.concat(students)
    setDataGridRows(combined)
  }

  function renderTree(isRoot, parent) {
    if (!groupTree) {
      return <CircularProgress />
    } else if (isRoot) {
      return groupTree.map((sub) => {
        return renderTree(false, sub)
      })
    } else if (!parent) {
      return <CircularProgress />
    }

    return <TreeItem nodeId={parent.id}
      key={parent.id}
      disabled={isUploading}
      collapseIcon={parent.isEmpty ? <></> : <ExpandMoreIcon />}
      expandIcon={parent.isEmpty ? <></> : <ChevronRightIcon />}
      label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={event => event.stopPropagation()}>
        <Typography >{parent.title}</Typography>
      </Box>
      }>
      {
        parent?.subgroups && parent?.subgroups.map((sub) => {
          return renderTree(false, sub)
        })
      }
      {
        !parent.isEmpty && parent?.subgroups == null && <CircularProgress size={32} />
      }
    </TreeItem>

  }

  async function generatePaths() {
    // TODO: Run on production to generate prod group paths
    // let result = await generateGroupPathValues()

    // if(result.data.message) {
    //     showSuccess(result.data.message)
    // }
    // console.log('generated')
  }

  function addToAllGroups(arr) {
    let all = allGroups ?? {}
    let alls = allStudents ?? {}
    for (let key in arr) {
      let item = arr[key]
      if (item.type === 'group') {
        all[item.id] = item
      } else if (item.type === 'student') {
        alls[item.id] = item
      }

      let subgroups = item.subgroups ?? []
      subgroups.forEach((subg) => {
        if (subg.type === 'group') {
          all[subg.id] = subg
        } else if (subg.type === 'student') {
          alls[subg.id] = subg
        }
      })
    }

    setAllGroups(all)
    setAllStudents(alls)
  }

  function findGroup(id, tree) {
    return allGroups[id]
  }

  function fetchSubgroups(parentID) {
    fetchUserAdminGroups(parentID).then((result) => {
      addToAllGroups(result.data)

      let tree = groupTree
      let item = findGroup(parentID, tree)

      if (item) {
        item.subgroups = result.data
        if (result.data.length == 0) {
          item.isEmpty = true
        }
      }

      setTreeUpdated(treeUpdated + 1)
      setGroupTree(tree)
    })
  }

  function handleCloseMore() {
    setMoreAnchor(null)
  }

  function onMoveStudent() {
    setShowMoveStudentDialog(true)
    setMoreAnchor(null)
  }

  function onCopyWithoutProgramming() {
    setShowCopyWithoutProgrammingDialog(true)
    setMoreAnchor(null)
  }

  function updateGroup(id, group) {

    let all = allGroups
    all[id] = group

    let parent = all[group.parentID]

    // replace subgroup in parent
    if (parent) {
      let subgroups = parent?.subgroups ?? []
      for (let key in subgroups) {
        let existing = subgroups[key]
        if (existing.id === id) {
          group.isEmpty = existing.isEmpty
          parent.subgroups[key] = group
          break
        }
      }
    }
    setAllGroups(all)
    setTreeUpdated(treeUpdated + 1)
  }

  async function onMoveStudentCompleted(destinationGroupID) {
    setShowMoveStudentDialog(false) // hide tree dialog
    setMovingStudentDone(false)

    console.log(`move student from ${selectedGroupKey} to ${destinationGroupID}`)

    if (selectedGroupKey === destinationGroupID) {
      showError("Student is already in that group - please select a different one.")
      return
    }
    if (destinationGroupID && destinationGroupID.trim().length > 0 && selectedStudent?.id && selectedGroupKey) {

      setMovingStudent(true)
      let res = await moveStudent(selectedStudent.id, selectedGroupKey, destinationGroupID).catch((err) => {
        setMovingStudent(false)
        showError(err)
      })

      let message = res.data.message
      console.log(`message ${res.data.message} error ${res.data.error}`)
      if (message) {
        // success, reload destination group

        let existingSourceGroup = allGroups[selectedGroupKey]
        if (existingSourceGroup) {
          await fetchUserAdminGroup(selectedGroupKey, existingSourceGroup.parentID).then((updatedGroup) => {
            if (updatedGroup) {
              updateGroup(selectedGroupKey, updatedGroup)
            }

            return null
          })
        }

        let existingGroup = allGroups[destinationGroupID]
        if (existingGroup) {
          // we already fetched the group, we need to reload it so the user is visible
          await fetchUserAdminGroup(destinationGroupID, existingGroup.parentID).then((updatedGroup) => {
            if (updatedGroup) {
              updateGroup(destinationGroupID, updatedGroup)
            }

            return null
          })
        }

        updateDataGridRows()
        finishMovingStudent(message)
      } else if (res?.data?.error) {
        finishMovingStudent(null, res.data.error)
      } else {
        finishMovingStudent(null, 'Unknown error')
      }
    }

  }

  async function onCopyWithoutProgrammingCompleted(destinationGroupID) {
    setShowCopyWithoutProgrammingDialog(false)
    setCopyingDone(false)

    console.log(`${selectedStudent.id} ${selectedGroupKey} ${destinationGroupID}`)
    if (destinationGroupID && destinationGroupID.trim().length > 0 && selectedStudent?.id && selectedGroupKey) {
      setCopyingWithoutProgramming(true)
      let res = await copyStudentWithoutProgramming(selectedStudent.id, selectedGroupKey, destinationGroupID).catch((err) => {
        showError(err)
      })

      let message = res.data.message
      console.log(`message ${res.data.message} error ${res.data.error}`)
      if (message) {
        // success, reload destination group
        let existingGroup = allGroups[destinationGroupID]
        if (existingGroup) {
          // we already fetched the group, we need to reload it so the user is visible
          fetchUserAdminGroup(destinationGroupID, existingGroup.parentID).then((updatedGroup) => {
            if (updatedGroup) {
              updateGroup(destinationGroupID, updatedGroup)
            }
            finishCopying(message)
          })
        } else {
          finishCopying(message)
        }
      } else if (res.data.error) {
        finishCopying(null, res.data.error)
      }
    }

    setSelectedStudent(null)
  }

  function onRoleChange(newValue) {
    setAddUserRole(newValue)
    reloadAddUserPanelState(addUserEmail, newValue)
  }

  function onEmailChange(value) {
    setAddUserEmail(value)
    reloadAddUserPanelState(value, addUserRole)
  }

  function reloadAddUserPanelState(email, role) {
    let isStudentRoleSelected = (role?.code === GroupRoleEnum.student)
    if (isStudentRoleSelected) {
      // we don't apply the email validation for students - we allow usernames too
      setAddUserEmailValid(validateUsername(email))
    } else {
      setAddUserEmailValid(validateEmail(email))
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateUsername = (username) => {
    return (username ?? '').trim().length > 0
  };

  function finishDialogAction(message, error, delayedAction, immediateAction) {
    let delay = 500

    if (error) {
      delay = 0
    }

    window.setTimeout(() => {
      delayedAction()
    }, delay);

    immediateAction()

    if (message) {
      showSuccess(message)
    } else if (error) {
      showError(error)
    }
  }

  function finishMovingStudent(message, error) {
    finishDialogAction(message, error, () => { setMovingStudent(false) }, () => { setMovingStudentDone(true) })
  }

  function finishCopying(message, error) {
    finishDialogAction(message, error, () => { setCopyingWithoutProgramming(false) }, () => { setCopyingDone(true) })
  }

  async function onDeleteStudent(value) {

    let userGroupKey = selectedGroupKey

    setDeleteStudentName(null)

    if (value) {
      setDeletingStudent(true)
      let res = await removeUserFromGroup({ userID: selectedStudent.id, groupID: selectedGroupKey }).catch((e) => showError(e))

      if (res.data?.message) {
        let existingGroup = allGroups[userGroupKey]
        if (userGroupKey && existingGroup) {
          // we already fetched the group, we need to reload it so the user is visible
          let updatedGroup = await fetchUserAdminGroup(selectedGroupKey, existingGroup.parentID)

          if (updatedGroup) {
            updateGroup(userGroupKey, updatedGroup)
          }
        } else {
          setAddUserEmail('')
          setAddUserRole(null)
          updateDataGridRows()
        }

        showSuccess(res.data.message)
      } else if (res.data?.error) {
        showError(res.data.error)
      }

      setSelectedStudent(null)
      setDeletingStudent(false)
      updateDataGridRows()
    } else {
      setSelectedStudent(null)
      setDeletingStudent(false)
    }
  }

  async function onAddUser() {
    setIsAddingUser(true)
    let result = await checkIfUserExists({ email: addUserEmail.trim().toLowerCase() })

    setAddUserDialogTitle("Confirm please")
    setAddUserDialogMessage(`Are you sure you want to add user '${addUserEmail}' with role '${addUserRole?.text}' to ${(allGroups ?? {})[selectedGroupKey]?.title ?? 'this group'}?`)

    let isStudentRoleSelected = (addUserRole.code === GroupRoleEnum.student)

    let infoType = 'email address'
    if (isStudentRoleSelected && !validateEmail(addUserEmail)) {
      infoType = 'account'
    }

    if (!result.data?.error) {
      let title = null
      let message = null

      if (result.data?.exists) {
        let accountType = result.data.type

        // check if we're trying to add student account as a non-student and vice versa
        let isStudentAccount = (accountType === UserTypeEnum.student)

        if (isStudentAccount && !isStudentRoleSelected) {
          showError(`${addUserEmail} is associated with a student account and cannot be added as a ${addUserRole?.text} to this group.`)
        } else if (!isStudentAccount && isStudentRoleSelected) {
          showError(`${addUserEmail} is associated with a non-student account and cannot be added as a student to this group.`)
        } else {
          let name = null
          if (result.data.name) {
            name = `the user ${result.data.name}`
          } else {
            name = `an existing user account`
          }
          title = 'Add existing user'

          message = `'${addUserEmail}' ${infoType} is associated with ${name}. Are you sure you add them as '${addUserRole?.text}' to ${(allGroups ?? {})[selectedGroupKey]?.title ?? 'this group'}?`
        }
      } else {
        if(isStudentRoleSelected) {
          // we don't invite students by email to join the app - only regular users
          showError("Student not found")
        } else {
          title = 'Account not found'
          message = `We couldn't find an account associated with the email '${addUserEmail}'. Would you like to invite this person to join the app as a '${addUserRole?.text}' in ${(allGroups ?? {})[selectedGroupKey]?.title ?? 'your group'}?`
        }
      }

      if(title && message) {
        setAddUserDialogTitle(title)
        setAddUserDialogMessage(message)
        setShowingAddUserConfirmationDialog(true)
      }
    }

    setIsAddingUser(false)
  }

  function onAddUserConfirmed(add) {
    setShowingAddUserConfirmationDialog(false)
    if (add) {
      setIsAddingUser(true)
      addUserToGroup({ email: addUserEmail.trim().toLowerCase(), role: addUserRole.code, groupID: selectedGroupKey }).catch((e) => {
        showError(e)
      }).then((res) => {
        if (res.data.error) {
          showError(res.data.error)
        } else if (res.data.message) {
          showSuccess(res.data.message)
        }

        let existingGroup = allGroups[selectedGroupKey]
        if (selectedGroupKey && existingGroup) {
          // we already fetched the group, we need to reload it so the user is visible
          return fetchUserAdminGroup(selectedGroupKey, existingGroup.parentID).then((updatedGroup) => {
            if (updatedGroup) {
              updateGroup(selectedGroupKey, updatedGroup)
            }

            setAddUserEmail('')
            setAddUserRole(null)
            updateDataGridRows()

            return null
          })
        } else {
          setAddUserEmail('')
          setAddUserRole(null)
          updateDataGridRows()
        }

      }).finally(() => {
        setIsAddingUser(false)
      })
    }
  }

  // async function onCreateMissingFolder() {
  //   // let groupID = '-Nc5t9ldsrJTPGixIdVY'
  //   let groupID = 'groupID'
  //   let res = await createProgramLibraryRootFolder({ groupID})

  //   if(res.data.error) {
  //     showError(res.data.error)
  //   } else if(res.data.message) {
  //     showSuccess(res.data.message)
  //   } else {
  //     showSuccess('Success')
  //   }
  // }

  return <Box sx={{ height: '100%' }} >
    <Box sx={{ ml: '16px', mr: '16px', height: '100%', mt: '0px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <Box sx={{ width: '35%', height: '100%', display: 'flex', mt: '24px', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography sx={{ mb: 1 }} component="h2" variant="body" align="center" >Groups</Typography>
        <TreeView
          disabled={isUploading}
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: '100%', flexGrow: 1, width: '100%', overflowY: 'auto' }}
          onNodeFocus={(event, id) => {
            setSelectedGroupKey(id)
          }}

          onNodeToggle={(event, nodeIDs) => {
            for (let key in nodeIDs) {
              let id = nodeIDs[key]
              let group = allGroups[id]
              if (group && !group.subgroups) {
                fetchSubgroups(group.id)
              }
            }
          }}
        >
          {
            treeUpdated && allGroups && renderTree(true, null)
          }
        </TreeView>
      </Box>
      <Divider flexItem orientation="vertical" sx={{ mr: 2 }} />
      <Box sx={{ mt: '24px', width: '65%' }}>
        <Typography sx={{ mb: 2 }} component="h2" variant="body" align="center" >{(allGroups ?? {})[selectedGroupKey]?.title ?? "No group selected"} </Typography>
        <Box sx={{ display: 'flex', spacingBetween: '8px', mb: 2, mt: '24px', flexDirection: 'row', alignItems: 'center' }}  >
          <Typography variant='body1'>Add User:</Typography>
          <TextField
            sx={{ ml: 2, width: '280px' }}
            placeholder={addUserRole?.code === GroupRoleEnum.student ? 'Email or Username' : "Email address"}
            value={addUserEmail}
            onChange={(e) => onEmailChange(e.target.value)}
            disabled={isAddingUser}
          />

          <Select value={addUserRole?.text ?? '-'}
            sx={{ ml: 1, width: '170px' }}
            onChange={(event) => onRoleChange(addUserRoleModel[event.target.value])}
            key="add_user_role_select"
            disabled={isAddingUser}
          >
            {
              Object.keys(addUserRoleModel).map((key) => {
                return <MenuItem value={addUserRoleModel[key].text} key={key}>
                  {addUserRoleModel[key].text}
                </MenuItem>
              })
            }
          </Select>

          {/* <Button onClick={(event) => generatePaths()}>Generate group paths</Button> */}
          <Button sx={{ ml: 1 }} disabled={!addUserRole || !addUserEmailValid || isAddingUser || !selectedGroupKey} variant="contained" onClick={(event) => onAddUser()}>Add</Button>
          {isAddingUser && <CircularProgress sx={{ ml: 1 }} />}

        </Box>
        <StyledDataGrid
          sx={{ width: '100%', height: '600px' }}
          rows={dataGridRows}
          getRowClassName={(params) => `super-app-theme--${params.row.status}`}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
        />

      </Box>
    </Box>

    <Menu
      id="menu-appbar"
      anchorEl={moreAnchor}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={moreAnchor !== null}
      onClose={handleCloseMore}>
      <MenuItem onClick={onMoveStudent} value='move_student'>
        <Divider />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DirectionsRunIcon />
          <span style={{ marginLeft: 6, color: 'info.main' ?? null }}>Move</span>
        </div>
      </MenuItem>
      {selectedStudent?.role === 7 && <MenuItem onClick={onCopyWithoutProgramming} value='copy_without_programming'>
        <Divider />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DriveFileMoveIcon />
          <span style={{ marginLeft: 6, color: 'info.main' ?? null }}>Copy without programming</span>
        </div>
      </MenuItem>
      }
    </Menu>

    <SimpleDialog open={deleteStudentName} yesTitle="Remove" noTitle="Cancel" onCompleted={onDeleteStudent} title="Careful..." text={`Are you sure you want to remove ${deleteStudentName ?? 'this user'} from ${(allGroups ?? {})[selectedGroupKey]?.title ?? 'this group'}?`} />
    <SimpleDialog open={isShowingAddUserConfirmationDialog} yesTitle="Add User" noTitle="Cancel" onCompleted={onAddUserConfirmed} title={addUserDialogTitle} text={addUserDialogMessage} />

    {/* Copy without programming */}
    <CopyWithoutProgrammingDialog open={showCopyWithoutProgrammingDialog} onCompleted={onCopyWithoutProgrammingCompleted} rootGroups={rootGroups} />
    <LoadingInformationDialog open={isCopyingWithoutProgramming} show={isCopyingWithoutProgramming} completed={isCopyingDone} items={copyWithoutProgrammingDialogModel} title='Copying...' />

    {/* Move Student dialogs */}
    <MoveStudentDialog userTerm={moveUserTerm} open={showMoveStudentDialog} onCompleted={onMoveStudentCompleted} rootGroups={rootGroups} />
    {/* <SimpleDialog open={showMoveStudentConfirmationDialog} yesTitle="Move" noTitle="Cancel" onCompleted={onAddUserConfirmed} title="Confirm please" text={`Are you sure you want to add user '${addUserEmail}' with role '${addUserRole?.text}' to ${(allGroups ?? {})[selectedGroupKey]?.title ?? 'this group'}?`} /> */}
    <LoadingInformationDialog open={isMovingStudent} show={isMovingStudent} completed={isMovingStudentDone} items={moveStudentDialogModel} title='Moving Student...' />
  </Box>
}