import { httpsCallable } from "firebase/functions";
import { functions } from "../components/firebase/firebase";
import { APIResponse } from "./response";

export const fetchEducationTemplateDescriptors = httpsCallable(functions, 'education_library-fetchEducationTemplateDescriptors');
export const fetchEducationTemplateData = httpsCallable(functions, 'education_library-fetchEducationTemplateData')
export const editEducationTemplateProgram = httpsCallable<unknown, APIResponse>(functions, 'education_library-editProgram');

export const fetchProgramLibrary = httpsCallable(functions, 'program_library-fetchProgramLibrary');
export const editProgram = httpsCallable<unknown, APIResponse>(functions, 'program_library-editProgram');
export const addItem = httpsCallable<unknown, APIResponse>(functions, 'program_library-addItem');
export const fetchFolderContents = httpsCallable(functions, "program_library-fetchFolderContents");
// export const exportProgramLibraryDataToDescriptors = httpsCallable(functions, "education_library-exportProgramLibraryDataToDescriptors");