import { atom } from "recoil";

export const isCustomizingProgramForStudentAtom = atom({
    key: 'isCustomizingProgramForStudent',
    default: false
});

export const selectedCustomizeStudentAtom = atom({
    key: 'selectedCustomizeStudent',
    default: null
});

export const doCustomizeProgramForStudentAtom = atom({
    key: 'doCustomizeProgramForStudent',
    default: false
});

export const editingProgramAtom = atom({
    key: 'editingProgram',
    default: null
});

export const editedProgramAtom = atom({
    key: 'editedProgram',
    default: null
});

export const selectedProgramAtom = atom({
    key: 'selectedProgram',
    default: null
});