import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MyGroupsTree from "./my-groups-tree";

export default function MoveStudentDialog(props) {
    const [selectedGroupKey, setSelectedGroupKey] = useState(null)
    const [selectedGroupName, setSelectedGroupName] = useState(null)
    const [userTerm, setUserTerm] = useState('student')

    useEffect(() => {
        if(props.userTerm) {
            setUserTerm(props.userTerm)
        }
    }, [props.userTerm])

    function uppercasedUserTerm() {
        return userTerm.charAt(0).toUpperCase() + userTerm.slice(1)
    }
    return <Dialog open={props.open} onClose={() => props.onCompleted?.(false)} >
        <DialogTitle>
            Move {uppercasedUserTerm()}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {uppercasedUserTerm()} will be moved to the destination group with all of it's data and programs.
            </DialogContentText>
            <DialogContentText>
                <Typography sx={{ mt: 1 }} component="p" variant="body" align="center" >Moving {userTerm} to: <b>{ selectedGroupName ?? '/' } </b></Typography>
            </DialogContentText>
            <Divider />
            { props.rootGroups && <MyGroupsTree 
                { ...props }
                sx={{ height: '700px', flexGrow: 1, width: '700px', overflowY: 'auto' }}
                // rootGroups={props.rootGroups}
                onSelectGroupKey={setSelectedGroupKey}
                onSelectGroupName={setSelectedGroupName} />
            }
        </DialogContent>
        <DialogActions sx={{ mt: 0 }}>
            <Button id="button_disagree" onClick={() => props?.onCompleted(null)}>{props.noTitle ?? "Cancel"}</Button>
            <Button disabled={!selectedGroupKey} id="button_agree" onClick={() => props?.onCompleted(selectedGroupKey)}>{props.yesTitle ?? "Move"}</Button>
        </DialogActions>
    </Dialog>
}
