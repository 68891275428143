import React, { useEffect, useState } from 'react';
import { FirebaseEntry } from '../components/firebase/firebase';
import '../assets/css/program_library.css'
import Divider from '@mui/material/Divider';
import { addItem, editProgram, fetchFolderContents, fetchProgramLibrary, fetchEducationTemplateDescriptors, fetchEducationTemplateData, editEducationTemplateProgram } from '../api/program_library'
import { Button, Box, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { EditProgramForm, EditProgramMode } from './program/edit_program';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddProgramForm from './program/add_program';
import CustomizeProgram from './program/customize_program';
import { toast } from 'react-toastify';
import MyGroupsSubheader from '../components/header/my_groups_subheader';
import ProgramLibrarySubheader from '../components/header/program_library_subheader';
import ProgramLibraryProgramSubheader from '../components/header/program_library_program_subheader';
import { useRecoilState } from 'recoil';
import { editedProgramAtom, editingProgramAtom, isCustomizingProgramForStudentAtom, selectedCustomizeStudentAtom, selectedProgramAtom } from '../recoil/atom/program_library_atom';
import { doSubmitFunctionAtom, updatingFunctionAtom } from '../recoil/atom/function_atom';
import { showError, showSuccess } from '../components/toasts';
import { printObject } from '../utils/print_object';

export interface EducationTemplateDescriptorData {
    key: string|null
    name: string
    itemType?: string
    hasProgram?: Boolean
    parentID: string|null
    photoURL?: string
}

export interface EducationTemplateData {
    id: string
    program?: TextualProgram
}

export interface ProgramLibraryData {
    key: string
    folderName: string
    rootFolder?: ProgramLibraryFolder
    items: { [key: string]: ProgramLibraryFolder }
    photoURL?: string
}

export interface ProgramLibraryItem {
    key?: string
    itemType: string
    name: string
    program: TextualProgram
}

export interface TextualProgram {
    title: string
    textItems: { [key: string]: ProgramTextEntry }
    signatures: { [key: string]: ProgramTextEntry }
    discontinuationItems?: Array<{ [key: string]: {} }>
}

export enum ProgramEntryFieldType {
    Multiline = "multiline",
    TextField = "textField",
    Signature = "signature"
}

export interface ProgramTextEntry {
    fieldType: ProgramEntryFieldType
    index: number
    label: string
    value: string
    email?: string
    type?: string
    isParentSignature?: boolean
    annual?: boolean
}

export interface ProgramLibraryFolder {
    key: string | null
    folderName: string
    parentGroupID?: string
    items: { [key: string]: ProgramLibraryItem } | null
    rootGroupID?: string

    // calculated properties
    subfolders?: Array<ProgramLibraryFolder>
    programs?: Array<TextualProgram>
    parentFolder?: ProgramLibraryFolder | null
}

export default function ProgramLibrary() {
    // const [selectedFolderKey, setSelectedFolderKey] = useState<string | null>(null)
    const [selectedFolders, setSelectedFolders] = useState<Array<EducationTemplateDescriptorData> | null>(null)
    const [folders, setFolders] = useState<Array<EducationTemplateDescriptorData> | null>(null)

    // const [libraries, setLibraries] = useState(Array<EducationTemplateDescriptorData>())
    const [selectedFolder, setSelectedFolder] = useState<EducationTemplateDescriptorData | null>(null)
    const [selectedProgramItem, setSelectedProgramItem] = useState<EducationTemplateDescriptorData | null>(null)
    const [selectedProgram, setSelectedProgram] = useState<ProgramLibraryItem | null>(null)
    const [addMode, setAddMode] = useState(false)
    const [reloadingCurrentFolder, setReloadingCurrentFolder] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [selectedProgramGlobal, setSelectedProgramAtom] = useRecoilState<any>(selectedProgramAtom)
    const [customizeProgram, setCustomizeProgram] = useRecoilState<any>(isCustomizingProgramForStudentAtom)
    const [updatingFunction, setUpdatingFunction] = useRecoilState(updatingFunctionAtom)
    const [selectedCustomizeStudent, setSelectedCustomizeStudent] = useRecoilState<any>(selectedCustomizeStudentAtom)
    const [editingProgram, setEditingProgram] = useRecoilState<any>(editingProgramAtom)
    const [editedProgram, setEditedProgram] = useRecoilState<any>(editedProgramAtom)
    const [doSubmitProgram, setDoSubmitProgram] = useRecoilState<any>(doSubmitFunctionAtom)

    useEffect(() => {
        if ((selectedProgramGlobal || selectedProgram) && selectedProgram?.name != selectedProgramGlobal?.name) {
            setSelectedProgram(selectedProgramGlobal)
        }
    }, [selectedProgramGlobal])

    useEffect(() => {
        if(doSubmitProgram && editedProgram) {
            printObject(editedProgram, 'sending... ')
            onEditProgram(editedProgram)
            setDoSubmitProgram(false)
        }
    }, [doSubmitProgram])

    useEffect(() => {
        setSelectedProgramAtom(null)
        setEditingProgram(null)
        setSelectedCustomizeStudent(null)
        setSelectedProgram(null)
        setCustomizeProgram(false)
    }, []);

    useEffect(() => {
        let parentID = (selectedFolder?.key || null)
        
        setSelectedProgram(null)
        setSelectedProgramItem(null)
        setSelectedProgram(null)
        
        console.log('fetching program library...')
        setLoading(true)
        let time = Date.now()
        
        fetchEducationTemplateDescriptors({ parentID: parentID}).then((result) => {
            console.log('fetched data in ' + (Date.now() - time))

            let jsonString = JSON.stringify(result.data, null, 4);
            console.log("education templates is " + jsonString)
            let validLibraries = Array<EducationTemplateDescriptorData>()

            const libraries = result.data as Array<{}>
            for (let idx in libraries) {
                const entry = libraries[idx] as FirebaseEntry
                if (!entry) {
                    console.log('' + idx + ' is not a valid entry')
                    continue
                }

                let library = entry.value as EducationTemplateDescriptorData
                if(!entry.value) {
                    
                    continue
                }else if (!library) {
                    continue
                }

                if (library && library.name) {
                    if(library.itemType === 'folder' || (library.itemType === 'item' && library.hasProgram === true)) {
                        library.key = entry.key
                        validLibraries.push(library)
                    }
                }
            }

            // setSelectedFolder(null)
            let sorted = validLibraries.sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 })
            parseLibraries(sorted)
            setFolders(sorted)
            // setLibraries(validLibraries)
            setLoading(false)
        });
    }, [selectedFolder]);

    useEffect(() => {
        setSelectedProgramAtom(selectedProgram)
        setEditingProgram(selectedProgram)
    }, [selectedProgram]);

    useEffect(() => {
        if(!editingProgram) {
            setSelectedProgramItem(null)
        }
    }, [editingProgram]);

    useEffect(() => {
        // fetch program for selected item
        if(selectedProgramItem?.key) {
            setLoading(true)
            let key = selectedProgramItem?.key ?? ""
            fetchEducationTemplateData({ itemID: selectedProgramItem.key }).then((response) => {
                printObject(response.data, `item ${key}`)
                let obj = response.data as {[key: string]: {}}
                let item: EducationTemplateData | undefined = obj['value'] as EducationTemplateData
                
                if(item?.program) {
                    let name = selectedProgramItem?.name ?? ""
                    console.log('program exists with name ' + name)
                    printObject(item.program, 'program')
                    setSelectedProgram({
                        key: key,
                        itemType: 'item',
                        name: name,
                        program: item.program})
                } else {
                    console.log('program does not exist')
                }
            }).catch((err) => {
                showError(err)
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setSelectedProgram(null)
        }
    }, [selectedProgramItem]);

    function isValidFolder(folder: ProgramLibraryFolder): boolean {
        return (folder.key != undefined && folder.folderName != undefined)
    }

    function parseLibraries(libraries: Array<EducationTemplateDescriptorData>) {
        let folders: { [key: string]: ProgramLibraryFolder } = {}
        for (let idx in libraries) {

            // add folders to a dictionary
            const library = libraries[idx]

            const rootFolder: ProgramLibraryFolder = { folderName: library.name, key: null, subfolders: Array<ProgramLibraryFolder>(), items: null }

            // create folder hierarchy
            for (let folderKey in folders) {
                const folder = folders[folderKey]
                if (folder.parentGroupID) {
                    let parentFolder = folders[folder.parentGroupID]
                    if (parentFolder && parentFolder.subfolders) {
                        parentFolder.subfolders.push(folder)
                        folder.parentFolder = parentFolder
                    }
                } else {
                    // root folder
                    rootFolder.subfolders?.push(folder)
                    folder.parentFolder = rootFolder
                }
            }

            sortFolders(rootFolder)
        }
    }

    function sortFolders(folder: ProgramLibraryFolder) {
        if(folder.subfolders) {
            folder.subfolders = folder.subfolders.sort((a, b): number => { return a.folderName.toLowerCase() < b.folderName.toLowerCase() ? -1 : 1 })

            for(let key in folder.subfolders) {
                let subfolder = folder.subfolders[key]
                if(subfolder.subfolders && subfolder.subfolders.length > 0) {
                    sortFolders(subfolder)
                }
            }
        }
    } 

    function onSelectTextualProgram(key: string, item: ProgramLibraryItem) {
        if (key) {
            console.log("onSelectTextualProgram " + item + " ; " + item.program + " ; " + item.program.title + " ; " + item.key)
            setSelectedProgram(item)
        }
    }

    function onSelectItem(key: string|null) {
        if(!folders) {
            return
        }

        for(let idx in folders) {
            let item = folders[idx]

            if(key === item.key) {
                if(item.itemType == 'folder') {
                    // selected folder
                    setSelectedFolder(item)
    
                    let currentFolders = selectedFolders ?? []
                    currentFolders.push(item)
                    setSelectedFolders(currentFolders)
                    break
                } else if(item.itemType === 'item') {
                    setSelectedProgramItem(item)
                    break
                }
            }
        }
    }

    function onSelectFolder(folder: ProgramLibraryFolder | null) {
        setAddMode(false)
        setCustomizeProgram(null)
        setSelectedProgram(null)
        if(folder) {
            console.log('folder selected ' + folder.folderName + ' ' + folder.key)
            
        }
        // setSelectedFolder(folder)
    }

    function onSelectBreadcrumbsFolder(folder: EducationTemplateDescriptorData | null) {
        setAddMode(false)
        setSelectedFolder(folder)

        if(!folder) {
            setSelectedFolders(null)
        } else {
            let currentFolders = selectedFolders ?? []

            let index = 0
            for(let idx in currentFolders) {
                
                if(currentFolders[idx].key === folder?.key) {
                    currentFolders = currentFolders.splice(0, index + 1)
                    break
                }
                index += 1
            }
            
            setSelectedFolders(currentFolders)
        }        
    }

    function onAddProgram(program: TextualProgram) {
        if (selectedFolder) {
            setSubmitting(true)
            setUpdatingFunction(true)
            printObject(program, "submitting program")
            addItem({ program: program, folderID: selectedFolder.key, title: program.title })
                .then((response) => {
                    if (response.data?.message) {
                        console.log('success!')
                        setAddMode(false)
                        setEditingProgram(null)
                        showSuccess(response.data.message)
                    } else {
                        const error = response.data?.error ?? "An error occurred."
                        console.log('err ' + error)
                        showError(error)
                    }
                }).catch((err) => {
                    showError(err)
                }).finally(() => {
                    setSubmitting(false)
                    setUpdatingFunction(false)
                })
        }
    }

    function onEditProgram(program: TextualProgram) {
        if (selectedProgram?.key) {

            setSubmitting(true)
            setUpdatingFunction(true)

            editEducationTemplateProgram({ itemID: selectedProgram?.key, program: program }).then((response) => {
                if (response.data.error) {
                    showError(response.data.error)
                } else {
                    let message: string = response.data.message ?? "Program edited."
                    showSuccess(message)

                    setSelectedProgram(null)
                }
            }).catch((err) => {
                showError(err)
            }).finally(() => {
                setSubmitting(false)
                setUpdatingFunction(false)
            })
        }
    }

    return (
        <div>
            <Box sx={{ mt: '80px', width: '100%' }}>
                {(loading || reloadingCurrentFolder) &&
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '16px' }}>
                        <CircularProgress className="spinner" />
                    </Box>
                }
                {
                    !loading && folders && !addMode && !selectedProgram &&
                    <List>
                        { selectedFolder && 
                            <ListItem key="add_program_button">
                                <ListItemButton
                                    onClick={() => { setAddMode(true) }} >
                                    <ListItemIcon>
                                        <AddCircleIcon />
                                    </ListItemIcon  >
                                    <ListItemText primary="Add New" />
                                </ListItemButton>
                            </ListItem>
                        }
                        
                        {
                            folders.map((value) => {
                                return (
                                    <ListItem key={value.key}>
                                        <ListItemButton onClick={() => onSelectItem(value.key)}>
                                            <ListItemIcon>
                                            {
                                                (value.hasProgram ?? false) ? <ArticleOutlinedIcon sx={{ color: '#48a5d3' }} /> : <FolderIcon />
                                            }    
                                            </ListItemIcon  >
                                            <ListItemText primary={value.name} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })
                        } 
                    </List>

                }

                {selectedProgramGlobal && !customizeProgram &&
                    <EditProgramForm mode={EditProgramMode.Edit} loading={submitting} isEmbedded={false} item={selectedProgramGlobal} onSubmit={(program) => onEditProgram(program)} />
                }

                {selectedProgramGlobal && customizeProgram &&
                    <CustomizeProgram onCompleted={() => setCustomizeProgram(false)} />
                }

                {addMode &&
                    <AddProgramForm submitting={submitting} onAddProgram={(program) => onAddProgram(program)} />
                }

                {!selectedProgram && !customizeProgram && 
                    <ProgramLibrarySubheader key="program_library_subheader" editMode={false} folders={selectedFolders} onSelectFolder={(folder: EducationTemplateDescriptorData | null) => onSelectBreadcrumbsFolder(folder)} />
                }

                { selectedProgram &&
                    <ProgramLibraryProgramSubheader key="program_library_program_subheader" editMode={false} allFolders={folders} selectedFolder={selectedFolder} onSelectFolder={(folder: ProgramLibraryFolder | null) => onSelectFolder(folder)} />
                }
            </Box>
        </div>
    );
}
