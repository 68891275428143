import { toast } from 'react-toastify';

export function showLoading(message, toastID) {
    return toast.loading(message, {
        position: "top-center",
        hideProgressBar: true,
        toastId: toastID,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false
    });
}

export function showError(message, toastID) {
    return toast.error(message, {
        position: "top-center",
        autoClose: 6000,
        toastId: toastID,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false
    });
}

export function showSuccess(message, toastID) {
    return toast.success(message, {
        position: "top-center",
        autoClose: 6000,
        toastId: toastID,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false
    });
}

// Dismiss toast

export function dismissToast(toastID) {
    return toast.dismiss(toastID)
}

// Update existing toast

export function updateToastSuccess(message, toastID) {
    return updateToast(toast.TYPE.SUCCESS, message, toastID)
}

export function updateToastError(message, toastID) {
    return updateToast(toast.TYPE.ERROR, message, toastID)
}

export function updateToast(type, message, toastID) {
    return toast.update(toastID, { 
        update: toastID,
        type: type, 
        render: message,
        autoClose: 6000,
        isLoading: false
    })
}
