import { Button, Box, CircularProgress, ListSubheader, Divider, List, ListItem, ListItemButton, ListItemText, ListItemIcon, ToggleButton, Checkbox, Radio, RadioGroup, CardContent, CardActionArea, TextField, IconButton } from "@mui/material";
import Typography from '@mui/material/Typography';

import { createdReportDownloadURLState, createdReportSettingsState, isEditingSavedReportState, selectedReportState } from "../../recoil/atom/reports-state";
import { useRecoilState } from "recoil";
import { Card } from "@material-ui/core";
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from "react";
import { saveReportSettings } from "./reports.api";
import { showError, showSuccess } from "../../components/toasts";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";

export default function ReportCreated(props) {
    const [isEditingSavedReport, setEditingSavedReport] = useRecoilState(isEditingSavedReportState)
    const [editedReport, _] = useRecoilState(selectedReportState) // report being edited; null if we're creating a new report
    const [createdReportURL, setCreatedReportURL] = useRecoilState(createdReportDownloadURLState)
    const [reportSettings, setReportSettings] = useRecoilState(createdReportSettingsState)
    const [reportName, setReportName] = useState("")
    const [isSavingReport, setSavingReport] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    function onDownloadClick() {
        const link = document.createElement("a");
        link.target = '_blank'
        link.download = 'file.zip'
        link.href = createdReportURL
        link.click();
    }

    function onUpdateClick() {
        if (!editedReport?.id) {
            return
        }
        setIsUpdating(true)
        let data = { ...reportSettings, id: editedReport.id, reportName: editedReport.title }

        saveReportSettings(data).then((resp) => {
            setIsUpdating(false)
            if (resp.data.error) {
                showError(resp.data.error)
            } else {
                showSuccess(resp.data.message ?? 'Report saved')
                setIsUpdated(true)
            }
        }).catch(err => {
            setIsUpdating(false)
            showError('An error occurred, please try again later.')
        })
    }

    function onSaveClick() {
        let minLength = 3
        let trimmed = reportName.trim()
        if (trimmed.length < minLength) {
            showError(`Report name must be at least ${minLength} characters long`)
            return
        }

        let data = { ...reportSettings }
        data.reportName = trimmed
        setSavingReport(true)
        saveReportSettings(data).then((resp) => {
            setSavingReport(false)
            if (resp.data.error) {
                showError(resp.data.error)
            } else {
                showSuccess(resp.data.message ?? 'Report saved')
                setIsSaved(true)
            }
        }).catch(err => {
            setSavingReport(false)
            showError('An error occurred, please try again later.')
        })
    }

    return <Box sx={{ marginLeft: 1, userSelect: 'none', mt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3, alignItems: 'center' }}>
            <Typography variant="body" component="h3" >Report successfully created!</Typography>
            <div sx={{ flexGrow: 1 }}></div>
            <Button component={Link} to="/dashboard/reports" sx={{ marginLeft: 'auto', mr: 4 }} startIcon={<ArrowBackIcon />} variant="contained">Back to Reporting</Button>

        </Box>

        <Box sx={{ mt: 2, width: '160px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {createdReportURL && <Card onClick={() => onDownloadClick()}>
                <CardActionArea  >
                    <CardContent >
                        <DownloadIcon sx={{ width: '100%', height: '40px' }} />
                        <Typography sx={{ fontSize: 17 }} color="text.secondary" gutterBottom>
                            Download Files
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            }
        </Box>
        <Divider sx={{ width: '100%', mt: 4 }} /> <br />
        {reportSettings && <div>
            {isEditingSavedReport && editedReport &&
                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="body" sx={{ height: '100%', mr: 1 }}>Save settings as a new copy</Typography>
                        <TextField disabled={isSaved || isSavingReport} label="e.g. 'Quarterly Report'" value={reportName} onChange={(event) => setReportName(event.target.value)} />
                        {(isSavingReport || isUpdating) ? <CircularProgress sx={{ ml: 1 }} /> : <Button disabled={isSaved || isUpdated || isSavingReport || isUpdating} onClick={() => onSaveClick()} variant="contained" sx={{ ml: 1 }}>{isSaved ? 'Saved' : 'Save'}</Button>}
                        {/* <br /> */}
                    </Box>

                    <Typography variant="body" sx={{ height: '100%', mr: 1, mt: 1 }}>or</Typography>
                    <Button disabled={isSaved || isUpdated || isSavingReport || isUpdating} onClick={() => onUpdateClick()} variant="contained" sx={{ mt: 3 }}>{isUpdated ? 'Settings updated' : 'Update existing settings'}</Button>
                </Box>
            }

            {(!editedReport || !isEditingSavedReport) &&
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="body" sx={{ height: '100%', mr: 1 }}>Save report settings for future use</Typography>
                    <TextField disabled={isSaved || isSavingReport} label="e.g. 'Quarterly Report'" value={reportName} onChange={(event) => setReportName(event.target.value)} />
                    { (isUpdating || isSavingReport) ? <CircularProgress sx={{ ml: 1 }} /> : <Button disabled={isSaved || isUpdated || isSavingReport || isUpdating} onClick={() => onSaveClick()} variant="contained" sx={{ ml: 1 }}>{isSaved ? 'Saved' : 'Save'}</Button>}
                </Box>
            }

        </div>
        }

    </Box>

}
