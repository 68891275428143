import { Button, Box, CircularProgress, ListSubheader, Divider, List, ListItem, ListItemButton, ListItemText, ListItemIcon, ToggleButton, Checkbox, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { AccountCircle, Label } from "@mui/icons-material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FormControl from '@mui/material/FormControl';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fetchReports, fetchStudentPrograms, fetchRecentReports, fetchGroups, fetchAllReports, fetchStudentFunctions, createReport, fetchExpandGroups, mergeRootAndExpandedGroups } from "./reports.api";
import dayjs from 'dayjs';
import { defaultWrittenComponentOptions } from "./report_written_components";
import ReportSubheader from "../../components/header/reports_subheader";
import { showError } from "../../components/toasts";
import { createdReportDownloadURLState, createdReportSettingsState, selectedReportState } from "../../recoil/atom/reports-state";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import LoadingInformationDialog from "./create-report-alert";

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const dateFormat = 'MM/DD/YYYY'

export default function CreateEditReport(props) {
    const [selectedReport, _] = useRecoilState(selectedReportState)
    const [createdReportURL, setCreatedReportURL] = useRecoilState(createdReportDownloadURLState)
    const [reportSettings, setReportSettings] = useRecoilState(createdReportSettingsState)
    const [screenTitle, setScreenTitle] = useState("")

    const [isFetchingGroupsToExpand, setIsFetchingGroupsToExpand] = useState(false)
    const [expandedGroups, setExpandedGroups] = useState(null)
    const [defaultExpandedIDs, setDefaultExpandedIDs] = useState(null)
    const [rootGroups, setRootGroups] = useState(null)
    const [isSubmitting, setSubmitting] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)

    // report data
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(dayjs(new Date()))
    const [showFirstPointsCount, setShowFirstPointsCount] = useState(3)
    const [showLastPointsCount, setShowLastPointsCount] = useState(3)
    const [selectedStudents, setSelectedStudents] = useState({})
    const [selectedGroups, setSelectedGroups] = useState({})
    const [programsType, setProgramsType] = useState('all')
    const [defaultAveragePeriod, setDefaultAveragePeriod] = useState('week')
    const [userDefinedAveragePeriod, setUserDefinedAveragePeriod] = useState(null)
    const [selectedWrittenComponents, setSelectedWrittenComponents] = useState({})
    const [includeAllResponses, setIncludeAllResponses] = useState(false)
    const [includeResponseRanges, setIncludeResponseRanges] = useState(false)
    const [includeGraphNotes, setIncludeGraphNotes] = useState(false)
    const [includeDataSheetNotes, setIncludeDataSheetNotes] = useState(false)
    const [studentNotes, setStudentNotes] = useState({})
    const [overallNote, setOverallNote] = useState("")
    const [studentPrograms, setStudentPrograms] = useState({})

    // state
    const [selectFirstLastRange, setSelectFirstLastRange] = useState([])
    const [selectedTimeframeIndex, setSelectedTimeframeIndex] = useState(null)
    const [isCustomRangeSelected, setCustomRangeSelected] = useState(false)
    const [isShowFirstPoints, setIsShowFirstPoint] = useState(false)
    const [isIncludeFirstPointsAverage, setIncludeFirstPointsAverage] = useState(false)
    const [isShowLastPoints, setIsShowLastPoint] = useState(false)
    const [isIncludeLastPointsAverage, setIncludeLastPointsAverage] = useState(false)
    const [groupTree, setGroupTree] = useState(null)
    const [allGroups, setAllGroups] = useState({})
    const [allStudents, setAllStudents] = useState({})

    const [studentsProgramData, setStudentsProgramData] = useState([])
    const [addNotePerStudent, setAddNotePerStudent] = useState(false)
    const [treeUpdated, setTreeUpdated] = useState(1)
    const timeframeOptions = ['1 Day', '1 Week', '1 Month', '3 Months', '6 Months', '1 Year']
    const writtenComponentOptions = defaultWrittenComponentOptions;

    useEffect(() => {
        fetchGroups(null).then((result) => {
            setRootGroups(result.data)
        })

        let firstLastRange = [...Array(31).keys()]
        firstLastRange.shift()
        setSelectFirstLastRange(firstLastRange)

    }, [])

    useEffect(() => {
        if(isFetchingGroupsToExpand) {
            return
        }
        if(expandedGroups && rootGroups) {
            mergeRootAndExpandedGroups(expandedGroups, rootGroups)
        }
        addToAllGroups(rootGroups)
        setGroupTree(rootGroups)

        setTreeUpdated(treeUpdated + 1)
    }, [expandedGroups, rootGroups])

    useEffect(() => {
        if (!selectedReport) {
            setScreenTitle('New Report')
        } else {
            setScreenTitle(selectedReport.title)
            setDateFrom(dayjs(selectedReport.dateFrom))
            setDateTo(dayjs(selectedReport.dateTo))
            
            let students = {}
            
            Object.values(selectedReport.students ?? {}).forEach((student) => {
                students[student.id] = student
            })

            setSelectedStudents(students)
            setProgramsType(selectedReport.programsType)
            if(selectedReport.firstPoints) {
                setShowFirstPointsCount(selectedReport.firstPoints)
                setIsShowFirstPoint(true)
                let avg = selectedReport.isIncludeFirstPointsAverage
                if(avg != null && avg != undefined) {
                    setIncludeFirstPointsAverage(avg)
                }
            }

            if(selectedReport.lastPoints) {
                setShowLastPointsCount(selectedReport.lastPoints)
                setIsShowLastPoint(true)
                let avg = selectedReport.isIncludeLastPointsAverage
                if(avg != null && avg != undefined) {
                    setIncludeLastPointsAverage(avg)
                }
            }
           
            if(selectedReport.includeAllResponses) {
                setIncludeAllResponses(true)
            }

            if(selectedReport.includeDataSheetNotes) {
                setIncludeDataSheetNotes(true)
            }

            if(selectedReport.includeGraphNotes) {
                setIncludeGraphNotes(true)
            }
            
            if(selectedReport.includeResponseRanges) {
                setIncludeResponseRanges(true)
            }

            if(selectedReport.overallNote) {
                setOverallNote(selectedReport.overallNote)
            }

            if(selectedReport.averagePeriod) {
                setUserDefinedAveragePeriod(selectedReport.averagePeriod)
            }

            if(selectedReport.studentNotes && Object.keys(selectedReport.studentNotes).length > 0) {
                setAddNotePerStudent(true)
                setStudentNotes(selectedReport.studentNotes)
            }

            if(selectedReport.writtenComponents) {
                let comps = {}
                Object.values(selectedReport.writtenComponents).forEach((comp) => {
                    comps[comp.key] = comp
                })
                setSelectedWrittenComponents(comps)
            }

            if(selectedReport.groupsToExpand) {
                let groupIDs = Object.values(selectedReport.groupsToExpand)
                if(groupIDs.length > 0) {
                    console.log('fetching groups ' + groupIDs)
                    setDefaultExpandedIDs(groupIDs)
                    setIsFetchingGroupsToExpand(true)
                    fetchExpandGroups(groupIDs).then((results) => {
                        for(let i in results) {
                            let res = results[i]
                            addToAllGroups(res.groups ?? [])
                        }
                        setExpandedGroups(results)
                    }).finally(() => {
                        setIsFetchingGroupsToExpand(false)
                    })
                }
            }

            setSelectedTimeframeIndex(-1)
        }
    }, [selectedReport])

    useEffect(() => {
        if(isSubmitted) {
            window.setTimeout(() => {
                navigate('/dashboard/report-created', { replace: true })
            }, 1500);
        }
    }, [isSubmitted])

    useEffect(() => {
        updateBeginDate()
    }, [selectedTimeframeIndex])

    useEffect(() => {
        updateBeginDate()
    }, [dateTo])

    useEffect(() => {
        if (programsType == 'per_student') {
            var selected = []
            // for(let idx in selectedStudents) {
            //     let student = selectedStudents[idx]

            // }
            fetchStudentFunctions(selected).then((result) => {
                let data = result?.data ?? []
                setStudentsProgramData(data)
            })
        }
    }, [programsType])

    function getAveragePeriod() {
        return userDefinedAveragePeriod ?? defaultAveragePeriod
    }

    function updateStudentNote(key, text) {
        let notes = studentNotes
        notes[key] = text
        setStudentNotes((prevNotes) => ({
            ...notes
        }))
    }

    function renderTree(isRoot, parent) {
        if (!groupTree) {
            return <CircularProgress />
        } else if (isRoot) {
            return groupTree.map((sub) => {
                return renderTree(false, sub)
            })
        } else if (!parent) {
            return <CircularProgress />
        }

        if (parent.type == 'student') {
            return <FormGroup id={parent.id} >
                <FormControlLabel sx={{ ml: 4 }} control={<Checkbox checked={selectedStudents[parent.id] != null} onChange={(event) => toggleStudent(parent.id, event.target.checked)} />} label={parent.title} />
            </FormGroup>
        } else {
            return <TreeItem nodeId={parent.id}
                label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormControlLabel sx={{ mr: 0 }} control={
                        <Checkbox sx={{ ml: 1 }} checked={selectedGroups[parent.id] != null}
                            onChange={(event) => toggleGroup(parent.id, event.target.checked)}
                            onClick={e => (e.stopPropagation())}
                        />}> </FormControlLabel>
                    <Typography >{parent.title}</Typography>
                </Box>
                }>

                {
                    parent?.subgroups && parent?.subgroups.map((sub) => {
                        return renderTree(false, sub)
                    })
                }
                {
                    !parent.isEmpty && parent?.subgroups == null && <CircularProgress size={32} />
                }
                {
                    parent.isEmpty && <TreeItem nodeId={`empty_${parent.id}`} label='/'></TreeItem>
                }
            </TreeItem>
        }
    }

    function toggleGroup(id, checked) {
        let group = allGroups[id]

        let groups = selectedGroups
        let students = selectedStudents

        toggleAllSubitems(group, checked, groups, students)

        setSelectedStudents((prev) => ({
            ...students
        }))

        if (!checked) {
            deselectParentGroups(id, groups)
        }

        setSelectedGroups((prev) => ({
            ...groups
        }))
    }

    function toggleAllSubitems(parent, checked, groups, students) {
        if (checked) {
            if (parent.type == 'student') {
                students[parent.id] = parent
            } else {
                groups[parent.id] = parent
            }
        } else {
            if (parent.type == 'student') {
                delete students[parent.id]
            } else {
                delete groups[parent.id]
            }
        }

        let subgroups = parent.subgroups ?? []
        subgroups.forEach((item) => {
            toggleAllSubitems(item, checked, groups, students)
        })
    }

    function toggleStudent(id, checked) {
        let student = allStudents[id]
        if (checked) {
            setSelectedStudents((prevStudents) => ({
                ...prevStudents,
                [id]: student
            }))
        } else {
            let students = selectedStudents
            delete students[id]
            setSelectedStudents((prevStudents) => ({
                ...students
            }))

            // deselect all parent groups
            let groups = selectedGroups
            deselectParentGroups(student.groupID, groups)
            setSelectedGroups((prev) => ({
                ...groups
            }))
        }
    }

    function deselectParentGroups(id, groups) {
        delete groups[id]
        let group = allGroups[id]

        if (group.parentID) {
            deselectParentGroups(group.parentID, groups)
        }
    }

    function addToAllGroups(arr) {
        let all = allGroups ?? {}
        let alls = allStudents ?? {}
        for (let key in arr) {
            let item = arr[key]
            if (item.type == 'group') {
                all[item.id] = item
            } else if (item.type == 'student') {
                alls[item.id] = item
            }

            let subgroups = item.subgroups ?? []
            subgroups.forEach((subg) => {
                if (subg.type == 'group') {
                    all[subg.id] = subg
                } else if (subg.type == 'student') {
                    alls[subg.id] = subg
                }
            })
        }

        setAllGroups(all)
        setAllStudents(alls)
    }

    function fetchSubgroups(parentID) {
        fetchGroups(parentID).then((result) => {
            addToAllGroups(result.data)

            let tree = groupTree
            let item = findGroup(parentID, tree)

            if (item) {
                item.subgroups = result.data
                if (result.data.length == 0) {
                    item.isEmpty = true
                }
            }

            setTreeUpdated(treeUpdated + 1)
            setGroupTree(tree)

            if (selectedGroups[item.id] != null) {
                // expanding an item which is selected -> select all subgroups and students
                toggleGroup(item.id, true)
            }
        })
    }

    function findGroup(id, tree) {
        return allGroups[id]
    }

    function updateBeginDate() {

        let fromDate = new Date(dateTo.valueOf());
        if (selectedTimeframeIndex != -1) {
            switch (selectedTimeframeIndex) {
                case 0:
                    fromDate = fromDate.addDays(-1)
                    break;
                case 1:
                    fromDate = fromDate.addDays(-7)
                    break;
                case 2:
                    fromDate = addMonths(fromDate, -1)
                    break;
                case 3:
                    fromDate = addMonths(fromDate, -3)
                    break;
                case 4:
                    fromDate = addMonths(fromDate, -6)
                    break;
                case 5:
                    fromDate.setFullYear(fromDate.getFullYear() - 1);
                    break;
                default:
                    return;
            }

            setDateFrom(dayjs(fromDate))
        }
    }

    function addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }

    const navigate = useNavigate()

    function getTreeExpandPaths(parent, node, expandedGroups) {
        if(node.type == 'student') {
            if(selectedStudents[node.id]) {
                if(parent?.id) {
                    expandedGroups[parent.id] = true
                    // return true because we need to expand all parent groups
                }
                return true
            }
        } else {
            if(selectedGroups[node.id]) {
                // save parent, because it has to be expanded so the node is visible
                if(parent?.id) {
                    expandedGroups[parent.id] = true
                }
                return true
            } else {
                let subgroups = node.subgroups ?? []

                let needsToBeExpanded = false
                for(let si in subgroups) {
                    let subg = subgroups[si]
                    let result = getTreeExpandPaths(node, subg, expandedGroups)
                    needsToBeExpanded = needsToBeExpanded || result
                }

                if(needsToBeExpanded && parent?.id) {
                    expandedGroups[parent.id] = true
                    return true
                } else {
                    return false
                }
            }
        }
    }

    function getSelectedGroupsAndStudents(node, groups, students) {
        if(node.type == 'student') {
            if(selectedStudents[node.id]) {
                students.push(node)
            }
        } else {
            if(selectedGroups[node.id]) {
                // whole group is selected, add it and do not add lower lever groups and students
                groups.push(node)
            } else {
                // else go down and check those below
                let subgroups = node.subgroups ?? []
                subgroups.forEach((subg) => {
                    getSelectedGroupsAndStudents(subg, groups, students)
                })
            }
        }
    }

    function doCreateReport() {

        let errorMessage = validateForm()

        if (errorMessage) {
            showError(errorMessage)
        } else {
            let formattedDateFrom = dateFrom.format(dateFormat)
            let formattedDateTo = dateTo.format(dateFormat)
            
            let firstPoints = isShowFirstPoints ? showFirstPointsCount : null
            let lastPoints = isShowLastPoints ? showLastPointsCount : null

            let selectedStudentPrograms = studentPrograms
            if (programsType != 'per_student') {
                selectedStudentPrograms = null
            }

            let groups = []
            let students = []

            let expandPaths = {}
            groupTree.forEach((rootGroup) => {
                getSelectedGroupsAndStudents(rootGroup, groups, students)
                getTreeExpandPaths(null, rootGroup, expandPaths)
            })

            let groupsToExpand = Object.keys(expandPaths)

            console.log('sending ' + students.length + ' students')
            console.log('sending students json ' + JSON.stringify(students, null, 4))

            let params = {
                students, groups, programsType, groupsToExpand, reportID: selectedReport?.id ?? null,
                studentPrograms: selectedStudentPrograms, selectedStudentPrograms, dateFrom: formattedDateFrom, dateTo: formattedDateTo,
                writtenComponents: Object.values(selectedWrittenComponents), averagePeriod: getAveragePeriod(),
                includeAllResponses, includeResponseRanges, includeGraphNotes, includeDataSheetNotes, includeFirst: firstPoints, firstPointsAverage: isIncludeFirstPointsAverage,
                includeLast: lastPoints, lastPointsAverage: isIncludeLastPointsAverage, studentNotes, overallNote
            }

            console.log('params ' + JSON.stringify(params, null, 4))

            setReportSettings(params)
            setSubmitting(true)
            createReport(params).then((resp) => {
                if(resp.data?.error) {
                    showError(resp.data.error)
                    return
                }
                console.log(`message ${resp.data.message}; error ${resp.data.error}`)
                let downloadURL = resp.data?.downloadURL
                if (downloadURL) {
                    setCreatedReportURL(downloadURL)
                }
                setSubmitted(true)
            }).catch(err => {
                if(err?.error) {
                    showError('An error occurred, please try again.')
                } 
            }).finally(() => {
                setSubmitting(false)
            })
        }
    }


    function validateForm() {
        if (!dateFrom) {
            return "Please select the Date Range"
        }

        let students = Object.values(selectedStudents)
        if ((students?.length ?? 0) == 0) {
            return "Please select at least one student"
        }

        // if( selectedWrittenComponents.length == 0) {
        //     return "Please select at least one written component"
        // }

        return null
    }

    return <Box sx={{ marginLeft: 1, userSelect: 'none', mt: '80px', width: '100%' }}>

        <Typography variant="body" component="h2" sx={{ mt: 3 }} style={{ display: 'inline-block' }}>{screenTitle}</Typography>
        <Box sx={{ marginLeft: 3 }}>
            <div className="date_range">
                <h3>Date Range</h3>
                {timeframeOptions.map((option, index) =>
                    <ToggleButton sx={{ marginLeft: 1 }} selected={selectedTimeframeIndex == index} color='primary' onChange={e => setSelectedTimeframeIndex(index)}>{option}</ToggleButton>
                )}
                <br />
                <ToggleButton sx={{ marginLeft: 1, marginTop: 2 }} selected={selectedTimeframeIndex == -1} color='primary' onChange={e => setSelectedTimeframeIndex(-1)}>Custom Range</ToggleButton>

                {<Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Typography sx={{ ml: '16px', mr: '8px' }} variant="body">From:</Typography>
                        <DatePicker
                            value={dateFrom}
                            onChange={(newDate) => {
                                setDateFrom(dayjs(newDate))
                            }} />
                        <Typography sx={{ ml: '16px', mr: '8px' }} variant="body">To:</Typography>
                        <DatePicker
                            value={dateTo}
                            readOnly={selectedTimeframeIndex != -1}
                            minDate={dateFrom}
                            onChange={(newDate) => {
                                setDateTo(dayjs(newDate))
                            }} />
                    </LocalizationProvider>
                </Box>
                }

            </div>
            <div className="students">
                <h3>Students</h3>
            </div>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                // defaultExpanded={["-Lua8pPKPGXpG2CFPIyM","-LuZv0pSN_PD-5sfcg6t","-LuZueH-DlLem_XqswE0"]}
                defaultExpanded={props.expandedGroupIDs}
                sx={{ height: 400, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
                onNodeToggle={(event, nodeIDs) => {
  
                    for (let key in nodeIDs) {
                        let id = nodeIDs[key]
                        let group = allGroups[id]
                        if (group && !group.subgroups) {
                            fetchSubgroups(group.id)
                        }
                    }
                }}>
                {
                    treeUpdated && allGroups && !isFetchingGroupsToExpand && renderTree(true, null)
                }
            </TreeView>
            <Box>
                <h3>Programs</h3>
                <Box sx={{ marginLeft: 2 }}>
                    <FormControl>
                        <RadioGroup
                            value={programsType}
                            onChange={event => setProgramsType(event.target.value)}>
                            <FormGroup>
                                <FormControlLabel value='all' control={<Radio />} label="All Programs" />
                                <FormControlLabel value='with_data' control={<Radio />} label="Only Programs with data from this time period" />
                                {/* <FormControlLabel value='per_student' control={<Radio />} label="Choose Programs per student" /> */}
                            </FormGroup>
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>

            {
                programsType == 'per_student' &&
                <Box sx={{ marginLeft: 2, height: '370px', width: '600px' }}>
                    <List sx={{ height: '100%', overflow: 'auto', mr: 2 }}
                    >
                        {studentsProgramData.map((student) => {
                            return <><ListItem>
                                <AccountCircle sx={{ mr: 2 }}></AccountCircle>
                                <ListItemText primary={student.displayName} />
                            </ListItem>
                                {student.functions.map((func) => {
                                    return <ListItem sx={{ ml: 4 }}>
                                        <Checkbox
                                            edge="start"
                                            tabIndex={-1}
                                        />
                                        <ListItemText primary={func.title} sx={{ ml: 0 }} />
                                    </ListItem>
                                })}
                            </>
                        })}
                    </List>
                </Box>
            }

            <Box sx={{ marginBottom: 4 }}>
                <h3>Data to Report per Program</h3>
                <h3>Written Components</h3>

                <Box sx={{ marginLeft: 2, height: '370px' }}>
                    <List sx={{ width: '600px', height: '100%', overflow: 'auto', mr: 2 }}
                    >
                        {writtenComponentOptions.map((option) => {
                            return <ListItem>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        onChange={event => {
                                            let key = option.key
                                            if (event.target.checked) {
                                                setSelectedWrittenComponents((prev) => ({
                                                    ...prev,
                                                    [key]: option
                                                }))
                                            } else {
                                                let options = selectedWrittenComponents
                                                delete options[key]
                                                setSelectedWrittenComponents((prev) => ({
                                                    ...options
                                                }))
                                            }
                                        }}
                                        checked={selectedWrittenComponents[option.key] != null}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.title} />
                            </ListItem>
                        })}
                    </List>
                </Box>
                <Box sx={{ mt: 5 }}>
                    <h3 >Data Summary</h3>
                </Box>
                <Box sx={{ marginLeft: 2 }}>
                    <h4>Summarize data averages by</h4>
                    <RadioGroup
                        value={getAveragePeriod()}
                        onChange={event => setUserDefinedAveragePeriod(event.target.value)}>
                        <FormGroup>
                            <FormControlLabel control={<Radio value='week' />} label="Week" />
                            <FormControlLabel control={<Radio value='month' />} label="Month" />
                        </FormGroup>
                        <h4>Responses</h4>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value='include_all_responses' checked={includeAllResponses} onChange={event => setIncludeAllResponses(event.target.checked)} />} label="Include all responses" />
                            <FormControlLabel control={<Checkbox value='include_ranges' checked={includeResponseRanges} onChange={event => setIncludeResponseRanges(event.target.checked)} />} label="Include ranges(highest and lowest responses during week/month)" />
                        </FormGroup>
                    </RadioGroup>
                    <h4>First/Last data points of time period</h4>
                    <FormGroup>

                        <Box sx={{ flexDirection: 'row' }}>

                            <Checkbox checked={isShowFirstPoints} onChange={event => setIsShowFirstPoint(event.target.checked)} />

                            Show first&nbsp;
                            <Select
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '200px'
                                        }
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={showFirstPointsCount}
                                label=""
                                disabled={!isShowFirstPoints}
                                onChange={(event) => setShowFirstPointsCount(event.target.value)} >
                                {
                                    selectFirstLastRange.map((value) => {
                                        return <MenuItem value={value}>{value}</MenuItem>
                                    })
                                }

                            </Select>
                            &nbsp;data points.

                        </Box>
                        {/* </FormControl> */}
                        <FormControlLabel
                            sx={{ marginLeft: 3 }}
                            control={
                                <Checkbox
                                    checked={isIncludeFirstPointsAverage}
                                    onChange={event => setIncludeFirstPointsAverage(event.target.checked)}
                                    disabled={!isShowFirstPoints} />
                            }
                            label="Include average" />
                        <Box sx={{ flexDirection: 'row' }}>

                            <Checkbox checked={isShowLastPoints} onChange={event => setIsShowLastPoint(event.target.checked)} />

                            Show last&nbsp;
                            <Select
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '200px'
                                        }
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={showLastPointsCount}
                                label=""
                                onChange={(event) => setShowLastPointsCount(event.target.value)}
                                disabled={!isShowLastPoints}
                            >

                                {
                                    selectFirstLastRange.map((value) => {
                                        return <MenuItem value={value}>{value}</MenuItem>
                                    })
                                }
                            </Select>
                            &nbsp;data points.

                        </Box>
                        <FormControlLabel
                            sx={{ marginLeft: 3 }}
                            control={
                                <Checkbox
                                checked={isIncludeLastPointsAverage}
                                    onChange={event => setIncludeLastPointsAverage(event.target.checked)}
                                    disabled={!isShowLastPoints} />
                            }
                            label="Include average" />
                    </FormGroup>

                    <h4>Include Graph(coming soon)</h4>
                    <FormGroup sx={{ ml: 2 }}>
                        <FormControlLabel control={<Radio disabled />} label="Thumbnail" />
                        <FormControlLabel control={<Radio disabled />} label="Full-sized" />
                    </FormGroup>

                    <h4>Include Data Summary page(coming soon)</h4>
                    <FormGroup sx={{ ml: 2 }}>
                        <FormControlLabel control={<Radio disabled />} label="Thumbnail" />
                        <FormControlLabel control={<Radio disabled />} label="Full-sized" />
                    </FormGroup>

                    <h3>Notes</h3>
                    <FormGroup sx={{ ml: 2 }}>
                        <FormControlLabel control={<Checkbox checked={includeGraphNotes} onChange={event => setIncludeGraphNotes(event.target.checked)} />} label="Include Graph notes" />
                        <FormControlLabel control={<Checkbox checked={includeDataSheetNotes} onChange={event => setIncludeDataSheetNotes(event.target.checked)} />} label="Include Data Sheet notes" />
                    </FormGroup>

                    <h3>Add Written Note to Report</h3>
                    <Checkbox checked={addNotePerStudent} onChange={event => setAddNotePerStudent(event.target.checked)} /> Add Note per Student
                    <Box sx={{ marginLeft: 2 }} id='written_note_students'>
                        <FormGroup>
                            {addNotePerStudent && selectedStudents && Object.keys(selectedStudents).map((key) => {
                                return <Box id={selectedStudents[key].id}>
                                    <Typography variant="body" component="h4" sx={{ mb: 2, mt: 3 }}>{selectedStudents[key].title} Note</Typography>
                                    <TextField
                                        id="filled-multiline-static"
                                        label="Note"
                                        multiline
                                        rows={6}
                                        value={studentNotes[selectedStudents[key].id] ?? ""}
                                        onChange={event => updateStudentNote(selectedStudents[key].id, event.target.value)}
                                        variant="filled"
                                        sx={{ minWidth: '600px' }}
                                    />
                                </Box>

                            })}
                        </FormGroup>
                        {addNotePerStudent && selectedStudents && Object.keys(selectedStudents).length > 0 && <Divider sx={{ mt: 4 }} />}
                        <Typography variant="body" component="h4" sx={{ mt: 3 }} style={{ display: 'inline-block' }}>All-Students Note</Typography>
                        <Typography variant="body"> (this note will appear in all student reports)</Typography>
                        <br />
                        <TextField
                            id="filled-multiline-static"
                            label="Note"
                            multiline
                            rows={6}
                            value={overallNote}
                            onChange={event => setOverallNote(event.target.value)}
                            variant="filled"
                            sx={{ minWidth: '600px', mt: 2, mb: 4 }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>

        <ReportSubheader canCreateReport={true} onCreateReport={() => doCreateReport()} />
        <LoadingInformationDialog open={isSubmitting || isSubmitted} show={isSubmitting || isSubmitted} completed={isSubmitted} />
        
    </Box>

}
