import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getDownloadURL, getStorage, ref, StorageReference, UploadResult, uploadString } from "firebase/storage";
import '../../utils/random_key';

let firebaseConfig = {}

console.log('staging ' + process.env.REACT_APP_IS_STAGING)
console.log('public url ' + process.env.REACT_APP_PUBLIC_URL)

if(process.env.REACT_APP_IS_STAGING) {
  // STAGING
  console.log('Environment: staging')
  firebaseConfig = {
    apiKey: "AIzaSyC2XT1Fk0Olk5Z1YlHpwWrnciTFZLM88xM",
    authDomain: "behaviorflowapp-staging.firebaseapp.com",
    databaseURL: "https://behaviorflowapp-staging.firebaseio.com",
    projectId: "behaviorflowapp-staging",
    storageBucket: "behaviorflowapp-staging.appspot.com",
    messagingSenderId: "742609199691",
    appId: "1:742609199691:web:cc0579a7939d6ae850e3ce",
    measurementId: "G-CP155DD9TV"
  };
} else {
  // PROD
  console.log('Environment: production')
  firebaseConfig = {
    apiKey: "AIzaSyAh89JbGNTA7YTT-TqJyH7eft0nqfROXeE",
    authDomain: "behaviorproapp-d8286.firebaseapp.com",
    databaseURL: "https://behaviorproapp-d8286.firebaseio.com",
    projectId: "behaviorproapp-d8286",
    storageBucket: "behaviorproapp-d8286.appspot.com",
    messagingSenderId: "595204407405",
    appId: "1:595204407405:web:fdd338ed50bbbe44a9ff64",
    measurementId: "G-HZFHGMNTZD"
  };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

// Auth

const signIn = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
}

const logout = () => {
  return signOut(auth);
};


const getCurrentUser = () => {
  return auth.currentUser
}

// Storage

const uploadBase64Image = (base64: string, path: string): Promise<UploadResult> => {
  let signatureRef = ref(storage, path)
  return uploadString(signatureRef, base64, 'data_url')
}

const getStorageURL = async (path: string): Promise<string> => {
  return getDownloadURL(ref(storage, path))
}

export interface FirebaseEntry {
  key: string
  value: any
}

const functions = getFunctions()

// connectFunctionsEmulator(functions, "localhost", 5001)

export {
  app,
  auth,
  signIn,
  logout,
  functions,
  getCurrentUser,
  uploadBase64Image,
  getStorageURL
};

