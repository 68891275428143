import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { EditProgramMode } from "./edit_program";
import InputDialog from "../../components/dialogs/input_dialog";

interface SignatureFieldProps {
    id: string,
    title: string
    editMode: boolean
    email?: string
    annual?: boolean
    type: string
    readOnly?: boolean
    onStateChanged?: (text: string, type: string, email: string, annual: boolean) => void
    onRemoveClicked?: () => void
}

interface SignatureFieldState {
    title: string
    type: string
    showsEmailField: boolean
    email: string
    annual: boolean
}

export default function SignatureField(props: SignatureFieldProps) {
    const [values, setValues] = useState<SignatureFieldState>({ title: "", type: 'regular', showsEmailField: false, email: "", annual: false })
    const [readOnly, setReadOnly] = useState<boolean>(props.readOnly ?? false)
    const [isRemoving, setIsRemoving] = useState<boolean>(false)
    useEffect(() => {
        var state: SignatureFieldState = { title: props.title, type: props.type ?? 'regular', showsEmailField: !props.editMode, email: props.email ?? "", annual: props.annual ?? false }
        setValues(state)
    }, []);


    function onTypeChanged(type: string) {
        setValues({ ...values, type: type })

        let email: string = type == 'regular' ? '' : values.email
        props.onStateChanged?.(values.title, type, email, values.annual)
    }

    function onAnnualCheckboxChange(checked: boolean) {
        props.onStateChanged?.(values.title, values.type, values.email, checked)
        setValues({
            ...values,
            annual: checked
        })
    }

    function onTitleChange(text: string) {
        props.onStateChanged?.(text, values.type, values.email, values.annual)

        setValues({
            ...values,
            title: text
        })
    }

    function onEmailChange(text: string) {
        props.onStateChanged?.(values.title, values.type, text, values.annual)
        setValues({
            ...values,
            email: text
        })
    }

    function onRemoveConfirmation(confirmed: boolean) {
        setIsRemoving(false)
        if(confirmed) {
            props.onRemoveClicked?.()
        }
    }

    return (
        <div className="round-border" style={{ marginBottom: '8px' }}>
            <Box
                m={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}>
                    <Typography sx={{ mb: 2 }} variant="h5" component="div" mb={1}>
                        Signature
                    </Typography>

                    <TextField sx={{ width: "300px" }}
                        key={"signature_" + props.id}
                        value={values.title}
                        disabled={readOnly}
                        onChange={(event) => onTitleChange(event.target.value)}
                        label="Signature Title"
                        autoComplete="off"
                        placeholder="e.g. Parent signature" />

                    {/* <Box
                        p={1}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}> */}
                        <FormControlLabel
                            key="parent_signature_form_label_annual"
                            label="Annual"

                            control={<Checkbox key="annual_signature_checkbox"
                                disabled={readOnly}
                                value={values.annual}
                                checked={values.annual}
                                onChange={(event) => onAnnualCheckboxChange(event.target.checked)} />} />
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                defaultValue="regular"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel disabled={readOnly} value="regular" control={<Radio checked={values.type == 'regular'} onClick={(event) => onTypeChanged('regular')} />} label="Regular" />
                                <FormControlLabel disabled={readOnly} value="parent" control={<Radio checked={values.type == 'parent'} />} onClick={(event) => onTypeChanged('parent')} label="Parent/Guardian" />
                                <FormControlLabel disabled={readOnly} value="external" control={<Radio checked={values.type == 'external'} />} onClick={(event) => onTypeChanged('external')} label="External Evaluator" />
                            </RadioGroup>
                        </FormControl>
                    {/* </Box> */}
                    {/* <FormControlLabel
                        key="parent_signature_form_label"
                        label="Parent/Guardian Signature"

                        control={<Checkbox key="parent_signature_checkbox"
                            disabled={readOnly}
                            value={values.isParentSignature}
                            checked={values.isParentSignature}
                            onChange={(event) => onParentCheckboxChange(event.target.checked)} />} /> */}



                    { values.type != 'regular' &&
                        <div>
                            <br />
                            Enter an email of a Parent/Guardian that you would like to assign this signature to.
                            <TextField disabled={readOnly} sx={{ marginTop: '16px', width: "250px", marginBottom: '16px' }} key={"email_" + props.id} onChange={(event) => onEmailChange(event.target.value)} value={values.email} label="Email" placeholder="Email Address" />
                        </div>
                    }
                </Box>
                {!readOnly &&
                    <Button sx={{ marginLeft: 'auto', marginRight: '16px' }} variant="outlined" startIcon={<DeleteIcon />} color='error' onClick={() => setIsRemoving(true)}>
                        Remove
                    </Button>
                }

                <Dialog open={isRemoving} >
                    <DialogTitle>
                        Careful...
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove this signature?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ mt: 0 }}>
                        <Button id="button_disagree" onClick={() => onRemoveConfirmation(false)}>Cancel</Button>
                        <Button id="button_agree" sx={{ color: 'red' }} onClick={() => onRemoveConfirmation(true)}>Remove</Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </div>
    );
};