import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography } from '@mui/material';

export default function AddProgramFieldPlus(props) {
    return (
        <Box onClick={() => props.onClick?.()} 
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer'
                    }}>   
            <AddCircleOutlineIcon sx={{ marginRight: '4px' }}/>
            <Typography variant="h6" component="div">Add Signature Field</Typography>
        </Box>
    );
}