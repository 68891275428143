import { Button, Box, CircularProgress, ListSubheader, Divider, List, ListItem, ListItemButton, ListItemText, ListItemIcon, ToggleButton, Checkbox, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TreeItem from '@mui/lab/TreeItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { fetchReports, fetchStudentPrograms, fetchRecentReports, fetchGroups, fetchAllReports, fetchStudentFunctions, createReport } from "./reports.api";
import dayjs from 'dayjs';
import { defaultWrittenComponentOptions } from "./report_written_components";
import { showError } from "../../components/toasts";
import { isEditingSavedReportState, reportExpandedIDsState, selectedReportState } from "../../recoil/atom/reports-state";
import { useRecoilState } from "recoil";

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const dateFormat = 'MM/DD/YYYY'

export default function Reports(props) {

    // state
    const [savedReports, setSavedReports] = useState(null)
    const [recentReports, setRecentReports] = useState(null)

    const [isLoadingReports, setLoadingReports] = useState(true)

    const writtenComponentOptions = defaultWrittenComponentOptions;
    const [isEditingSavedReport, setEditingSavedReport] = useRecoilState(isEditingSavedReportState)
    const [_, setSelectedReport] = useRecoilState(selectedReportState)
    const [reportExpandedIDs, setReportExpandedIDs] = useRecoilState(reportExpandedIDsState)

    useEffect(() => {

        fetchAllReports().then((result) => {
            setLoadingReports(false)
            setSavedReports(result.saved ?? [])
            setRecentReports(result.recent ?? [])
        })

        setEditingSavedReport(false)
        setSelectedReport(null)
        setReportExpandedIDs([])
    }, [])

    function onSelectReport(report, isSaved) {
        setEditingSavedReport(isSaved)
        setSelectedReport(report)
        setReportExpandedIDs(report.groupsToExpand || [])
    }

    function renderReports(list, isSaved) {
        return list.map((report) => {
            return <ListItemButton onClick={(event) => onSelectReport(report, isSaved)} component={Link} to="/dashboard/report">
                <ListItemAvatar>
                    <Avatar>
                        <AssessmentIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={report.title}
                    secondary={report.timeString}
                />
            </ListItemButton>
        })
    }

    return <Box sx={{ marginLeft: 1, userSelect: 'none', width: '100%' }}>
        <Box sx={{ marginRight: '32px', mt: '8px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <Typography variant="body" component="h1" sx={{ mt: 3, flex: 1 }} style={{ display: 'inline-block' }}>Reporting</Typography>
            <Button sx={{ ml: 'auto' }} variant="contained" component={Link} to="/dashboard/report">Create New Report</Button>
        </Box>

        {isLoadingReports ? <CircularProgress /> :
            <Box sx={{ marginLeft: 3 }}>
                <div className="recent_and_saved">
                    <h3>Recent & Saved Report Templates</h3>
                    <Box sx={{ height: '370px', display: 'flex', flexDirection: 'row' }}>

                        {recentReports &&
                            <List sx={{ width: '300px', height: '100%', overflow: 'auto', mr: 2 }}
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Recently Used
                                    </ListSubheader>
                                }>
                                {renderReports(recentReports, false)}
                            </List>
                        }

                        {savedReports &&
                            <List sx={{ width: '330px', height: '100%', overflow: 'auto' }}
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Saved
                                    </ListSubheader>
                                }>
                                {renderReports(savedReports, true)}
                            </List>
                        }
                    </Box>
                </div>
            </Box>
        }
    </Box>

}