import { AppBar, Box, Button, createTheme, Divider, IconButton, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sideMenuWidth } from "../sidemenu/side_menu";

function ReportSubheader(props) {
    
    return (
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: `${sideMenuWidth}px`,
                zIndex: 1,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1
            }}>
            <IconButton component={Link} to="/dashboard/reports">
                    <ArrowBackIcon sx={{ ml: 2, mr: 2, color: '#3c7ebf' }} />
                </IconButton>
            <Box flexGrow={1} sx={{ marginRight: '32px', display: 'flex', alignItems: 'center', justifyContent:'end'}} >
                <Button hidden={!props.showCreateReportButton} disabled={!props.canCreateReport} sx={{mt: '12px', mb: '12px' }} variant="contained" onClick={() => props.onCreateReport?.()}>Create Report</Button>
            </Box>
        </Box>
    );
};

export default ReportSubheader;