import { httpsCallable } from "firebase/functions";
import { functions } from "../components/firebase/firebase";
import { SimpleResponse } from '../model/api-model';

export const createStandaloneProgram = httpsCallable<unknown, SimpleResponse>(functions, 'functions-createStandaloneProgram');
export const updateStandaloneProgram = httpsCallable(functions, 'functions-updateStandaloneProgram');
export const archiveFunction = httpsCallable(functions, 'functions-archive_function');
export const fetchFunctionData = httpsCallable(functions, 'functions-fetchFunctionData');

export const fetchAllStudentFunctions = httpsCallable(functions, 'functions-fetchAllStudentFunctions');

export const signSignature = httpsCallable(functions, 'functions-signSignature');

