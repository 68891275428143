import React, { useEffect, useState } from 'react';
import data from '../../model.json';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../assets/css/edit_program.css'
import StudentList, { UserInGroupParameter } from '../choose_student_list';
import { EditProgramForm, EditProgramMode } from './edit_program';
import { ProgramLibraryItem, TextualProgram } from '../program_library'
import { createStandaloneProgram } from '../../api/functions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showError, showSuccess } from '../../components/toasts';
import { printObject } from '../../utils/print_object';
import { useRecoilState } from 'recoil';
import { doCustomizeProgramForStudentAtom, editedProgramAtom, selectedCustomizeStudentAtom, selectedProgramAtom } from '../../recoil/atom/program_library_atom';
import { jsonCopyObject } from '../../utils/json_copy';
import { updatingFunctionAtom } from '../../recoil/atom/function_atom';

interface CustomizeProgramProps {
    onCompleted: () => void
}

export default function CustomizeProgram({ onCompleted }: CustomizeProgramProps) {
    const [selectedStudent, setSelectedStudent] = useState<[name: string, parameter: UserInGroupParameter] | null>(null);
    const [customizeSelectedStudent, setCustomizeSelectedStudent] = useRecoilState<any>(selectedCustomizeStudentAtom)
    const [doCustomizeProgram, setDoCustomizeProgram] = useRecoilState<any>(doCustomizeProgramForStudentAtom)
    const [updatingFunction, setUpdatingFunction] = useRecoilState<any>(updatingFunctionAtom)
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [program, setProgram] = useRecoilState<any>(selectedProgramAtom)
    const [editedProgram, setEditedProgram] = useRecoilState<any>(editedProgramAtom)

    useEffect(() => {
        setCustomizeSelectedStudent(selectedStudent)
    }, [selectedStudent])

    useEffect(() => {
        if (doCustomizeProgram) {
            submitProgram()
            setDoCustomizeProgram(false)
        }
    }, [doCustomizeProgram])

    function submitProgram() {
        if (!selectedStudent) {
            showError('You must select a student')
            return
        }

        if (editedProgram == null) {
            return
        }

        for (let key in editedProgram.textItems) {
            console.log("key " + key)
        }

        let program = jsonCopyObject(editedProgram);

        // validate program_name
        let name = program.textItems.program_name?.value.trim()

        if (!name) {
            name = program.textItems.target_response?.value.trim() ?? program.textItems.program_full_title?.value.trim()
        }

        console.log(" name is " + program.textItems.program_full_title?.value.trim())

        if (!name || name.length < 3) {
            showError("Program name must be at least 3 characters long.")
            return
        } else {
            program.title = name
        }

        const data = {
            program: program,
            student: selectedStudent[1]
        }
        setSubmitting(true)
        setUpdatingFunction(true)
        console.log('name ' + program.title)
        printObject(program, 'edited program')
        createStandaloneProgram(data).then((response) => {
            setSubmitting(false)

            if (response.data.error) {
                showError(response.data.error)
            } else {
                showSuccess('Program customized for ' + selectedStudent[0] + '.')
                onCompleted()
            }
        }).catch((err) => {
            
            showError(err.message)
        }).finally(() => {
            setSubmitting(false)
            setUpdatingFunction(false)
        })
    }

    return (
        <Box
            sx={{
                marginTop: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <ToastContainer />

            <StudentList onStudentSelected={(name: string, student: UserInGroupParameter) => setSelectedStudent([name, student])} />
            {program &&
                <EditProgramForm
                    isEmbedded={false}
                    item={program}
                    mode={EditProgramMode.Customize}
                    loading={submitting}
                />
            }
            <br />
        </Box>

    );
}

