import { Button, CircularProgress, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, TextField, Typography } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import PanToolIcon from '@mui/icons-material/PanTool';
import Signature from "../../components/signature/signature";
import { Box } from "@mui/system"
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { GroupRole } from "../../model/api-model";
import { uploadSignatureToStorage } from "../../api/functions/api_standalone_program";
import { signAndApplyDiscontinuationRationale } from "../../api/functions/api_standalone_program";
import { getCurrentUser } from "../../components/firebase/firebase";
import SignatureField from "./signature_field";
import { showError, showSuccess } from "../../components/toasts";
import { jsonCopyObject } from "../../utils/json_copy";
import { generateRandomKey } from "../../utils/random_key";

export default function DiscontinuationRationale(props) {
    const [items, setItems] = useState(null)
    // const [itemText, setItemText] = useState({})
    const [signature, setSignature] = useState(null)
    const [signatureRequest, setSignatureRequest] = useState(null)

    const [alreadySigned, setAlreadySigned] = useState(false)

    const [signing, setSigning] = useState(false)
    const [canSign, setCanSign] = useState(props.canSign ?? false)
    // const [signedItemKey, setSignedItemKey] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState({})
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [isCanvasSigned, setCanvasSigned] = useState(false)
    const [canvasBase64, setCanvasBase64] = useState(null)
    const [signAttempt, setSignAttempt] = useState(0)
    const [submitting, setSubmitting] = useState(false)

    useState(() => {
        resetState()
    }, [props.dratModel]);

    useEffect(() => {
        resetState()
    }, [props.updateRequest])

    function resetState() {
        let itemsArr = []
        for (let key in props.dratModel?.items) {
            let item = props.dratModel?.items?.[key]
            if (item) {
                item.key = key
                itemsArr.push(item)
            }
        }
        let sortedItems = itemsArr.sort((a, b) => a.index > b.index ? 1 : -1)
        setItems(sortedItems)

        let signatureItem = props.dratModel?.signature
        setSignature(signatureItem)
        setSignatureRequest(generateRandomKey(8))

        const userProfile = props.userProfile
        if (userProfile) {
            // Temp for testing - set currently logged-in user role to Teacher
            // userProfile.role = GroupRole.Teacher

            let alreadySigned = props.dratModel?.signature?.signature != null
            setAlreadySigned(alreadySigned)

            let canSign = !alreadySigned &&
                userProfile.role &&
                (userProfile.role == GroupRole.Principal || userProfile.role == GroupRole.AdminTeacher || userProfile.role == GroupRole.Teacher)
            setCanSign(canSign)
        }

        if (props.dratModel?.state?.selectedOptions) {
            setSelectedOptions(props.dratModel.state.selectedOptions)
            // setSignedItemKey(props.dratModel.state.selectedOptionID)
            // updateTextValue(props.dratModel.state.text ?? "", props.dratModel.state.selectedOptionID)
        }

    }

    function onSignAgree() {
        setShowConfirmationDialog(false)

        if (!isCanvasSigned) {
            showError("Signature canvas is empty.")
            return;
        }

        let checkedSelectedOptions = {}
        for(let key in selectedOptions) {
            let option = selectedOptions[key]
            if(option.selected ?? false) {
                checkedSelectedOptions[key] = option
            }
        }

        if(Object.keys(checkedSelectedOptions).length == 0) {
            showError("You must choose a reason for discontinuation.")
            return
        }

        doSign()
    }

    function onSign() {
        setSignAttempt(signAttempt + 1)
        setShowConfirmationDialog(true)
    }

    async function doSign() {
        if (!canvasBase64 || !props.dratModel?.key) {
            return;
        }

        let groupID = props.groupID
        let functionID = props.functionID
        let studentID = props.studentID

        console.log("group " + groupID + " function " + functionID + " studentID " + studentID + " dratID " + props.dratModel.key)

        if (!groupID || !functionID || !studentID) {
            // show error
            return
        }

        let checkedSelectedOptions = {}
        for(let key in selectedOptions) {
            let option = selectedOptions[key]
            if(option.selected ?? false) {
                checkedSelectedOptions[key] = option
            }
        }

        if(Object.keys(checkedSelectedOptions).length == 0) {
            showError("You must choose a reason for discontinuation.")
            return
        }

        console.log("submitting...")
        setSubmitting(true)

        let uploadResult = await uploadSignatureToStorage(canvasBase64, getCurrentUser().uid)

        let signatureURL = uploadResult?.metadata?.fullPath

        if (!signatureURL) {
            showError("Failed to upload signature.")
            setSubmitting(false)
            return;
        }

        console.log("Uploaded with signature " + signatureURL)
        // let selectedOptionText = itemText[selectedItemKey] || null

        signAndApplyDiscontinuationRationale({
            groupID: groupID,
            studentID: studentID,
            functionID: functionID,
            signatureURL: signatureURL,
            dratID: props.dratModel.key,
            dratSelectedOptions: checkedSelectedOptions
            // dratSelectedOptionID: selectedItemKey,
            // dratSelectedOptionText: selectedOptionText
        }).catch((err) => {
            showError(err)
            setSubmitting(false)
        }).then((response) => {
            if (response.data?.error) {
                showError(response.data.error)
            } else {
                setSigning(false)
                showSuccess("Discontinuation rationale applied successfully.")

                if (props.reloadFunctionData) {
                    props.reloadFunctionData?.()
                }
            }
            setSubmitting(false)
        })
    }

    function cancelSigning() {
        setSigning(false)
    }

    function onCanvasStateChanged(canvas, isSigned) {
        let base64 = canvas?.toDataURL() ?? null

        if (!isSigned) {
            base64 = null
        }

        setCanvasBase64(base64)
        setCanvasSigned(isSigned)
    }

    function updateTextValue(text, key) {
        let options = jsonCopyObject(selectedOptions)
        options[key].text = text
        setSelectedOptions(options)
    }

    function renderSignButtons() {
        if (submitting) {
            return <></>
        } else if (signing) {
            return <div className="fullWidth">

                <Box
                    m={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                    <Button sx={{ margin: '16px', height: '44px' }} variant="outlined" startIcon={<CreateIcon />} color='success' onClick={() => onSign()}>
                        Sign
                    </Button>
                    <Button sx={{ margin: '16px', height: '44px' }} variant="outlined" startIcon={<CancelIcon />} color='error' onClick={() => cancelSigning()}>
                        Cancel Signing
                    </Button>
                </Box>
            </div >
        } else if (canSign && !(props.editMode ?? false)) {
            return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button sx={{ marginLeft: '4px', marginTop: '8px', marginBottom: '16px', height: '44px' }} variant="contained" startIcon={<PanToolIcon />} color='primary' onClick={() => setSigning(true)}>
                    Sign and apply discontinuation
                </Button>
            </Box>
        } else {
            return <></>
        }
    }

    return (
        <Box
            mt={2}
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            }}>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                <Typography sx={{ mt: '8px', ml: 2, mb: 3 }} variant="h4" component="h4" >Discontinuation Rationale Signature</Typography>
                {(props.editMode ?? false) && !alreadySigned &&
                    <Button sx={{ marginLeft: '16px', marginRight: '16px', height: '44px' }} variant="outlined" startIcon={<DeleteIcon />} color='error' onClick={() => props.onRemoveClicked?.(props.dratModel.key)}>
                        Remove
                    </Button>
                }

            </Box>

            { canSign && 
            <Typography sx={{ ml: 4, mb: 1, color: "#5A5A5A" }} variant="h5" component="h6" >Choose all that apply:</Typography> }
            

            {items && items.map((item) => {
                return <Box sx={{
                    mb: 1,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}

                    key={item.key}>

                    <FormControlLabel
                        key={item.key}
                        label={item.text}
                        multiline
                        control={<Checkbox
                            disabled={!signing}
                            checked={selectedOptions[item.key]?.selected === true }
                            onChange={(event) => {
                                let options = jsonCopyObject(selectedOptions)
                                let existingObject = options[item.key]
                                if(!existingObject) {
                                    existingObject = {
                                        key: item.key,
                                        selected: true
                                    }
                                }

                                existingObject.selected = event.target.checked
                                
                                options[item.key] = existingObject
                                setSelectedOptions(options)
                            }} />}
                    />
                    {item.fieldType &&
                        <>
                            {item.fieldType === "multiline" &&
                                <br />
                            }
                            <TextField
                                key={"field_" + item.key}
                                multiline={item.fieldType !== "short"}
                                rows={item.fieldType === "short" ? 1 : 5}
                                disabled={!(selectedOptions[item.key]?.selected ?? false) || !signing}
                                value={selectedOptions[item.key]?.text ?? ""}
                                onChange={(event) => updateTextValue(event.target.value, item.key)}
                                sx={{ minWidth: item.fieldType === "multiline" ? 500 : 200 }} />
                        </>
                    }
                </Box>
            })
            }

            {signature &&
                <Box m={1} key="signature_wrapper">
                    <Signature updateRequest={signatureRequest} key={"drat_signature"} signAttempts={signAttempt} onCanvasStateChanged={(canvas, isSigned) => onCanvasStateChanged(canvas, isSigned)} isSigning={signing} showCancelButton={false} showSignSignatureButton={false} showSignButton={false} model={signature} {...props} />
                </Box>
            }

            {renderSignButtons()}

            {submitting && <CircularProgress className="spinner" />}

            <Dialog
                open={showConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Discontinuation rationale cannot be edited after it has been signed. Are you sure you want to submit the discontinuation rationale?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="alert_button_no" onClick={() => setShowConfirmationDialog(false)}>No</Button>
                    <Button id="alert_button_yes" onClick={() => onSignAgree()}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

}