import React, { useEffect, useState } from "react";
import { Box, Button, Card, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, InputLabel, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { sideMenuWidth } from "../../components/sidemenu/side_menu";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import 'firebase/storage';
import { showError, showSuccess } from "../../components/toasts";
import { editingProgramAtom, isCustomizingProgramForStudentAtom } from '../../recoil/atom/program_library_atom';
import { useRecoilState } from "recoil";
import { printObject } from "../../utils/print_object";
import { fetchSignaturePrograms } from "./signatures-api";
import StandaloneProgram from "../functions/standalone_program";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    text: {
        color: "black"
    }
});

export default function SignatureList(props) {

    const [programs, setPrograms] = useState(null)
    const [selectedProgramIndex, setSelectedProgramIndex] = useState(null)
    const [selectedProgram, setSelectedProgram] = useRecoilState(editingProgramAtom)

    const handleListItemClick = (event, index) => {
        window.scrollTo({ top: 0, left: 0})
        selectIndex(index)
    };

    useEffect(() => {
        setSelectedProgram(null)
        fetchUserSignatures()
    }, [])

    useEffect(() => {
        if (programs && selectedProgramIndex < programs.length && selectedProgramIndex >= 0 && programs[selectedProgramIndex]?.function) {
            setSelectedProgram(programs[selectedProgramIndex].function)
            printObject(programs[selectedProgramIndex], 'selected prog')
        } else {
            setSelectedProgram(null)
        }
    }, [selectedProgramIndex])

    async function fetchUserSignatures() {
        let result = await fetchSignaturePrograms()
        if (result.error) {
            showError(result.error)
        } else if (result.programs) {
            setPrograms(result.programs)
        }
    }

    function selectIndex(index) {
        setSelectedProgramIndex(index)
    }

    const classes = useStyles()

    return <Box>
        {/* <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: '240px',
                zIndex: 1,
                width: 'calc(100% - 240px)',
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1
            }}>
        </Box> */}
        
        <Box sx={{ position: 'fixed', top: '55px', height: `calc(100% - 55px)`, left: `${sideMenuWidth}px`, borderRight: `1px solid rgb(224, 224, 224)`, }}>
            <List sx={{ width: '300px', height: '100%', overflow: 'auto' }}>
                { !programs && <ListItemIcon sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                        <CircularProgress /> 
                    </ListItemIcon>}
                {
                    programs && programs.map((program, index) => {
                        return <>
                            <ListItemButton sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}

                                key={program.key}
                                selected={selectedProgramIndex === index}
                                onClick={(event) => handleListItemClick(event, index)}>
                                <ListItemText sx={{ display: 'inline' }} primary={program.title} />
                                <ListItemText classes={{ primary: classes.text }} sx={{ display: 'inline' }} primary={program.subtitle} />

                            </ListItemButton>
                            <Divider  component="li" sx={{ color: 'black' }} />
                        </>

                    })
                }
            </List>
        </Box>

        <Box sx={{ ml: '316px', mr: '16px', height: '100%' }}>
            {selectedProgram && <StandaloneProgram
                {...props}
                functionData={selectedProgram}
                functionID={selectedProgram.key}
            />
            }
        </Box>
    </Box>
}