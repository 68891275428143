import { atom } from "recoil";

export const selectedReportState = atom({
  key: "selectedReport",
  default: null,
});

export const isEditingSavedReportState = atom({
  key: "isEditingSavedReport",
  default: false,
});

export const createdReportDownloadURLState = atom({
    key: "createdReportDownloadURL",
    default: null,
  });

export const createdReportSettingsState = atom({
    key: "createdReportSettings",
    default: null,
});
  
export const reportExpandedIDsState = atom({
  key: "reportExpandedIDs",
  default: null,
});