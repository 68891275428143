import { Button, Box, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchGroupDescriptors } from "../../api/my_groups";
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import { fetchAllStudentFunctions } from "../../api/functions";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FunctionContainer from "../functions/function_container";
import MyGroupsSubheader from "../../components/header/my_groups_subheader";
import { fetchUserInGroup } from "../../api/user";
import { GroupRole } from "../../model/api-model";
import FunctionSubheader from "../../components/header/function_subheader";
import { printObject } from "../../utils/print_object";
import { StandaloneProgramState } from "../../model/api-model"
import { useRecoilState } from "recoil";
import { isEditingFunctionAtom, reloadedFunctionDataAtom } from "../../recoil/atom/function_atom";
import { Label } from "@mui/icons-material";

export default function MyGroups(props) {

    const [loading, setLoading] = useState(true)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [groups, setGroups] = useState(null)
    const [rootGroups, setRootGroups] = useState(null)
    const [allGroups, setAllGroups] = useState(null)
    const [editMode, setEditMode] = useState(null)
    const [selectedStudent, setSelectedStudent] = useState(null)
    const [studentFunctions, setStudentFunctions] = useState(null)
    const [title, setTitle] = useState(null)
    const [titleColor, setTitleColor] = useState(null)
    const [selectedFunction, setSelectedFunction] = useState(null)
    const [isEditingFunctionInstance, setEditingFunctionInstance] = useRecoilState(isEditingFunctionAtom)
    const [reloadedFunctionData, setReloadedFunctionData] = useRecoilState(reloadedFunctionDataAtom)

    useEffect(() => {
        reloadAllGroups()
    }, [])

    useEffect(() => {
        if(reloadedFunctionData) {
            printObject(reloadedFunctionData, 'reloaded f data my groups')
            setTitle(functionTitle(reloadedFunctionData))
            setTitleColor(functionTitleColor(reloadedFunctionData))
        }
    }, [reloadedFunctionData])
    
    useEffect(() => {
        if(selectedFunction) {
            if(isEditingFunctionInstance) {
                setEditMode('editing')
            } else {
                setEditMode('canEdit')        
            }
        } else {
            setEditMode(null)
        }
        setTitle(functionTitle(selectedFunction))
    }, [selectedFunction, isEditingFunctionInstance]);

    
    function parseGroups(groups) {
        let groupsArr = []
        let dict = allGroups ?? {}
        for (let key in groups) {
            let group = groups[key]

            let students = []
            if (group.users) {
                for (let userKey in group.users) {
                    let user = group.users[userKey]
                    if (user.role == 7) {
                        user.key = userKey
                        user.fullname = user.name + " " + (user.lastName ?? "")
                        students.push(user)
                    }
                }
            }

            group.students = students.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)

            group.key = key
            groupsArr.push(group)
            dict[key] = group
        }

        setAllGroups(dict)

        return groupsArr
    }

    function reloadAllGroups() {
        fetchGroupDescriptors().then((response) => {
            let jsonString = JSON.stringify(response.data, null, 4);
            console.log("my groups: " + jsonString)
            let groups = parseGroups(response.data)
            setGroups(groups)
            setRootGroups(groups)

            setLoading(false)
        });
    }

    function fetchSubgroupsIfNeeded(group) {
        if (!group || group.subgroups) {
            // group not selected or selected but already fetched subgroups
            return
        }

        setLoading(true)
        console.log("Fetch subgroups for " + group.key)
        fetchGroupDescriptors({ parentGroupID: group.key }).then((response) => {
            let groups = parseGroups(response.data)
            group.subgroups = groups
            let jsonString = JSON.stringify(response.data, null, 4);
            console.log("fetched subgroups " + jsonString)
            setGroups(groups)
            setLoading(false)
        })
    }

    function fetchStudentFunctions(student) {
        if (selectedGroup?.key && student.key) {
            setLoading(true)
            return fetchAllStudentFunctions({ groupID: selectedGroup.key, studentID: student.key }).then((response) => {
                if (response?.data?.error) {
                    console.log("error " + response.data.error)
                }

                let jsonString = JSON.stringify(response.data, null, 4);
                // console.log("fetched functions " + jsonString)

                var sections = { 
                    home: { 
                        title: "Home", 
                        programs: [] 
                    }, 
                    instructional: {
                        title: "Instructional",
                        programs: []
                    }, treatment: {
                        title: "Decreasing Problem Behavior",
                        programs: []
                    }, other: {
                        title: 'Other',
                        programs: []
                    }
                }
                for (let key in response.data) {
                    let f = response.data[key]
                    f.key = key

                    let status = f.status ?? 1
                    if(status != 1) {
                        continue;
                    }

                    if (f.program && f.program?.textItems) {
                    // if (f.program) {
                        printObject(f.program, "Program")
                        if(isHomeFunction(f)) {
                            sections.home.programs.push(f)
                            sortSections(sections.home.programs)
                        } else if(isInstructionalFunction(f)) {
                            sections.instructional.programs.push(f)
                            sortSections(sections.instructional.programs)
                        } else if(isTreatmentFunction(f)) {
                            sections.treatment.programs.push(f)
                            sortSections(sections.treatment.programs)
                        } else {
                            printObject(f, "ff")
                            sections.other.programs.push(f)
                            sortSections(sections.other.programs)
                        }

                        console.log(`${f.key} - ${f.programState}`)
                    }
                }

                setStudentFunctions(sections)
                setLoading(false)
            })
        } else {
            return null
        }
    }

    function sortSections(arr) {
        arr.sort((a,b) => { return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1 })
    }

    function isInstructionalFunction(func) {
        let category = func.program?.category
        if(category) {
            return category === 'instruction'
        }

        let type = func.program?.textItems?.['program_type']?.value
        if(type) {
            return type.toLowerCase().includes('instruction')
        }
        return false
    }

    function isTreatmentFunction(func) {
        let category = func.program?.category
        if(category) {
            return category === 'treatment'
        }

        let type = func.program?.textItems?.['program_type']?.value
        if(type) {
            return type.toLowerCase().includes('treatment')
        }
        return false
    }

    function isHomeFunction(func) {
        return func.program?.category === 'home'
    }

    function renderFolderPathButton(group, key, showNextSymbol) {
        let folderName = group?.name ?? "All Groups"
        return (
            <Button
                key={"path_button_" + key}
                onClick={() => onSelectGroup(key)}
                variant="text"> {folderName + (showNextSymbol ? ' >' : '')}
            </Button>
        );
    }

    function onNavigateStudent() {
        setSelectedFunction(null)
        setEditingFunctionInstance(false)
        setStudentFunctions(null)
        if (selectedStudent) {
            fetchStudentFunctions(selectedStudent)
        }
    }

    function onSelectStudent(student) {
        printObject(student, 'student')
        setSelectedStudent(student)
        fetchStudentFunctions(student)
    }

    function onSelectStudentFunction(studentFunction) {
        setSelectedFunction(studentFunction)
    }

    function onSelectGroup(key) {

        if (!key) {
            setSelectedGroup(null)
            setGroups(rootGroups)
        } else {
            let group = allGroups[key]
            setSelectedGroup(group)
            if (group.subgroups) {
                setGroups(group.subgroups)
            } else {
                fetchSubgroupsIfNeeded(group)
            }
        }
        setEditingFunctionInstance(false)
        setSelectedStudent(null)
        setStudentFunctions(null)
        setSelectedFunction(null)
    }

    function functionTitle(f) {
        if(!f) {
            return ""
        } 
        
        const state = f.program?.programState
        if(state === StandaloneProgramState.Discontinued) {
            return f.title + " - Discontinued" 
        } else if (state === StandaloneProgramState.TemporarilyDiscontinued) {
            return f.title + " - Temporarily Discontinued" 
        } else {
            return f.title
        }
    }

    function functionTitleColor(f) {
        if(!f) {
            console.log('returning null')
            return null
        } 
        
        const state = f.program?.programState
        if(state === StandaloneProgramState.Discontinued || state === StandaloneProgramState.TemporarilyDiscontinued) {
            return '#5A5A5A'
        } else {
            return '#3c7ebf'
        } 
    }

    function renderFunctionSectionList(sectionName) {
        let section = studentFunctions?.[sectionName]
        let programs = section?.programs

        if(!programs || programs.length == 0) {
            return <></>
        }

        let sectionTitle = section.title ?? sectionName
        return <>
            <Box sx={{ml: 3, mt: 3, mb: 1, fontSize: 'large'}}>
                {sectionTitle}
            </Box>
             {
            programs.map((studentFunction) => {
            return <ListItem key={studentFunction.key}>
                <ListItemButton onClick={() => onSelectStudentFunction(studentFunction)}>
                    <ListItemIcon sx={{ mr: '16px', minWidth: 0}}>
                        <ArticleOutlinedIcon sx={{ color: ( (studentFunction.program?.programState ?? 0) === 0) ?'#48a5d3' : "#DADADA"}} />
                    </ListItemIcon>
                    <ListItemText sx={{ ml: 0, opacity: ((studentFunction.program?.programState ?? 0) === 0) ? 1 : 0.5  }}>{studentFunction.title}</ListItemText>
                </ListItemButton>
            </ListItem>
            })
        }
        </>
    }

    return (
        <div>
            <Box sx={{ mt: '80px', width: '100%'}}>
                {loading && 
                <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <CircularProgress />
                </Box>
                }
                
                { selectedStudent &&
                    <div>
                        <Box sx={{
                            marginTop: '16px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                        </Box>

                        { !selectedFunction &&
                            <List key="student_item_list">
                                {
                                    renderFunctionSectionList('instructional')
                                }
                                {
                                    renderFunctionSectionList('treatment')
                                }
                                {
                                    renderFunctionSectionList('home')
                                }
                                {
                                    renderFunctionSectionList('other')
                                }
                            </List>
                        }

                        { selectedFunction &&
                            <FunctionContainer 
                                editMode={isEditingFunctionInstance} 
                                onFinishedEditing={() => setEditingFunctionInstance(false)}
                                canArchiveProgram={true}
                                item={selectedFunction} 
                                groupID={selectedGroup.key} 
                                student={selectedStudent} 
                                studentID={selectedStudent.key} 
                                functionID={selectedFunction.key} />
                        }

                    </div>
                }

                { !selectedStudent && !selectedFunction &&
                    <List key="group_list">
                        {
                            // groups
                            !loading && groups && groups.map((group) => {
                                return <ListItem key={group.key}>
                                    <ListItemButton onClick={() => onSelectGroup(group.key)}>
                                        <ListItemIcon sx={{ mr: '16px', minWidth: 0}}>
                                            <GroupsIcon />
                                        </ListItemIcon>
                                        <ListItemText sx={{ ml: 0 }}>{group.name}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                        {
                            // users
                            !loading && selectedGroup?.students.map((student) => {
                                return <ListItem key={student.key}>
                                    <ListItemButton onClick={() => onSelectStudent(student)}>
                                        <ListItemIcon sx={{ mr: '16px', minWidth: 0}}>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <ListItemText sx={{ ml: 0 }}>{student.fullname}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                    </List>
                }
            </Box>
            { (selectedFunction || selectedStudent) &&
                <FunctionSubheader 
                    key="function_subheader" 
                    editMode={editMode} 
                    onBack={() => selectedFunction ? onNavigateStudent() : onSelectGroup(selectedGroup.key) } 
                    title={title} 
                    titleColor={titleColor}
                    studentName={selectedStudent.fullname} 
                    studentPhotoURL={selectedStudent?.photoURL} />
            }

            { !selectedFunction && !selectedStudent &&
                <MyGroupsSubheader key="my_groups_subheader" editMode={editMode} allGroups={allGroups} selectedGroup={selectedGroup} setEditingFunctionInstance={setEditingFunctionInstance} onSelectGroup={onSelectGroup} />
            }
        </div>
    );
}
