import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/header/header';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../components/firebase/firebase';
import { Route, Routes, useNavigate } from 'react-router-dom';
import "./../assets/css/dashboard.css"
import SideMenu, { sideMenuWidth } from '../components/sidemenu/side_menu';
import Home from './home';
import { Divider } from '@mui/material';
import ProgramLibrary from './program_library';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyGroups from './my_groups/my_groups';
import { fetchUserProfile } from '../api/user';
import { printObject } from '../utils/print_object';
import Reports from './reports/reports';
import CreateEditReport from './reports/edit-report';
import ReportCreated from './reports/report-created';
import { useRecoilState } from 'recoil';
import { reportExpandedIDsState } from '../recoil/atom/reports-state';
import ImportData from './import/import_data';
import ImportDataToLibrary from './import/import_to_library';
import ImportSuccess from './import/import_success';
import ImportInstances from './import/import_instances';
import { userProfileState } from '../recoil/atom/user-state';
import SignatureList from './signatures/signature-list';
import AdministrationHome from '../components/administration/administration-home';

export default function Dashboard() {

  const [user, loading, error] = useAuthState(auth);
  const [currentUserProfile, setCurrentUserProfile] = useState(null)
  const [displayUsername, setDisplayUsername] = useState(null)
  const [reportExpandedIDs, _] = useRecoilState(reportExpandedIDsState)
  const [currentUserProfileState, setCurrentUserProfileState] = useRecoilState(userProfileState)
  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
        primary: {
            main: '#268DA5',
            dark: '#0c6472',
        }
    },
});


  useEffect(() => {
    if (loading) {
      // show loading screen
      return;
    }
    if (!user) navigate("/");
  }, [user, loading]);

  useEffect(() => {
    if (!user) {
      setCurrentUserProfile(null)
    } else if (!currentUserProfile) {
      fetchCurrentUserProfile()
    }
  }, [user]);

  function fetchCurrentUserProfile() {
    fetchUserProfile().then((response) => {
      printObject(response.data, "user profile")
      setCurrentUserProfile(response.data)

      let firstname = response.data?.name ?? ""
      let lastname = response.data?.lastName ?? ""
      setDisplayUsername(`${firstname} ${lastname}`)
      setCurrentUserProfileState(response.data)
    })
  }

  return (
    <>
      <div className="header">
        <Header title="BehaviorFlow" displayUsername={displayUsername} />
      </div>
      
      <SideMenu  />  

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: `${sideMenuWidth}px`,
        justifyContent: 'stretch',
        width: `calc(100% - ${sideMenuWidth}px)`,
        height: '100%',
        
      }}
       >
        
        <div style={{paddingLeft: '8px', marginTop: '55px', width: '100%'}}>
          <Routes>
            <Route path="/home" element={<Home currentUserProfile={currentUserProfile} />} />
            <Route path="/my_groups" element={<MyGroups currentUserProfile={currentUserProfile} />} />
            <Route path="/program_library" element={<ProgramLibrary currentUserProfile={currentUserProfile} />} />
            <Route path="/reports" element={<Reports currentUserProfile={currentUserProfile} />} />
            <Route path="/report" element={<CreateEditReport expandedGroupIDs={reportExpandedIDs} currentUserProfile={currentUserProfile} />} />
            <Route path="/report-created" element={<ReportCreated currentUserProfile={currentUserProfile} />} />
            <Route path="/import" element={<ImportData currentUserProfile={currentUserProfile} />} />
            <Route path="/signatures" element={<SignatureList currentUserProfile={currentUserProfile} />} />
            <Route path="/import_to_library" element={<ImportDataToLibrary currentUserProfile={currentUserProfile} />} />
            <Route path="/import_student_instances" element={<ImportInstances currentUserProfile={currentUserProfile} />} />
            <Route path="/import_completed" element={<ImportSuccess currentUserProfile={currentUserProfile} />} />
            <Route path="/organization" element={<AdministrationHome currentUserProfile={currentUserProfile} />} />
            
          </Routes>
        </div>
      </Box>
      </>
  );
}

