import { httpsCallable } from "firebase/functions";
import { functions } from "../../components/firebase/firebase";

const getUserSignatures = httpsCallable(functions, 'signature-get_signature_programs');

export async function fetchSignaturePrograms() {
    let response = await getUserSignatures()
    if(response.error) {
        return {
            error: response.error
        }
    }

    let programs = response.data.programs ?? []

    programs.forEach((program) => {
        let studentFullName = program.student?.name + ' ' + program.student?.lastName
        let groupName = program.groupName
        let programTitle =  program.function?.title ?? ''
        program.title = `${studentFullName} - ${groupName}`
        program.subtitle = programTitle
    })

    return {
        programs
    }
}

