
export interface SimpleResponse {
    success: boolean, 
    message: string
    error?: string
}

export const GroupRole = {
    Principal: 1,
    Teacher: 2,
    AdminTeacher: 3,
    Assistant: 4,
    Parent: 6,
    Student: 7
}

export enum StandaloneProgramState {
    Active = 0,
    TemporarilyDiscontinued = 1,
    Discontinued = 2,
    Archived = 3
}