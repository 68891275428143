import { AppBar, Button, createTheme, Divider, IconButton, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { useState, React } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from "@mui/system";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { logout } from '../firebase/firebase';

const theme = createTheme({
    palette: {
        primary: {
            main: '#268DA5',
            dark: '#0c6472',
        }
    },
});

export default function Header(props) {
    const [anchor, setAnchor] = useState(null);

    function handleMenu(event) {
        console.log('anchor ' + event.currentTarget)
        setAnchor(event.currentTarget);
    }

    function handleLogout() {
        logout()
        setAnchor(null)
    }

    function handleClose() {
        setAnchor(null);
    }

    return (
        <ThemeProvider theme={theme}>
            <AppBar sx={{ minHeight: '20px', height: '55px' }} position="fixed">
                <Box sx={{
                    mt: '8px',
                    mb: '8px',
                    ml: '24px',
                    mr: '24px',

                    display: 'flex',
                    flexDirection: 'row',
                    height: 'calc(100% - 16px)',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                }}>
                    <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <img src={process.env.REACT_APP_PUBLIC_URL + (process.env.REACT_APP_IS_STAGING ? '/logo256_staging.png' : '/logo256.png')} height="40px" />
                    </IconButton>
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'medium' }} >
                        <p>{props.title}</p>
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row', width: '100%' }}>
                        { props.displayUsername &&
                            <Typography variant="h6" component="div" >
                                <p>{props.displayUsername}</p>
                            </Typography>
                        }
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls={anchor !== null ? "menu-appbar" : undefined}
                            aria-expanded={anchor !== null ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchor}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={anchor !== null}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </AppBar>
        </ThemeProvider>
    );
};