import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function CancelEditingDialog(props) {
    const { onCompleted } = props;

    return (
        <Dialog open={props.open} onClose={() => onCompleted(false)} >
            <DialogTitle>
                Careful...
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
            Are you sure you want to cancel editing? All of your changes will be lost. 
            </DialogContentText>
            </DialogContent>            
            <DialogActions sx={{mt: 0}}>
                <Button id="button_disagree" onClick={() => onCompleted(false)}>Stay on page</Button>
                <Button id="button_agree" sx={{ color: 'red'}} onClick={() => onCompleted(true)}>Yes, I'm sure</Button>
            </DialogActions>
        </Dialog>
    );
}