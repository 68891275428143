import { atom } from "recoil";

export const isEditingFunctionAtom = atom({
  key: "isEditingFunction",
  default: false,
});

export const updatingFunctionAtom = atom({
  key: "updatingFunction",
  default: false,
});

export const cancelEditingFunctionAtom = atom({
    key: "cancelEditingFunction",
    default: false,
  });

export const doSubmitFunctionAtom = atom({
  key: "doSubmitFunction",
  default: false,
});

export const selectedFunctionAtom = atom({
  key: 'selectedFunction',
  default: null
});

export const reloadFunctionAtom = atom({
  key: 'reloadFunction',
  default: false
});

export const selectedFunctionDescriptorAtom = atom({
  key: 'selectedFunctionDescriptor',
  default: null
});

export const reloadedFunctionDataAtom = atom({
  key: 'reloadedFunctionData',
  default: null
});