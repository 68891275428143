import { httpsCallable } from "firebase/functions";
import { functions } from "../components/firebase/firebase";

export const fetchUserProfile = httpsCallable(functions, 'user-fetchUserProfile');
export const fetchUserInGroup = httpsCallable(functions, 'user-fetchUserInGroup');

export let UserTypeEnum = {
    student: 1,
    supporter: 2,
    signature_parent_guardian: 3,
    signature_external: 4,
    isSignatureType: (type: number) => { return UserTypeEnum['signature_parent_guardian'] == type || UserTypeEnum['signature_external'] == type }
}