import { Button, Dialog, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import PanToolIcon from '@mui/icons-material/PanTool';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Box } from "@mui/system"

export default function AddProgramFieldDialog(props) {
    const { onSelect } = props;
    
    function onSelectItem(type) {
        onSelect(type)
    }

    return (
        <Dialog open={props.open} onClose={() => onSelectItem(null)} >
            <DialogTitle>
                Add Signature Field
            </DialogTitle>
            <List component="nav" aria-label="main mailbox folders">
            <ListItemButton
                    onClick={(event) => onSelectItem("drat")}
                >
                    <ListItemIcon>
                        <PanToolIcon />
                    </ListItemIcon>
                    <ListItemText primary="Discontinuation Rationale" />
                </ListItemButton>

                <ListItemButton
                    onClick={(event) => onSelectItem("signature")}
                >
                    <ListItemIcon>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Generic Signature" />
                </ListItemButton>
                <ListItemButton
                    onClick={(event) => onSelectItem("annual-signatures")}
                >
                    <ListItemIcon>
                        <ViewListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Annual Review Signatures" />
                </ListItemButton>
            </List>
        </Dialog>
    );
}