import * as React from 'react';
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Typography } from '@mui/material';

export default function DeleteDialog(props) {
    const [text, setText] = useState("");
    const [itemName, setItemName] = useState(props.itemName)
    const defaultDeleteTerm = "delete"

    useEffect(() => {
        setText("")
    }, [props.open])

    function defaultText(term) {
        const item = itemName ?? 'item'
        return <><Typography variant='body1'>
            This action <span style={{ fontWeight: 700 }}>CANNOT</span> be undone. The program will be {`${term}d`} permanently.
        </Typography>
            <br />
            <Typography variant='body1'>
                Please confirm by typing the word <span style={{ color: '#ff0000' }}>{term}</span> in the field below:
            </Typography>
        </>
    }

    function getDeleteText() {
        if (props.text) {
            return props.text(getDeleteTerm())
        } else {
            return defaultText(getDeleteTerm())
        }
    }

    function getDeleteTerm() {
        return props.deleteTerm ?? defaultDeleteTerm
    }

    function onClickCompleted(shouldDelete) {
        props.onCompleted?.(shouldDelete)
    }

    return (
        <Dialog open={props.open} onClose={() => onClickCompleted(false)} >
            <DialogTitle>
                Are you ABSOLUTELY sure?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getDeleteText()}
                </DialogContentText>
                <br />
                <TextField
                    disabled={props.isLoading}
                    autoFocus
                    autoComplete='off'
                    margin="dense"
                    id="input"
                    onChange={(e) => { setText(e.target.value) }}
                    value={text}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions sx={{ mt: 0 }}>
                {props.isLoading && <CircularProgress sx={{ mr: 2 }}/>}
                {!props.isLoading && <>
                    <Button id="button_disagree" onClick={() => onClickCompleted(false)}>{props.noTitle ?? "Cancel"}</Button>
                    <Button id="button_agree" disabled={text.trim().toLowerCase() != getDeleteTerm().trim().toLowerCase()} color='error' onClick={() => onClickCompleted(true)}>{props.yesTitle ?? "Delete"}</Button>
                </>
                }
            </DialogActions>
        </Dialog>
    );
}