import { httpsCallable } from "firebase/functions";
import { functions } from "../components/firebase/firebase";
import { parseTreeGroups } from "../pages/reports/reports.api";
import { fetchGroupDescriptor, fetchGroupDescriptors } from "./my_groups";
import { printObject } from "../utils/print_object";

export let GroupRoleEnum = {
    principal: 1,
    teacher: 2,
    adminTeacher: 3,
    assistant: 4,
    parent: 6,
    student: 7,
    all: () => { 
        let all = []
        for(let key in GroupRoleEnum) {
            let value = GroupRoleEnum[key]
            if(typeof value == 'number') {
                all.push(value)
            }
        }
        return all
    },
    isAdministrationRole: (role) => { return role == GroupRoleEnum.principal || role == GroupRoleEnum.adminTeacher},
    titleFor: (role) => { 
        switch(role) {
            case 1:
                return "Principal"
            case 2:
                return "Teacher"
            case 3:
                return "Admin Teacher"
            case 4:
                return "Assistant"
            case 6:
                return "Parent"
            case 7:
                return "Student"
            default:
                return "Unknown"
        }
    }

}


export const generateGroupPathValues = httpsCallable(functions, 'db-generate_group_path_values');
// export const createProgramLibraryRootFolder = httpsCallable(functions, 'program_library-create_program_library_root_folder');

const moveStudentToAnotherGroup = httpsCallable(functions, 'user_admin-move_student');
const copyWithoutProgramming = httpsCallable(functions, 'user_admin-copy_without_programming');
const getUserAdminRootGroups = httpsCallable(functions, 'user_admin-get_manage_user_groups');
const fetchAdminGroupsAndInvitations = httpsCallable(functions, 'user_admin-fetch_groups_and_invitations');
const fetchAdminSingleGroupWithInvitations = httpsCallable(functions, 'user_admin-fetch_single_group_with_invitations')

export const removeUserFromGroup = httpsCallable(functions, 'user_admin-remove_student_from_group')
export const addUserToGroup = httpsCallable(functions, 'user_admin-add_user_to_group');
export const checkIfUserExists = httpsCallable(functions, 'user_admin-check_user_exists');

export function moveStudent(studentID, sourceGroupID, destinationGroupID) {
    return moveStudentToAnotherGroup({ studentID, sourceGroupID, destinationGroupID })
}

export function copyStudentWithoutProgramming(studentID, sourceGroupID, destinationGroupID) {
    return copyWithoutProgramming({ studentID, sourceGroupID, destinationGroupID })
}

export function fetchUserAdminRootGroups() {
    return getUserAdminRootGroups().then((res) => {
        return parseGroups(res.data.groups, null)
    })
}

export function fetchUserAdminGroups(parentID) {
    return fetchAdminGroupsAndInvitations({ parentGroupID: parentID}).then((response) => {
        printObject(response.data, 'Fetched subgroups for parentID ' + parentID)
        return parseGroups(response.data, parentID)
    });
}

export function fetchUserAdminGroup(groupID, parentID) {
    return fetchAdminSingleGroupWithInvitations({ groupID }).then((response) => {
        printObject(response.data, 'descriptor for ' + groupID)
        let groups = parseGroups({ [groupID]: response.data }, parentID).data ?? []
        if(groups.length > 0) {
            return groups[0]
        } else {
            return null
        }
    });
}

function parseGroups(groups, parentID) {
    let groupsArr = []

    for (let key in groups) {
        let group = groups[key]
        if (group.status && group.status !== 1) {
            continue
        }

        let groupDict = {
            id: key,
            type: 'group',
            title: group.name,
            parentID: parentID,
            invitations: group.invitations
        }

        let students = undefined
        if (group.users) {
            for (let userKey in group.users) {
                let user = group.users[userKey]
                
                let status = user.status
                if (!(status === 0 && status !== null && status !== undefined)) {
                    let dict = {
                        id: `${userKey}`,
                        studentID: userKey,
                        fullname: user.name + " " + (user.lastName ?? ""),
                        type: 'student',
                        role: user.role,
                        roleTitle: GroupRoleEnum.titleFor(user.role),
                        groupID: key
                    }
                    if (!students) {
                        students = []
                    }
                    students.push(dict)    
                }
            }
        }
        if (students) {
            groupDict.students = students.sort((a, b) => a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1)
        }

        groupsArr.push(groupDict)
    }

    return {
        data: groupsArr
    }
}
