import { useEffect, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Card, InputLabel, Typography } from "@mui/material";
import { Label } from "@mui/icons-material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from "react-router-dom";

const spacingBetween = 4

const style = {
    border: '1.5px solid',
    borderColor: 'primary.main',
    borderRadius: '32px',
    p: 1, 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    width: "200px", 
    mr: spacingBetween / 2,
    cursor: 'pointer',
    userSelect: 'none'
};

export default function ImportData(props) {

    const navigate = useNavigate()
    
    function onClick(index) {
        switch(index) {
            case 0:
                navigate('/dashboard/import_student_instances', { replace: true })
                break
            case 1:
                navigate('/dashboard/import_to_library', { replace: true })
                break
            default:
                return
        }
    }

    return <Box sx={{ mt: '32px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '95%', height: '100%', justifyContent: 'center' }}>                  
        <Box sx={{ ...style }} onClick={(event) => onClick(0)}>
            <PersonIcon sx={{ width: '70px', height: '70px'}} color='primary' />
            <Typography component="h3" variant="body" align="center" >Import program instances</Typography>
            <Typography sx={{ mt: 1 }} component="div" variant="body" align="center">Import programs <b>for a specific student</b></Typography>
        </Box>
        <Box sx={{ ...style }} onClick={(event) => onClick(1)} >
            <MenuBookIcon sx={{ width: '70px', height: '70px'}} color='primary' />
            <Typography component="h3" variant="body" align="center" >Import program templates</Typography>
            <Typography sx={{ mt: 1 }} component="div" variant="body" align="center">Import programs <b>to the program library</b></Typography>
        </Box>
    </Box>
}