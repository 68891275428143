import { AppBar, Box, Button, createTheme, Divider, IconButton, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { borderBottom } from "@mui/system";
import { useEffect, useState } from "react";
import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { printObject } from "../../utils/print_object";
import { sideMenuWidth } from "../sidemenu/side_menu";

const theme = createTheme({
    palette: {
        primary: {
            main: '#268DA5',
            dark: '#0c6472',
        }
    },
});

function ProgramLibrarySubheader(props) {
    // const [allFolders, setAllFolders] = useState(props.allFolders)
    // const [selectedFolder, setSelectedFolder] = useState(props.selectedFolder)
    const [folders, setFolders] = useState(props.folders)

    // useEffect(() => {
    //     setAllFolders(props.allFolders)
    //     setSelectedFolder(props.selectedFolder)
    // }, [props.folders, props.selectedFolder])

    useEffect(() => {
        setFolders(props.folders)
    }, [props.folders])

    function renderFolderPathButton(folder, folderKey, showNextSymbol) {
        let folderName = folder?.name ?? 'My Program Libraries'
        // console.log("render key " + folder?.key)
        return (
            <Button
                    key={"path_button_" + (folderKey ?? 'root')}
                    sx={{pl: 1, pr: 0, ml: !folderKey ? 2 : 0}}
                    onClick={() => props.onSelectFolder?.(folder)}
                    variant="text">
                    <Typography variant="subtitle2">
                        {folderName} 
                    </Typography>
                    { showNextSymbol && <KeyboardArrowRightIcon />}
                  
                </Button>
        );
    }

    function renderFolderPath() {
       
        // let pathFolders = []
        // let folder = selectedFolder
        // console.log('iterating')
        // while(folder) {
        //     console.log('folder ' + folder.folderName + ' key ' + folder.key)
        //     console.log('keys ' + Object.keys(folder))
        //     pathFolders.push(folder)
        //     folder = folder.parentFolder
        // }

        // pathFolders = pathFolders.reverse()
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                {
                    renderFolderPathButton(null, null, (folders?.length > 0) ?? false)
                }
                {
                    folders && folders.map((folder, index) => {
                        return renderFolderPathButton(folder, folder.key, index < folders.length - 1)
                    })
                }
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: `${sideMenuWidth}px`,
                zIndex: 1,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1
            }}>
            {renderFolderPath()}
            {props.editMode == 'canEdit' &&
            <Box flexGrow={1} sx={{ marginRight: '32px', display: 'flex', alignItems: 'center', justifyContent:'end'}} >
                <Button sx={{mt: '12px', mb: '12px' }} variant="contained" onClick={() => props.setEditingFunctionInstance?.(true)}>Edit</Button>
            </Box>
            }
        </Box>
    );
};

export default ProgramLibrarySubheader;