import { httpsCallable } from "firebase/functions";
import { functions } from "../../components/firebase/firebase";

const getTokenObject = httpsCallable(functions, 'auth-get_token_object');
const createSignatureAccount = httpsCallable(functions, 'auth-create_signature_account');
const createRegularAccount = httpsCallable(functions, 'auth-create_regular_account');

export function registerRegularAccount(email, firstName, lastName, password) {
    return createRegularAccount({ email, firstName, lastName, password})
}

export function registerSignatureAccount(email, firstName, lastName, password, token) {
    return createSignatureAccount({ email, firstName, lastName, password, token })
}

export function getEmailToken(key) {
    return getTokenObject({ token: key }).then((res) => {
        let data = res.data
        if(data.usedAt) {
            return {
                error: "This invitation has already been used."
            }
        }

        return {
            email: data.email
        }
    })
}