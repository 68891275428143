import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ProgramEntryFieldType, ProgramLibraryItem, ProgramTextEntry, TextualProgram } from '../program_library';
import '../../assets/css/edit_program.css';
import SignatureField from './signature_field';
import AddSignaturePlus from './add_signature_plus';
import { generateRandomKey } from '../../utils/random_key';
import DiscontinuationRationale from './discontinuation_rationale';
import { jsonCopyObject } from '../../utils/json_copy';
import { useRecoilState } from 'recoil';
import { isEditingFunctionAtom } from '../../recoil/atom/function_atom';
import { editedProgramAtom, editingProgramAtom, isCustomizingProgramForStudentAtom } from '../../recoil/atom/program_library_atom';
import { printObject } from '../../utils/print_object';

export enum EditProgramMode {
    Customize = "customize",
    Edit = "edit"
}

interface EditProgramProps {
    mode: EditProgramMode
    loading: boolean
    onSubmit?: (program: TextualProgram) => void
    onChange?: (program: TextualProgram) => void
    item: ProgramLibraryItem
    onCustomizeClicked?: Function | null,
    isEmbedded: boolean
}

interface EditProgramState {
    entries: { [key: string]: ProgramTextEntry }
    signatures: { [key: string]: ProgramTextEntry }
    discontinuationItems: Array<{ [key: string]: {} }>
    title: string
}

export function EditProgramForm(props: EditProgramProps) { 
    const [isProgramEditable, setProgramEditable] = useRecoilState<any>(isEditingFunctionAtom)
    const [isCustomizingProgram, setCustomizingProgram] = useRecoilState<any>(isCustomizingProgramForStudentAtom)
    const [entries, setEntries] = useState<{ [key: string]: ProgramTextEntry }>({})
    const [signatures, setSignatures] = useState<{ [key: string]: ProgramTextEntry }>({})
    const [discontinuationItems, setDiscontinuationItems] = useState<Array<{ [key: string]: {} }>>([])
    const [title, setTitle] = useState<string>("")
    const [editingProgram, setEditingProgram] = useRecoilState<any>(editingProgramAtom)
    const [editedProgram, setEditedProgram] = useRecoilState<any>(editedProgramAtom)

    useEffect(() => {
        let itemCopy: any = editingProgram || undefined

        if(!itemCopy) {
            return
        }

        itemCopy = jsonCopyObject(itemCopy)

        let discontinuationItems: Array<{}> = []

        for (let key in itemCopy.program.discontinuationItems ?? {}) {
            let item = itemCopy.program.discontinuationItems?.[key]
            if (item) {
                item.key = key
                discontinuationItems.push(item)
            }
        }

        let signatures = itemCopy.program.signatures ?? {}

        for(let key in signatures) {
            let signature = signatures[key]
            signature.isParentSignature = (signature.isParentSignature || signature.type == 'parent')
        }

        setEntries(itemCopy.program.textItems)
        setSignatures(signatures)
        setTitle(itemCopy.program.title)
        setDiscontinuationItems(discontinuationItems)
    }, [editingProgram]);

    useEffect(() => {
        let program: TextualProgram = { textItems: entries, title: title, signatures: signatures, discontinuationItems: discontinuationItems }
        setEditedProgram(program)
        
        props.onChange?.(program)
    },[entries, signatures, discontinuationItems, title])

    function onAddSignature() {
        let allSignatures = jsonCopyObject(signatures)
        const key = generateRandomKey(20)

        const index = Object.keys(allSignatures).length

        let signature: ProgramTextEntry = {
            fieldType: ProgramEntryFieldType.Signature,
            index: index,
            label: "Signature",
            value: "",
            type: 'regular'
        }

        allSignatures[key] = signature
        setSignatures(allSignatures)
    }

    function onSignatureValueChange(key: string, text: string, type: string, email: string, annual: boolean) {
        let allSignatures = jsonCopyObject(signatures)
        let signature = allSignatures[key]
        if (signature.fieldType == ProgramEntryFieldType.Signature) {
            signature.value = text
            signature.type = type
            signature.isParentSignature = null
            signature.email = email
            signature.annual = annual
            setSignatures(allSignatures)
        }
    }

    function removeSignature(key: string) {
        let allSignatures = jsonCopyObject(signatures)
        let entry = allSignatures[key]
        if (entry.fieldType == ProgramEntryFieldType.Signature) {
            delete allSignatures[key]
            setSignatures(allSignatures)
        }
    }

    function handleChange(key: string, value: string) {
        let allEntries = jsonCopyObject(entries)
        allEntries[key].value = value
        setEntries(allEntries)
    }

    return (
        <Box
            sx={{
                marginTop: props.isEmbedded ? 0 : 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>

            <form className='form'>
                {
                    entries && Object.entries(entries).sort((a, b) => a[1].index < b[1].index ? -1 : 1).map((field, index) => {
                        return (
                            <div className="form-item" key={field[0] + "_form"}>
                                {
                                    field[1].fieldType == ProgramEntryFieldType.TextField &&
                                    <TextField margin="normal" 
                                        required
                                        fullWidth
                                        name={field[1].label}
                                        key={field[0]}
                                        InputProps={{
                                            readOnly: isProgramEditable === false && !isCustomizingProgram
                                          }}
                                        label={field[1].label} variant="standard"
                                        value={field[1].value}
                                        onChange={(event) => handleChange(field[0], event.target.value)}
                                        className='form-textfield' />
                                }

                                {field[1].fieldType == ProgramEntryFieldType.Multiline &&
                                    // TODO: Add placeholder
                                    <TextField className="form-textarea"
                                        key={field[0]}
                                        fullWidth id="outlined-basic"
                                        rows={12}
                                        multiline
                                        InputProps={{
                                            readOnly: isProgramEditable == false && !isCustomizingProgram
                                          }}
                                        label={field[1].label}
                                        value={field[1].value}
                                        onChange={(event) => handleChange(field[0], event.target.value)}
                                        variant="filled" />
                                }

                            </div>
                        );
                    })
                }

                {discontinuationItems?.length > 0 && discontinuationItems.map((item) => {
                    return <Box>
                        <DiscontinuationRationale dratModel={item} ></DiscontinuationRationale>
                    </Box>
                })}

                {
                    signatures && Object.entries(signatures).sort((a, b) => a[1].index < b[1].index ? -1 : 1).map((field, index) => {
                        return <SignatureField
                            id={field[0]}
                            key={field[0]}
                            title={field[1].value}
                            editMode={ isProgramEditable == true || isCustomizingProgram } 
                            readOnly={ isProgramEditable == false && !isCustomizingProgram }
                            type={field[1].isParentSignature ? 'parent' : (field[1].type ?? 'regular')}
                            annual={field[1].annual ?? false}
                            onRemoveClicked={() => removeSignature(field[0])}
                            onStateChanged={(text, isChecked, email, annual) => onSignatureValueChange(field[0], text, isChecked, email, annual)} />
                    })
                }

                { (isProgramEditable == true || isCustomizingProgram) && <AddSignaturePlus key="add_signature_plus" onClick={() => onAddSignature()} /> }

            </form>
           
            <br />
        </Box>

    );
}

