import { AppBar, Box, Button, createTheme, Divider, IconButton, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { borderBottom } from "@mui/system";
import { useEffect, useState } from "react";
import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { sideMenuWidth } from "../sidemenu/side_menu";

const theme = createTheme({
    palette: {
        primary: {
            main: '#268DA5',
            dark: '#0c6472',
        }
    },
});

function MyGroupsSubheader(props) {
    const [allGroups, setAllGroups] = useState(props.allGroups)
    const [selectedGroup, setSelectedGroup] = useState(props.selectedGroup)

    useEffect(() => {
        setAllGroups(props.allGroups)
        setSelectedGroup(props.selectedGroup)
    }, [props.allGroups, props.selectedGroup])
    
    function renderFolderPathButton(group, key, showNextSymbol) {
        let folderName = group?.name ?? "All Groups"

        return (
            <span key={`wrapper_${key}`}>
                <Button
                    key={"path_button_" + key}
                    sx={{pl: 1, pr: 0, ml: !key ? 2 : 0}}
                    onClick={() => props.onSelectGroup?.(key)}
                    variant="text">
                    <Typography variant="subtitle2">
                        {folderName} 
                    </Typography>
                    { showNextSymbol && <KeyboardArrowRightIcon />}
                  
                </Button>
            </span>
        );
    }

    function renderFolderPath() {
        if (!allGroups) {
            console.log('no groups')
            return <span></span>
        }
        let pathFolders = []
        let group = selectedGroup

        while (group) {
            pathFolders.push(group)

            if (!group.parent) {
                break;
            } else {
                group = allGroups[group.parent]
            }
        }

        pathFolders = pathFolders.reverse()
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                {
                    renderFolderPathButton(null, null, pathFolders.length > 0)
                }
                {
                    pathFolders.map((group, index) => {
                        return renderFolderPathButton(group, group.key, index < pathFolders.length - 1)
                    })
                }
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: '60px',
                display: 'flex',
                position: 'fixed',
                top: '55px',
                left: `${sideMenuWidth}px`,
                zIndex: 1,
                width: `calc(100% - ${sideMenuWidth}px)`,
                flexDirection: 'row',
                background: '#DDDDDDFF',
                boxShadow: 1
            }}>
            {renderFolderPath()}
            {props.editMode == 'canEdit' &&
            <Box flexGrow={1} sx={{ marginRight: '32px', display: 'flex', alignItems: 'center', justifyContent:'end'}} >
                <Button sx={{mt: '12px', mb: '12px' }} variant="contained" onClick={() => props.setEditingFunctionInstance?.(true)}>Edit</Button>
            </Box>
            }
        </Box>
    );
};

export default MyGroupsSubheader;